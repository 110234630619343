import React, { Component } from 'react'
import './Form2.css'
import logo from './image003.jpg'
import QRcode from 'react-qr-code'
import bagform from './bagform';
import htmlcanvas from 'html2canvas'
import axios from 'axios'
import Cookies from 'js-cookie'
export class ODetay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            bagno: sessionStorage.getItem("ordinoID"),
            link: "https://mehmetalidc.online/ordino/" + sessionStorage.getItem("ordinoID"),
            topkg : "0"
        }
    }
    componentDidMount() {
        this.vericek();
    }
    vericek() {
        fetch(window.$apiurltokensiz + "/ORDINOCEK?ordref=" + sessionStorage.getItem("ordinoID"))
            .then(res => res.json())
            .then(json => {
                this.setState({ items: json });
                console.log(json);
                document.getElementById("LBLtarih").innerHTML = "Tarih : " + json[0]["TARIH"];
                document.getElementById("LBLyukref").innerHTML = "Yükleme No : " + sessionStorage.getItem("ordinoID");
                document.getElementById("LBLtedadi").innerHTML = json[0]["FIRMANAME"];
                document.getElementById("LBLtopkg").innerHTML = "Toplam Ordino KG : " + json[0] ["TOPKG"]+" ";
                document.getElementById("LBLsoforadi").innerHTML = "Şöför Adı : "+json[0]["SOFORADI"];
                document.getElementById("LBLtcno").innerHTML = "TC Nosu : "+json[0]["TCNO"];
                document.getElementById("LBLplaka").innerHTML = json[0]["PLAKA"];
            });
    }
    render() {
        return (
            <div style={{ backgroundColor: "gray" }}>
                <div id="scdeneme">
                    <div id="anacizgi">
                        <div id="anacizgi2">
                            <div id="kutu" style={{ paddingLeft: "20px" }}>
                                <img src={logo} style={{ width: "150", height: "45px", float: "left", marginLeft: "10px", marginTop: "20px" }}></img>


                                <p style={{ marginTop: "10px", marginLeft: "20px", float: "right", marginRight: "10px" }}><QRcode value={this.state.link} id="qrcode" size="80" /></p>


                            </div>
                            <table border={0}>
                                <tr>
                                    <td style={{ width: "250px", paddingLeft: "10px" }}>Cumhuriyet Mah. No : 136/1 Sk.No:2
                                    Koyundere Menemen/İZMİR</td>
                                    <td style={{ width: "70%", textAlign: "end", paddingRight: "10px", fontSize: "16px" }}><b id="LBLtarih">Tarih : 2021</b></td>
                                </tr>
                                <tr >
                                    <td style={{ paddingLeft: "10px" }}>Telefon : +90 232 364 34 44 pbx</td>
                                    <td style={{ width: "70%", textAlign: "end", paddingRight: "10px", fontSize: "16px" }}><b id="LBLyukref">Yükleme No : 0</b></td>
                                </tr>
                                <tr>
                                    <td style={{ paddingLeft: "10px" }}>Faks :+90 312 473 67 99</td>

                                </tr>
                            </table>
                            <br />



                            <table style={{ paddingLeft: "0px" }} className="tablo2" border={1}>
                                <tbody id="tblyaz">
                                    <tr style={{ fontSize: "18px", textAlign: 'center' }} className="cltr">
                                        <td colSpan={2}><h4 id="LBLtedadi" style={{ paddingTop: "5px", paddingBottom: "5px" }}></h4></td>
                                    </tr>
                                    <tr style={{ fontSize: "18px", textAlign: 'center' }} className="cltr">
                                        <td colSpan={2}><h4 style={{ paddingTop: "5px", paddingBottom: "5px" }}>Yükleme Yeri:</h4></td>
                                    </tr>
                                    <tr className="cltr">
                                        <td className="cltr" style={{ paddingLeft: "5px", width: "150px" }}><b>Mamül Cinsi</b></td>
                                        <td className="cltr" style={{ textAlign: "end", width: "60px", paddingRight: "10px" }}><b>Miktar(kg)</b></td>
                                    </tr>
                                    {this.state.items.map(item => (
                                        <tr className="cltr">
                                            <td className="cltr" style={{ paddingLeft: "5px" }}><b>{item.URUNADI}</b></td>
                                            <td className="cltr" style={{ textAlign: "end", paddingRight: "10px" }}><b>{item.MIKTAR}</b></td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: "end", paddingRight: "10px" }}><b id="LBLtopkg">Toplam Ordino KG :</b></td>
                                    </tr>

                                    {/*{items.map(item => (
                                <tr className="cltr">
                                    <td className="cltr" style={{paddingLeft:"5px"}}>{item.URUNADI}</td>
                                    <td className="cltr" style={{textAlign:"right",paddingRight:"5px"}}>{item.MIKTARI} KG</td>
                                    <td className="cltr" style={{textAlign:"right",paddingRight:"5px"}}>{item.FIYATI}</td>
                                    <td className="cltr" style={{textAlign:"right",paddingRight:"5px"}}>{item.TOPTUTAR} {item.KUR}</td>
                                    <td className="cltr"  id="LBLdeneme" />
                                </tr>
                            ))}*/}

                                </tbody>
                            </table>
                            <table style={{ marginTop: "70px" }} className="tablo2" border={0}>
                                <tr>
                                    <td><b id="LBLsoforadi"> şöför </b></td>
                                    <td style={{ textAlign: "end" }}><b id="LBLtcno">TC Nosu : 0</b></td>
                                </tr>
                            </table>
                            <div id="alt2">

                                {/*<b style={{ paddingLeft: "10px" }}>Merkez</b> &nbsp; Anadolu Cad.No:41/1 MYPLAZA Kat:12 D:12001-12002 Bayraklı / İZMİR<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  Telefon:0(232) 486 65 35 pbx<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Faks    :0(232) 462 64 94*/}
                                <table style={{ paddingLeft: "10px", marginLeft: "20px", marginTop: "7px" }}>
                                    <tr style={{ width: "100%" }}>
                                        <td rowSpan={2} style={{ width: "200px", textAlign: "center", fontSize: "25px", border: "1px solid black" }}> <b id="LBLplaka">55 </b></td>
                                        <td style={{ fontSize: "16px", textDecoration: "underline", textAlign: "end", width: "75%", paddingRight: "10px" }}><b>Talep Eden</b></td>
                                    </tr>
                                    <tr>

                                        <td style={{ width: "75%", textAlign: "end", paddingRight: "10px" }}><img src={logo} style={{ height: "45px" }} /></td>
                                    </tr>
                                </table>
                            </div>
                            {/*<p id="enalt"> E-mail:  zms@zmsdemir.com   -  Web: www.zmsdemir.com</p>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ODetay
