import React, { Component } from 'react'
import Cookies from 'js-cookie'
export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.cikis = this.cikis.bind(this);
    }
    cikis() {
        Cookies.remove('giris');
        window.location.href = "/Login"
    }
    componentDidMount() {
        if (Cookies.get('giris') != "1" || Cookies.get('giris') == null) {
            window.location.href = "/Login"
        }
        if (Cookies.get("Token") == "" || Cookies.get("Token") == null) {
            window.location.href = "/Login";
        }
        /* if (Cookies.get('CariID') != "0") {
            document.getElementById("LBLcariadi").innerHTML = Cookies.get("CariAdi");
        } */
    }
    render() {
        var usrnm = Cookies.get("CariAdi")
        return (
            <div className="top-bar light-top-bar">{/*by default top bar is dark, add .light-top-bar class to make it light*/}
                 <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-8">
                            <a href="#" className="admin-logo">
                                <h1><img src="images/image003.jpg" width="140" alt /></h1>
                            </a>
                            <div className="left-nav-toggle  ">
                                <a href="#">
                                    <i className="glyphicon glyphicon-menu-hamburger" />
                                </a>
                            </div>{/*end nav toggle icon*/}<br/>
                            
                            <div className="mrb-user">
                                <h4>{usrnm}</h4>
                            </div>

                            {/*start search form*/}
                            
                            {/*end search form*/}
                        </div>
                        <div className="col-xs-4">
                            <ul className="list-inline top-right-nav">
                            
                                <li className="dropdown avtar-dropdown">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                        <img src="images/person.png" className="img-circle" width={30} alt />
                                    </a>
                                    <ul className="dropdown-menu top-dropdown">
                                        <li><a style={{fontFamily:"sans-serif"}} href="#"><i className="icon-bell" /> Anasayfa</a></li>
                                        <li><a style={{fontFamily:"sans-serif"}} href="/Hesap"><i className="icon-user" /> Hesap</a></li>
                                        <li className="divider" />
                                        <li><a  style={{fontFamily:"sans-serif"}} onClick={this.cikis} href="javascript: void(0);"><i className="icon-logout" /> Çıkış</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Header
