import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header';
import './login.css'
import Cookies from 'js-cookie'
import Axios from 'axios'
export class asd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
        this.tokenal.bind(this);
    }

    tokenal() {

        var usernameE = document.getElementById("TXTusername").value;
        var passwordD = document.getElementById("TXTpassword").value;
        var data = { "username": usernameE, "password": passwordD };
        Axios.post("https://mentalsoft.net:58/api/auth/login", data).then(res => {
            document.getElementById("Token").innerHTML = res.data.Token;
            const authAxios = Axios.create({
                baseURL: 'https://mentalsoft.net:58/api/yedek',
                headers: {
                    Authorization: 'Bearer ' + res.data.Token
                }
            })
            authAxios.get('https://mentalsoft.net:58/api/yedek').then(res2 => {
                console.log(res2.data);
            });
        }).catch(er => {
            alert(er);
        })


    }
    smsdeneme() {
        var num = document.getElementById("TXTnumara").value;
        var mesaj = document.getElementById("TXTmesaj").value;
        fetch(window.$apiurl + "/SMSYGONDER?numara="+num+"&mesaj="+mesaj+"", { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } }).then(res => res.json()).then(json => { })
    }
    smsdeneme2(){
        /* var num = document.getElementById("TXTnumara").value;
        var mesaj = document.getElementById("TXTmesaj").value;
        fetch("https://service.mobilpark.biz/http/SendMsg.aspx?username=5320383358&password=436815&messageType=sms&from=MEHMETALIDC&to="+num+"&text="+mesaj).then(res => res.json()).then(json => { }) */
    }
    componentDidMount() {

    }


    render() {
        return (
            <div>
                <input style={{ marginBottom: "10px" }} type="text" id="TXTusername" /><br />
                <input style={{ marginBottom: "10px" }} type="text" id="TXTpassword" /><br />
                <input className="btn btn-primary" type="button" onClick={this.tokenal} value="Token al"></input>
                <div id="Token"></div>
                <br /><br />
                <input style={{ marginBottom: "10px" }} type="text" id="TXTnumara" /><br />
                <input style={{ marginBottom: "10px" }} type="text" id="TXTmesaj" /><br />
                <input className="btn btn-primary" type="button" onClick={this.smsdeneme} value="SMS gonder"></input>
              {/*   <input style={{marginLeft:'10px'}} className="btn btn-primary" type="button" onClick={this.smsdeneme2} value="SMS gonder2"></input> */}

            </div>


        )
    }
}

export default asd
