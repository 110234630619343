import React, { Component } from 'react'
import Footer from '../GerekliSayfalar/Footer'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import '../App.css'
import './Sayfa.css'
import Cookies from 'js-cookie'
export class Anasayfa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isloaded: false
        }
    }

    componentDidMount() {
        var flag = Cookies.get("Flag");
        if (flag != "1") {
            window.location.href = "/Login"
        }
        this.kurcek();
        this.vericek();
    }
    vericek() {
        if (Cookies.get("durum") != "DEMİR") {
            return;
        }
        fetch(window.$apiurl + "/GRAPORCEK", { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                document.getElementById("LBLfstok").innerHTML = json[0]["FSTOK"];
                document.getElementById("LBLdepostok").innerHTML = json[0]["DEPOSTOK"];
                document.getElementById("LBLtopstok").innerHTML = json[0]["TOPSTOK"];
                document.getElementById("LBLmbag").innerHTML = json[0]["MBAG"];
                document.getElementById("LBLgastok").innerHTML = json[0]["GASTOK"];
                document.getElementById("LBLgatonaj").innerHTML = json[0]["GATONAJ"];
                document.getElementById("LBLgstonaj").innerHTML = json[0]["GSTONAJ"];
                document.getElementById("LBLgytonaj").innerHTML = json[0]["GYTONAJ"];
                document.getElementById("LBLytahsilat").innerHTML = json[0]["YTAHSILAT"];
                document.getElementById("LBLyodeme").innerHTML = json[0]["YODEME"];
                document.getElementById("LBLgtahsilat").innerHTML = json[0]["GTAHSILAT"];
                document.getElementById("LBLgodeme").innerHTML = json[0]["GODEME"];
                document.getElementById("LBLsattonaj").innerHTML = json[0]["SATTONAJ"];


            });

    }
    kurcek() {
        fetch(window.$apiurl + "/KURCEK", { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                document.getElementById("LBLdolaral").innerHTML = json[0]["DOLARA"];
                document.getElementById("LBLeuroal").innerHTML = json[0]["EUROA"];
                document.getElementById("LBLdolarsat").innerHTML = json[0]["DOLARS"];
                document.getElementById("LBLeurosat").innerHTML = json[0]["EUROS"];
                document.getElementById("LBLsterlinal").innerHTML = json[0]["STRA"];
                document.getElementById("LBLsterlinsat").innerHTML = json[0]["STRS"];
            });
    }
    gitFStok() {
        window.location.href = "/FabrikaStok";
    }
    gitMBag() {
        window.location.href = "/Borc";
    }
    gitSTonaj() {
        window.location.href = "/SatTonaj";
    }
    gitgaStok() {
        window.location.href = "/gAStok";
    }
    gitgSTonaj() {
        window.location.href = "/gSTonaji";
    }
    gitgATonaj() {
        window.location.href = "/gATonaji";
    }
    gitgYTonaj() {
        window.location.href = "/gYTonaji";
    }

    render() {
        var { items } = this.state


        return (
            <div>
                <Header />
                <Rightsidebar />
                <Leftsidebar />
                <section className="main-content container">



                    {/*page header start*/}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-3">
                                <h4>Genel Rapor</h4>
                            </div>
                            <div className="col-lg-1 text-right">
                                <div style={{ width: '100%' }}>
                                    <img src="images/united-states.png" height="35" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div>
                                    <h2 style={{ fontSize: '15px' }} id="LBLdolaral" className="mv-0">0</h2>
                                    <h2 style={{ fontSize: '15px' }} id="LBLdolarsat" className="mv-0">0</h2>
                                </div>
                            </div>
                            <div className="col-lg-1 text-right">
                                <img src="images/european-union.png" height="35" />
                            </div>
                            <div className="col-lg-2">
                                <div>
                                    <h2 style={{ fontSize: '15px' }} id="LBLeuroal" className="mv-0">0</h2>
                                    <h2 style={{ fontSize: '15px' }} id="LBLeurosat" className="mv-0">0</h2>
                                </div>
                            </div>
                            <div className="col-lg-1 text-right">
                                <img src="images/united-kingdom.png" height="35" />
                            </div>
                            <div className="col-lg-2">
                                <div>
                                    <h2 style={{ fontSize: '15px' }} id="LBLsterlinal" className="mv-0">0</h2>
                                    <h2 style={{ fontSize: '15px' }} id="LBLsterlinsat" className="mv-0">0</h2>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div id="anasayfa" className="panel-body table-responsive" style={{}}>
                        <div style={{ width: '100%' }}>
                            <div className="row">
                                <div>
                                    <div data-toggle="tooltip" data-placement="top" title="" style={{ cursor: 'pointer' }} onClick={this.gitFStok} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget2 bg-teal padding-0">
                                            <div className="row row-table" style={{ backgroundColor: 'chocolate', height: '94px' }}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <i class="fas fa-dolly-flatbed" style={{ fontSize: '45px' }}></i>
                                                    {/* <em className="icon-wallet fa-3x" /> */}
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLfstok" className="mv-0">0</h2>
                                                    <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Fabrİka Stok</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}
                                    <div style={{ cursor: 'pointer' }} onClick={this.gitMBag} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget2 bg-success padding-0">
                                            <div className="row row-table" style={{ backgroundColor: '#008080', height: '94px' }}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <i class="far fa-calendar-check" style={{ fontSize: '45px' }}></i>
                                                    {/* <em className="icon-bell fa-3x" /> */}
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLmbag" className="mv-0">0</h2>
                                                    <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Müşterİ Bağlantı</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}

                                    <div style={{ cursor: 'pointer' }} onClick={this.gitgATonaj} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget2 bg-success padding-0">
                                            <div className="row row-table" style={{ backgroundColor: '#808080', height: '94px' }}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <i class="fas fa-balance-scale-left" style={{ fontSize: '45px' }}></i>
                                                    {/* <em className="icon-calculator fa-3x" /> */}
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLgatonaj" className="mv-0">0</h2>
                                                    <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Günlük Alım Tonajı</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="widget2 bg-danger padding-0">
                                        <div className="row row-table" style={{ backgroundColor: 'red', height: '94px' }}>
                                            <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                <i class="fas fa-wallet" style={{ fontSize: '45px' }}></i>
                                                {/* <em className="icon-docs fa-3x" /> */}
                                            </div>
                                            <div className="col-xs-8 pv-15 text-center">
                                                <h2 style={{ fontSize: '15px' }} id="LBLytahsilat" className="mv-0">0</h2>
                                                <div style={{ fontFamily: 'Verdana' }} className="text-uppercase">Yapılacak Tahsİlat</div>
                                            </div>
                                        </div>
                                    </div>{/*end widget*/}
                                </div>{/*end col*/}
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget2 bg-teal padding-0">
                                            <div className="row row-table" style={{ backgroundColor: 'chocolate', height: '94px' }}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <i className="fas fa-dolly-flatbed" style={{ fontSize: '45px' }}></i>
                                                    {/* <em className="icon-notebook fa-3x" /> */}
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLdepostok" className="mv-0">0</h2>
                                                    <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Depo Stok</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}

                                    <div style={{ cursor: 'pointer' }} onClick={this.gitgaStok} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget2 bg-info padding-0">
                                            <div className="row row-table" style={{ backgroundColor: '#008080', height: '94px' }}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <i class="fas fa-dolly-flatbed" style={{ fontSize: '45px' }}></i>
                                                    {/* <em className="icon-tag fa-3x" /> */}
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLgastok" className="mv-0">0</h2>
                                                    <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Günlük Açılan Stok</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}

                                    <div style={{ cursor: 'pointer' }} onClick={this.gitgSTonaj} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget2 bg-info padding-0">
                                            <div className="row row-table" style={{ backgroundColor: '#808080', height: '94px' }}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <i class="fas fa-balance-scale-right" style={{ fontSize: '45px' }}></i>
                                                    {/* <em className="icon-cloud-upload fa-3x" /> */}
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLgstonaj" className="mv-0">0</h2>
                                                    <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Günlük Satış Tonajı</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget2 bg-danger padding-0">
                                    <div className="row row-table" style={{ backgroundColor: 'red', height: '94px' }}>
                                        <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                            <i class="fas fa-wallet" style={{ fontSize: '45px' }}></i>
                                            {/* <em className="icon-book-open fa-3x" /> */}
                                        </div>
                                        <div className="col-xs-8 pv-15 text-center">
                                            <h2 style={{ fontSize: '15px' }} id="LBLyodeme" className="mv-0">0</h2>
                                            <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Yapılacak Ödeme</div>
                                        </div>
                                    </div>
                                </div>{/*end widget*/}
                            </div>{/*end col*/}
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget2 bg-teal padding-0">
                                            <div className="row row-table" style={{ backgroundColor: 'chocolate', height: '94px' }}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <i className="fas fa-dolly-flatbed" style={{ fontSize: '45px' }}></i>
                                                    {/* <em className="icon-notebook fa-3x" /> */}
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLtopstok" className="mv-0">0</h2>
                                                    <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Toplam Stok</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}

                                    <div style={{ cursor: 'pointer' }} onClick={this.gitSTonaj} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget2 bg-info padding-0">
                                            <div className="row row-table" style={{ backgroundColor: '#008080', height: '94px' }}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <i class="fas fa-dolly" style={{ fontSize: '45px' }}></i>
                                                    {/* <em className="icon-tag fa-3x" /> */}
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLsattonaj" className="mv-0">0</h2>
                                                    <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Satılabİlİr Tonaj</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}

                                    <div style={{ cursor: 'pointer' }} onClick={this.gitgYTonaj} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget2 bg-info padding-0">
                                            <div className="row row-table" style={{ backgroundColor: '#808080', height: '94px' }}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <i class="fas fa-truck-loading" style={{ fontSize: '45px' }}></i>
                                                    {/* <em className="icon-cloud-upload fa-3x" /> */}
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLgytonaj" className="mv-0">0</h2>
                                                    <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Günlük Yükleme Tonajı</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="widget2 bg-danger padding-0">
                                        <div className="row row-table" style={{ backgroundColor: 'red', height: '94px' }}>
                                            <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                <i class="fas fa-wallet" style={{ fontSize: '45px' }}></i>
                                                {/* <em className="icon-docs fa-3x" /> */}
                                            </div>
                                            <div className="col-xs-8 pv-15 text-center">
                                                <h2 style={{ fontSize: '15px' }} id="LBLgtahsilat" className="mv-0">0</h2>
                                                <div style={{ fontFamily: 'Verdana' }} className="text-uppercase">Günlük Tahsİlat</div>
                                            </div>
                                        </div>
                                    </div>{/*end widget*/}
                                </div>{/*end col*/}
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget2 bg-danger padding-0">
                                    <div className="row row-table" style={{ backgroundColor: 'chocolate', height: '94px' }}>
                                        <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                            <i class="fas fa-folder-open" style={{ fontSize: '45px' }}></i>
                                            {/* <em className="icon-book-open fa-3x" /> */}
                                        </div>
                                        <div className="col-xs-8 pv-15 text-center">
                                            <h2 style={{ fontSize: '15px' }} id="LBLrapor1" className="mv-0"></h2>
                                            <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>RAPOR</div>
                                        </div>
                                    </div>
                                </div>{/*end widget*/}
                            </div>{/*end col*/}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget2 bg-danger padding-0">
                                    <div className="row row-table" style={{ backgroundColor: 'chocolate', height: '94px' }}>
                                        <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                            <i class="fas fa-folder-open" style={{ fontSize: '45px' }}></i>
                                            {/* <em className="icon-book-open fa-3x" /> */}
                                        </div>
                                        <div className="col-xs-8 pv-15 text-center">
                                            <h2 style={{ fontSize: '15px' }} id="LBLrapor2" className="mv-0"></h2>
                                            <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>RAPOR</div>
                                        </div>
                                    </div>
                                </div>{/*end widget*/}
                            </div>{/*end col*/}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget2 bg-danger padding-0">
                                    <div className="row row-table" style={{ backgroundColor: 'chocolate', height: '94px' }}>
                                        <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                            <i class="fas fa-folder-open" style={{ fontSize: '45px' }}></i>
                                            {/* <em className="icon-book-open fa-3x" /> */}
                                        </div>
                                        <div className="col-xs-8 pv-15 text-center">
                                            <h2 style={{ fontSize: '15px' }} id="LBLrapor3" className="mv-0"></h2>
                                            <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>RAPOR</div>
                                        </div>
                                    </div>
                                </div>{/*end widget*/}
                            </div>{/*end col*/}
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget2 bg-danger padding-0">
                                    <div className="row row-table" style={{ backgroundColor: 'red', height: '94px' }}>
                                        <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                            <i class="fas fa-wallet" style={{ fontSize: '45px' }}></i>
                                            {/* <em className="icon-book-open fa-3x" /> */}
                                        </div>
                                        <div className="col-xs-8 pv-15 text-center">
                                            <h2 style={{ fontSize: '15px' }} id="LBLgodeme" className="mv-0">0</h2>
                                            <div className="text-uppercase" style={{ fontFamily: 'Verdana' }}>Günlük Ödeme</div>
                                        </div>
                                    </div>
                                </div>{/*end widget*/}
                            </div>{/*end col*/}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )

    }
}

export default Anasayfa
