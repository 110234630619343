import React, { Component } from 'react'

export class Yuklemerapor extends Component {
    componentDidMount(){
        var bastarih = this.props.match.params.bastarih;
        var bittarih = this.props.match.params.bittarih;
        var cariID = this.props.match.params.cariID;
        var kapali = this.props.match.params.kapali;
        var plaka = this.props.match.params.plaka;
       // var tedid = this.props.match.params.tedid;
        sessionStorage.setItem("bastarih",bastarih);
        sessionStorage.setItem("bittarih",bittarih);
        sessionStorage.setItem("cariIDform",cariID);
        sessionStorage.setItem("plaka",plaka);
        sessionStorage.setItem("kapali",kapali);
       // sessionStorage.setItem("tedid",tedid);
        window.location.href = "/YuklemeForm";
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default Yuklemerapor
