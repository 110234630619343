import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import ReactToExcel from 'react-html-table-to-excel'
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class Sevkiyatlar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            flag: Cookies.get("Flag"),
            columnDefs: [
                { field: 'YUKREF', rowStyle: { background: 'black' }, headerName: 'OrdinoNo', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'YUKTARIH', onCellClicked: this.onCellClickTarih, headerName: 'Tarih', sortable: true, floatingFilter: true, filter: true, },
                { field: 'FIRMAADI', headerName: 'Firma Adı', sortable: true, floatingFilter: true, filter: true, },
                { field: 'PLAKA', cellStyle: { fontWeight: 'bold' }, headerName: 'Plaka', sortable: true, floatingFilter: true, filter: true, },
                { field: 'SOFOR', headerName: 'Şöför', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
                { field: 'TCNO', headerName: 'TC', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
                //{ field: 'TONAJ', sortable: true,cellStyle:{background:'rgb(57, 141, 19)',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'SEVKYERI', maxWidth: '80', headerName: 'SevkYeri', sortable: true, },
                { field: 'TUTAR', headerName: 'Tutar', sortable: true, type: 'rightAligned', },
                { field: 'LOGOFISNO', headerName: 'İrsaliyeNo', sortable: true },
                { field: 'LOGOFATNO', headerName: 'FaturaNo', sortable: true },
                { field: 'DURUM', maxWidth: '75', headerName: 'Durum', sortable: true }
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            rowClassRules: {
                'sick-days-breach': function (params) {
                    var numSickDays = params.data.DURUM;
                    return numSickDays == 'B';
                },
                // 'sick-days-breach': 'data.DURUM != Bekleyen',
            },
            defaultColDef: { flex: 1, },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        { field: 'ADI', headerName: 'Ürün Adı', minWidth: 150 },
                        { field: 'NKG', onCellClicked: this.onCellClickSevkDetay, headerName: 'NetKg', type: 'rightAligned' },
                        { field: 'MFIYAT', headerName: 'BirimFiyat', type: 'rightAligned' },
                        { field: 'YBAGMIK', headerName: 'BagMiktari', type: 'rightAligned' },
                        { field: 'YBAGNO', headerName: 'Bağlantı No', type: 'rightAligned' },
                        /*{ field: 'SUMMIKTAR',type:'rightAligned'},
                        { field: 'SUMHMIKTAR',type:'rightAligned'},
                        { field: 'SUMHTUTAR',type:'rightAligned'},*/


                    ],
                    defaultColDef: { flex: 1 },
                },
                getDetailRowData: function (params) {
                    //alert(params.data.BAGNO);
                    setTimeout(function () {
                        fetch(window.$apiurl + '/CSEVKIYATDETAYCEK?yukref=' + params.data.YUKREF, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                            .then(res => res.json())
                            .then(dataT => params.successCallback(dataT));

                    }, 1000);
                },
            },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null,
            kapatilacaksevkiyat: null,
            etarih: null,
            yuklineref:null
        }
        //this.vericek = this.vericek.bind(this);
        this.sevkiyatkapat = this.sevkiyatkapat.bind(this);
        this.tarihguncelle = this.tarihguncelle.bind(this);
        this.sevkdetayguncelle = this.sevkdetayguncelle.bind(this);
    }
    onGridReady = (params) => {
        document.getElementById("GRDdeneme").style.display = "";
        var flag = Cookies.get("Flag");
        var cariid = Cookies.get("CariID");
        var bastarih = document.getElementById("BASTARIH").value;
        var bittarih = document.getElementById("BITTARIH").value;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/ASEVKIYATCEK?bastarih=' + bastarih + '&bittarih=' + bittarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    componentDidMount() {
        this.tarihcek();
        document.getElementById("GRDdeneme").style.display = "none";
        document.getElementById("BTNmdl").style.display = "none";
        // this.vericek();
    }
    tarihcek() {
        fetch(window.$apiurl + '/TARIHCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                document.getElementById("BASTARIH").value = json[0]["TARIHG"];
                document.getElementById("BITTARIH").value = json[0]["TARIHB"];
            })
    }
    /*  vericek() {
         if (Cookies.get("durum") != "DEMİR") {
             return;
         }
         var flag = Cookies.get("Flag");
         var cariid = Cookies.get("CariID");
         var bastarih = document.getElementById("BASTARIH").value;
         var bittarih = document.getElementById("BITTARIH").value;
         fetch(window.$apiurl + '/SEVKIYATCEK?Flag=' + flag + '&cariID=' + cariid + '&bastarih=' + bastarih + '&bittarih=' + bittarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
             .then(res => res.json())
             .then(json => {
                 this.setState({
                     items: json
                 })
             });
     } */
    onCellClick = (params) => {
        document.getElementById("myModalLabel").innerHTML = params.data.YUKREF + " Numaralı Sevkiyatı Kapatmak İstediğinize Eminmisiniz ? ";
        if (Cookies.get('Adi') == "Merve") {
            document.getElementById("BTNmdl").style.display = "";
        }
        this.setState({ kapatilacaksevkiyat: params.data.YUKREF });
        //alert(this.state.kapatilacaksevkiyat);
    };
    onCellClickTarih = (params) => {
        document.getElementById("myModalLabeltarih").innerHTML = params.data.YUKREF + " Numaralı Sevkiyat Tarihi Güncelleme ";
        if (Cookies.get('Adi') == "Merve") {
            document.getElementById("TARIHDETAY").value = params.data.TARIH2;
            document.getElementById("BTNtarih").click();
        }
        this.setState({ kapatilacaksevkiyat: params.data.YUKREF, etarih: params.data.TARIH2 });
        //alert(this.state.kapatilacaksevkiyat);
    };
    onCellClickSevkDetay = (params) => {
        document.getElementById("myModalLabelsevkdetay").innerHTML = params.data.YUKREF + " Numaralı Sevkiyat KG Güncelleme ";
        if (Cookies.get('Adi') == "Merve") {
            document.getElementById("SEVKDETAY").value = params.data.FNKG;
            document.getElementById("BTNsevkkg").click();
        }
        this.setState({ kapatilacaksevkiyat: params.data.YUKREF,yuklineref:params.data.YUKLINEREF });
        console.log("yukref = " +params.data.YUKREF +" lineref = " +params.data.YUKLINEREF);
        //alert(this.state.kapatilacaksevkiyat);
    };
    sevkiyatkapat() {
        fetch(window.$apiurl + "/SEVKAPAT?yukref=" + this.state.kapatilacaksevkiyat+"&kapatuser="+Cookies.get('Adi'), { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {

            });
        document.getElementById("BTNkkpt").click();
    }
    tarihguncelle() {
        fetch(window.$apiurl + "/TARGUNCELLE?yukref=" + this.state.kapatilacaksevkiyat + "&etarih=" + this.state.etarih + "&ytarih=" + document.getElementById("TARIHDETAY").value + "&adduser=" + Cookies.get('Adi'), { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {

            });
        document.getElementById("BTNkkpt").click();
    }
    sevkdetayguncelle() {
        fetch(window.$apiurl + "/SEVKDETAYGUNCELLE?yukref=" + this.state.kapatilacaksevkiyat + "&yuklineref=" + this.state.yuklineref + "&yenikg=" + document.getElementById("SEVKDETAY").value , { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {

            });
        document.getElementById("BTNkkpt").click();
    }
    render() {
        var { items } = this.state;
        return (
            <div>
                <div>
                    <div>
                        <div>
                            <Header />
                            <Rightsidebar />
                            <Leftsidebar />
                            <section class="main-content container">
                                <input type="button" id="BTNkapat" data-toggle="modal" data-target="#signupModal" style={{ display: 'none' }} />
                                <input type="button" id="BTNtarih" data-toggle="modal" data-target="#signupModalTarih" style={{ display: 'none' }} />
                                <input type="button" id="BTNsevkkg" data-toggle="modal" data-target="#signupModalSevkDetay" style={{ display: 'none' }} />


                                {/*page header start*/}
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>Sevkiyatlar</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default collapsed">

                                    <div className="panel-body table-responsive ">
                                        <input id="BASTARIH" type="date" style={{ marginRight: "15px", marginBottom: "10px", border: "1px solid" }} /><input id="BITTARIH" style={{ border: "1px solid" }} type="date" /><input id="BTNkkpt" className="renkkodu" onClick={this.onGridReady} style={{ marginLeft: "10px", marginRight: "10px", height: "30px", width: "60px", borderRadius: "5px", color: "white" }} type="submit" value="Ara" />
                                        <input id="BTNmdl" className="renkkodu" data-toggle="modal" data-target="#signupModal" style={{ marginLeft: "7px", marginRight: "10px", height: "30px", width: "60px", borderRadius: "5px", color: "white" }} type="button" value="Kapat" />

                                        <div style={{ width: '100%', height: '100%' }}>
                                            <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 1200, width: "100%" }}>
                                                <AgGridReact
                                                    localeText={this.state.localeText}
                                                    animateRows={true}
                                                    enableRangeSelection={true}
                                                    paginationAutoPageSize={true}
                                                    columnDefs={this.state.columnDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    masterDetail={true}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    //onGridReady={this.onGridReady}
                                                    rowClassRules={this.state.rowClassRules}
                                                    rowData={this.state.rowData}
                                                    onCellClicked={this.onCellClick}
                                                />
                                            </div>
                                        </div>
                                        {/* <ReactToExcel table="EXtable" filename="SevkiyatExcel" sheet="sheet" buttonText="Yazdır" className="btnclas" /><br />

                                            <table className="table table-bordered" id="EXtable" style={{ border: "1px solid #ddd" }}>
                                                <thead>
                                                    <tr className="renkkodu" style={{   fontSize: "12px", fontFamily: "sans-serif" }}>
                                                        <th style={{ width: "90px" }}>Yük Tarih</th>
                                                        <th style={{ width: "80px" }}>Fiş NO</th>
                                                        <th style={{ width: "90px" }}>Plaka</th>
                                                        <th style={{ width: "40px" }}>YT</th>
                                                        <th style={{ width: "80px" }}>Birim Fiyat</th>
                                                        <th style={{ width: "180px" }}>Ürün Adı</th>
                                                        <th style={{ width: "80px" }}>Tonaj(KG)</th>
                                                        <th style={{ width: "80px" }}>Tutar(TL)</th>

                                                        <th style={{ width: "60px" }}>Durum</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items.map(item => (
                                                        <tr style={{ fontSize: "12px", backgroundColor: item.BGCOLOR }}>
                                                            <td>{item.YUKTARIH}</td>
                                                            <td>{item.FISNO}</td>
                                                            <td>{item.PLAKA}</td>
                                                            <td>{item.YTURU}</td>
                                                            <td>{item.BIRIMFIYAT}</td>
                                                            <td>{item.URUNADI}</td>
                                                            <td style={{ textAlign: "right" }}>{item.TONAJ}</td>
                                                            <td style={{ textAlign: "right" }}>{item.TUTAR}</td>

                                                            <td>{item.YDURUMU}</td>
                                                        </tr>
                                                    ))}


                                                </tbody>
                                            </table> */}
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="modal fade" id="signupModal" tabIndex={-1} role="dialog" aria-labelledby="signupModal">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                        <h3 className="modal-title" style={{ fontSize: '14px' }} id="myModalLabel">Kapama</h3>
                                    </div>
                                    <div className="modal-body">
                                        <div className="modal-form">

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div>
                                                        <button type="button" style={{ border: "0px", width: '100%' }} data-dismiss="modal" aria-label="Close" className="btn btn-primary renkkodu">İptal</button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div >
                                                        <button type="button" onClick={this.sevkiyatkapat} style={{ border: "0px", width: '100%' }} data-dismiss="modal" aria-label="Close" className="btn btn-primary renkkodu">Kapat</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr />
                                            <div className="text-center">
                                                <p>Mentalsoft</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="signupModalTarih" tabIndex={-1} role="dialog" aria-labelledby="signupModal">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                        <h3 className="modal-title" style={{ fontSize: '14px' }} id="myModalLabeltarih">Tarih Güncelleme</h3>
                                    </div>
                                    <div className="modal-body">
                                        <div className="modal-form">

                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div>
                                                        Tarih =
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div >
                                                        <input id="TARIHDETAY" type="date" style={{ marginRight: "15px", border: "1px solid" }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <button type="button" onClick={this.tarihguncelle} style={{ border: "0px", width: '100%' }} data-dismiss="modal" aria-label="Close" className="btn btn-primary renkkodu">Güncelle</button>
                                                </div>
                                            </div>

                                            <hr />
                                            <div className="text-center">
                                                <p>Mentalsoft</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="signupModalSevkDetay" tabIndex={-1} role="dialog" aria-labelledby="signupModal">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                        <h3 className="modal-title" style={{ fontSize: '14px' }} id="myModalLabelsevkdetay">KG Güncelleme</h3>
                                    </div>
                                    <div className="modal-body">
                                        <div className="modal-form">

                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div>
                                                        KG =
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div >
                                                        <input style={{paddingLeft:'10px'}} id="SEVKDETAY" type="text" style={{ marginRight: "15px", border: "1px solid" }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <button type="button" onClick={this.sevkdetayguncelle} style={{ border: "0px", width: '100%',marginLeft:'10px' }} data-dismiss="modal" aria-label="Close" className="btn btn-primary renkkodu">Güncelle</button>
                                                </div>
                                            </div>

                                            <hr />
                                            <div className="text-center">
                                                <p>Mentalsoft</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sevkiyatlar
