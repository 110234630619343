import React, { Component } from 'react'
import Footer from '../GerekliSayfalar/Footer'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class Alisbaglantilari extends Component {

    constructor(props){
        super(props);
        this.state = {
            items :[],
            itemsdetay:[],
            isloaded:false,
            columnDefs: [
                { field: 'BAGNO',headerName:'Baglantı No', cellRenderer: 'agGroupCellRenderer',sortable: true, floatingFilter: true,  filter: true, },
                { field: 'BTARIHI', sortable: true, floatingFilter: true,  filter: true, },
                { field: 'FIRMAADI', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
                { field: 'BAGTUT', sortable: true,cellStyle:{background:'rgb(57, 141, 19)',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'N08MM', sortable: true,type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'N10MM', sortable: true,type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'N10mmm..', sortable: true,type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'BIRIM', sortable: true,type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            defaultColDef: { flex: 1, },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        { field: 'URUNADI',minWidth:150},
                        { field: 'FIYATI',type:'rightAligned'},
                        { field: 'KURC' },
                        { field: 'MIKTARI',type:'rightAligned'},
                        { field: 'HMIKTAR',type:'rightAligned'},
                        { field: 'HTUTUAR',type:'rightAligned'},
                        /*{ field: 'SUMMIKTAR',type:'rightAligned'},
                        { field: 'SUMHMIKTAR',type:'rightAligned'},
                        { field: 'SUMHTUTAR',type:'rightAligned'},*/
                        
                        
                    ],
                    defaultColDef: { flex: 1 },
                },
                getDetailRowData: function (params) {
                    //alert(params.data.BAGNO);
                    setTimeout(function () {
                        fetch(window.$apiurl+'/BAGDETAYCEK?bref='+params.data.BAGNO,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
                            .then(res => res.json())
                            .then(dataT => params.successCallback(dataT));

                    }, 1000);
                },
            },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }
      
    }
    componentDidMount(){
       
    }
  
    onGridReady = (params) => {
        var cariid = Cookies.get("CariID");
        var flag = Cookies.get("Flag");
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl+'/ALISBAGCEK?flag='+flag+'&cariID='+cariid,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    render() {
        var {items,isloaded,itemsdetay} = this.state;
       
     
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">



                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Alış Bağlantıları</h4>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default collapsed">
                            
                            <div className="panel-body table-responsive ">
                            <div style={{ width: '100%', height: '100%' }}>
                                    <div className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            paginationAutoPageSize={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            masterDetail={true}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            onGridReady={this.onGridReady}
                                            rowData={this.state.rowData}
                                        />
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                        
                    </section>
                </div>
           
           
            </div>
        )
  
    }
}

export default Alisbaglantilari
