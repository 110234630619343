import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class CariHaraketleri extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            columnDefs: [
                { field: 'TARIH', headerName: 'Tarih', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'FISNO', headerName: 'FişNo', sortable: true, floatingFilter: true, filter: true, },
                { field: 'ISLEMTURU', minWidth: 120, headerName: 'İşlem Türü', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
                { field: 'ALACAK', headerName: 'Alacak', sortable: true,  type: 'rightAligned',},
                { field: 'BORC', headerName: 'Borç', sortable: true, type: 'rightAligned', },
                { field: 'BAKIYE', headerName: 'Bakiye', sortable: true, type: 'rightAligned', },
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            defaultColDef: { flex: 1, },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }
    }
    onGridReady = (params) => {
        var cariid = Cookies.get("CBAGREF");
        
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/CEXTRECEK?cariID=' + cariid, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    componentDidMount() {
        //this.vericek();
    }
    /*  vericek() {
         var cariid = Cookies.get("CariID");
         fetch(window.$apiurl + '/CARIEXTRECEK?cariID=' + cariid,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
             .then(res => res.json())
             .then(json => {
                 this.setState({
                     items: json
                 })
                 //document.getElementById("TXTsumalacak").innerHTML = json[0]["SUMALACAK"];
                 //document.getElementById("TXTsumborc").innerHTML = json[0]["SUMBORC"];
                 //document.getElementById("TXTsumbakiye").innerHTML = json[0]["SUMBAKIYE"];
             })
     } */
    render() {
        var { items } = this.state
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class='main-content container'>
                        <div style={{ display: "none" }} id="sipref"></div>


                        {/*page header start*/}
                        <div className='page-header'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <h4 id="deneme">Cari Hareketleri</h4>
                                </div>
                            </div>
                        </div>
                        <div className='panel panel-default collapsed'>

                            <div className='panel-body table-responsive '>
                                <div style={{ width: '100%', height: '100%' }}>
                                    <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 1200, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            onGridReady={this.onGridReady}
                                            rowData={this.state.rowData}
                                        />
                                    </div>
                                </div>
                                {/*   <table className='table table-bordered' style={{ border: '1px solid #ddd' }}>
                                    <thead>

                                        <tr style={{ backgroundColor: '#cfcdcd', fontSize: '12px', fontFamily: 'sans-serif' }}>
                                            <th>Fiş Türü</th>
                                            <th>Tarih</th>
                                            <th>Alacak</th>
                                            <th>Borç</th>
                                            <th>Bakiye</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => (
                                            <tr style={{ fontSize: '12px' }}>
                                                <td>{item.FISTURU}</td>
                                                <td>{item.TARIH}</td>
                                                <td>{item.ALACAK}</td>
                                                <td>{item.BORC}</td>
                                                <td>{item.BAKIYE}</td>
                                            </tr>
                                        ))}
                                        <tr className="bckcolor">
                                            <td></td>
                                            <td></td>
                                            <td>Toplam Alacak</td>
                                            <td>Toplam Borç</td>
                                            <td>Toplam Bakiye</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td id="TXTsumalacak">0</td>
                                            <td id="TXTsumborc">0</td>
                                            <td id="TXTsumbakiye">0</td>
                                        </tr>

                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                    </section>
                </div>

            </div>
        )
    }
}

export default CariHaraketleri
