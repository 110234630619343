import React, { Component } from 'react'

export class YuklemeForm extends Component {
    componentDidMount(){
       // alert(sessionStorage)
      
        document.getElementById("DVframe").innerHTML = "<iframe  style='width:100%;height:1400px' src='https://mentalsoft.net:65/mehmetalidcyukleme.aspx?bastarih="+sessionStorage.getItem("bastarih")+"&bittarih="+sessionStorage.getItem("bittarih")+"&cariID="+sessionStorage.getItem("cariIDform")+"&kapali="+sessionStorage.getItem("kapali")+"'/>";
   
        

      // alert(sessionStorage.getItem("bastarih"));
    }
    render() {
        return (
            <div>
                <div style={{width:"100%",height:'1500px'}} id="DVframe">
                </div>
            </div>
        )
    }
}

export default YuklemeForm
