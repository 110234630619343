import React, { Component } from 'react'

export class TeklifY extends Component {
    componentDidMount(){
        var id = this.props.match.params.id;
       // var tedid = this.props.match.params.tedid;
        sessionStorage.setItem("tekref",id);
       // sessionStorage.setItem("tedid",tedid);
        window.location.href = "/Tform";
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default TeklifY
