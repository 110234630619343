import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import "./Sayfa.css"
import Cookies from 'js-cookie'


import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class CariEkstre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isloaded: false,
            columnDefs: [
                { field: 'ISLEM_TURU', minWidth: 120, headerName: 'FişTürü', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'TARIH', headerName: 'Tarih', sortable: true, floatingFilter: true, filter: true },
                { field: 'ALACAK', headerName: 'Alacak', sortable: true, floatingFilter: true, filter: true },
                { field: 'BORC', headerName: 'Borç', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true },
                { field: 'BAKIYE', headerName: 'Bakiye', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
            ],
            defaultColDef: { flex: 1, },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };
        var cariref = sessionStorage.getItem("cariref");
        fetch(window.$apiurl + '/CARIDETAYCEK?cariref=' + cariref, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    componentDidMount() {
        var cariref = sessionStorage.getItem("cariref");
        //alert(cariref);
        this.caridetay(cariref);
    }
    caridetay(cariref) {
        // alert(sipref);
        fetch(window.$apiurl + '/CARIDETAYCEK?cariref=' + cariref, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                //console.log(json);
                this.setState({
                    items: json,
                    isloaded: true
                })
            });
    }

    geridon() {
        window.location.href = "/CariBilgileri";
    }

    render() {
        var cariadi = sessionStorage.getItem("cariadi");
        var carikodu = sessionStorage.getItem("carikodu");
        var { isloaded, items } = this.state;


        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">



                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <button type="button" style={{ border: "0px" }} onClick={this.geridon} className="btn btn-primary renkkodu">Carilere Dön</button>
                                </div>
                            </div>
                        </div>

                        <div className="panel panel-default collapsed">
                            <div className="panel-body table-responsive ">
                                <h5>{cariadi} ({carikodu})</h5>
                                {/* <table className="table table-bordered" style={{ border: "1px solid #ddd" }}>
                                    <thead>
                                        <tr className="renkkodu" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <th style={{ textAlign: "center" }}>Fiş Türü</th>
                                            <th style={{ textAlign: "center" }}>Tarih</th>
                                            <th style={{ textAlign: "center" }}>Alacak</th>
                                            <th style={{ textAlign: "center" }}>Borç</th>
                                            <th style={{ textAlign: "center" }}>Bakiye</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {items.map(item => (


                                            <tr style={{ fontSize: "12px" }}>
                                                <td style={{ textAlign: "center" }}>{item.FISTURU}</td>
                                                <td style={{ textAlign: "center" }}>{item.TARIH}</td>
                                                <td style={{ textAlign: "right" }}>{item.ALACAK}</td>
                                                <td style={{ textAlign: "right" }}>{item.BORC}</td>
                                                <td style={{ textAlign: "right" }}>{item.BAKIYE}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> */}
                                <div style={{ width: '100%', height: '100%' }}>
                                    <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 500, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            onGridReady={this.onGridReady}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default CariEkstre
