import React, { Component } from 'react'
import Axios from 'axios'
import htmlcanvas from 'html2canvas'
export class bahittin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            title: '',
            content: '',
            image: null
        }
        this.mailgonder.bind(this);
        this.deneme.bind(this);
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    };

    handleImageChange = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    };
    deneme() {
     
    }
    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);
        let form_data = new FormData();
        form_data.append('image', this.state.image, this.state.image.name);
        form_data.append('title', this.state.title);
        form_data.append('content', this.state.content);
        let url = 'https://mentalsoft.net:92/api/yedek/RESIMEKLE';
        Axios.post(url, form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                console.log(res.data);
                this.vericek();
            })
            .catch(err => console.log(err))
    };
    mailgonder(gelen) {
        var mailadres = document.getElementById("TXTmailadres").value;
        if (mailadres == "") {
            alert("Mail adresi giriniz ! ");
            return;
        }
        let conf = { "LOGREF": gelen, "KIME": mailadres };
        console.log(conf);
        console.log(gelen);
        Axios.post("https://mentalsoft.net:92/api/yedek/MAILGONDER", conf).then(res => {
            console.log("respones :", res);
        })
    }
    vericek() {
        document.getElementById("DVekle").innerHTML = "";
        Axios.get(`https://mentalsoft.net:92/api/yedek/RESIMCEK`)
            .then(res => {
                const persons = res.data;
                this.setState({ items: persons });
                //console.log(res.data[0]["ISIM"]);
                /* for (var i = 0; i < res.data.length; i++) {
                     console.log(i);
                     document.getElementById("DVekle").innerHTML += "<tr><td>" + res.data[i].LOGICALREF + "</td><td><img height='30' src='https://mentalsoft.net:92/photos/" + res.data[i].ISIM + "' alt /></td><td>" + res.data[i].ISIM + "</td><td>" + res.data[i].ADDATE + "</td><td><span style='height:30px' onClick='{() => this.mailgonder(1)}' class='btn btn-primary'>Mail Gönder</span></td></tr>"
                 }*/


            })
    }
    componentDidMount() {
        this.vericek();
    }
    render() {

        return (
            <div>
                {/*{this.state.items.map(item => (
                    <div>{item.Cadi}</div>
                ))}   */}
                <form style={{ marginTop: "10px", marginBottom: "10px" }} onSubmit={this.handleSubmit}>
                    <div className="fileupload btn btn-primary btn-icon margin-r-5">
                        <i className="fa fa-cloud" />   <span>Upload</span>
                        <input type="file"
                            className="upload"
                            id="image"
                            accept="image/png, image/jpeg" onChange={this.handleImageChange} required />
                    </div>
                    <input className="btn btn-primary" type="submit" value="Resim Ekle" />
                    <input style={{ marginLeft: '10px', border: "1px solid black" }} type="text" id="TXTmailadres"></input>
                </form>
                <div id="scdeneme" className="panel-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Resim</th>
                                <th>Resim Adı</th>
                                <th>Eklenme Tarihi</th>
                                <th>#</th>
                            </tr>
                        </thead>
                        <tbody id="DVekle">
                            {this.state.items.map(item => (
                                <tr key={item.LOGICALREF}>
                                    <td>{item.LOGICALREF}</td>
                                    <td><img style={{ height: '30px' }} src={item.linkresim} /></td>
                                    <td>{item.ISIM}</td>
                                    <td>{item.ADDATE}</td>
                                    <td><input type="button" onClick={() => this.mailgonder(item.LOGICALREF)} className="btn btn-primary" value="Mail gönder " /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        )
    }
}

export default bahittin
