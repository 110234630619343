import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import ReactToExcel from 'react-html-table-to-excel'
import Cookies from 'js-cookie'
import '../Sayfalar/renk.css'
export class cBankaBilgileri extends Component {
    render() {
        return (
            <div>
                <div>
                    <div>
                        <div>
                            <Header />
                            <Rightsidebar />
                            <Leftsidebar />
                            <section class="main-content container">
                                {/*page header start*/}
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>Banka Bilgileri</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default collapsed">

                                    <div className="panel-body table-responsive ">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="widget bg-danger padding-0">
                                                    <div className="row row-table">
                                                        <div className="col-xs-12 pv-15 text-center">
                                                            <h2 style={{ fontSize: '15px' }} className="mv-0">AKBANK T.A.Ş</h2>
                                                            <div className="text-uppercase">ATATÜRK ORG.SAN.ŞUBE</div>
                                                            <div className="text-uppercase">TR79 0004 6006 3488 8000 1605 33</div>
                                                        </div>
                                                    </div>
                                                </div>{/*end widget*/}
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="widget bg-primary padding-0">
                                                    <div className="row row-table">
                                                        <div className="col-xs-12 pv-15 text-center">
                                                            <h2 style={{ fontSize: '15px' }} className="mv-0">T.İŞ BANKASI</h2>
                                                            <div className="text-uppercase">YILDIZ TİC.ŞUBE</div>
                                                            <div className="text-uppercase">TR50 0006 4000 0014 3940 0041 32</div>
                                                        </div>
                                                    </div>
                                                </div>{/*end widget*/}
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="widget bg-dark padding-0">
                                                    <div className="row row-table">
                                                        <div className="col-xs-12 pv-15 text-center">
                                                            <h2 style={{ fontSize: '15px' }} className="mv-0">QNB FİNANSBANK</h2>
                                                            <div className="text-uppercase">ATATÜRK O.S.B</div>
                                                            <div className="text-uppercase">TR46 0011 1000 0000 0087 8148 47</div>
                                                        </div>
                                                    </div>
                                                </div>{/*end widget*/}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="widget bg-success padding-0">
                                                    <div className="row row-table">
                                                        <div className="col-xs-12 pv-15 text-center">
                                                            <h2 style={{ fontSize: '15px' }} className="mv-0">T.İŞ BANKASI</h2>
                                                            <div className="text-uppercase">YILDIZ TİC. ŞUBE</div>
                                                            <div className="text-uppercase">4394 / 0004132</div>
                                                            <div className="text-uppercase">TR50 0006 4000 0014 3940 0041 32</div>
                                                        </div>
                                                    </div>
                                                </div>{/*end widget*/}
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="widget bg-warning padding-0">
                                                    <div className="row row-table">
                                                        <div className="col-xs-12 pv-15 text-center">
                                                            <h2 style={{ fontSize: '15px' }} className="mv-0">VAKIFBANK</h2>
                                                            <div className="text-uppercase">PINARBAŞI ŞB.</div>
                                                            <div className="text-uppercase">S00421 / 00158007293524603</div>
                                                            <div className="text-uppercase">TR06 0001 5001 5800 7293 5246 03</div>
                                                        </div>
                                                    </div>
                                                </div>{/*end widget*/}
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="widget bg-teal padding-0">
                                                    <div className="row row-table">
                                                        <div className="col-xs-12 pv-15 text-center">
                                                            <h2 style={{ fontSize: '15px' }} className="mv-0">GARANTİ BANKASI</h2>
                                                            <div className="text-uppercase">EGE TİCARİ ŞB.</div>
                                                            <div className="text-uppercase">1668-6298876 HS</div>
                                                            <div className="text-uppercase">TR84 0006 2001 6680 0006 2988 76</div>
                                                        </div>
                                                    </div>
                                                </div>{/*end widget*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default cBankaBilgileri
