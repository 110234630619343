import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import './renk.css'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class Aylikgelir extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemsdetay: [],
            columnDefs: [
                { field: 'YIL', headerName: 'Yıl', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            defaultColDef: { flex: 1, },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        { field: 'AY', headerName: 'Ay', minWidth: 150 },
                        { field: 'YUKGELIR', headerName: 'Yük Gelirleri', type: 'rightAligned' },
                        { field: 'YUKGIDER', headerName: 'Yük Giderleri', type: 'rightAligned' },
                        { field: 'YUKBAKIYE', headerName: 'Yük Bakiyesi', type: 'rightAligned' },
                        { field: 'TOPKG', headerName: 'Toplam KG', type: 'rightAligned' },
                        /*{ field: 'SUMMIKTAR',type:'rightAligned'},
                        { field: 'SUMHMIKTAR',type:'rightAligned'},
                        { field: 'SUMHTUTAR',type:'rightAligned'},*/

                        {/* <td>{itemss.AY}</td>
                                                        <td style={{textAlign:"right"}}>{itemss.YUKGELIR}</td>
                                                        <td style={{textAlign:"right"}} >{itemss.YUKGIDER}</td>
                                                        <td style={{textAlign:"right"}}>{itemss.YUKBAKIYE}</td>
                                                        <td style={{textAlign:"right"}}>{itemss.TOPKG}</td> */}
                    ],
                    defaultColDef: { flex: 1 },
                },
                getDetailRowData: function (params) {
                    //alert(params.data.BAGNO);
                    setTimeout(function () {
                        fetch(window.$apiurl + '/AYLIKGELIRDETAYCEK?yil=' + params.data.YIL, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                            .then(res => res.json())
                            .then(dataT => params.successCallback(dataT));

                    }, 1000);
                },
            },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }
        //this.vericek = this.vericek.bind(this);
        //this.aydetay = this.aydetay.bind(this);
    }
    componentDidMount() {
       // this.vericek();
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/AYLIKGELIRCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    /*  vericek() {
         if (Cookies.get("durum") != "DEMİR") {
             return;
         }
         fetch(window.$apiurl + '/AYLIKGELIRCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
             .then(res => res.json())
             .then(json => {
                 this.setState({
                     items: json
                 })
             })
     } */
    /* aydetay(gelen) {
        document.getElementById("myModalLabel").innerHTML = gelen + " Yılının Detayı";
        //alert(gelen);
        fetch(window.$apiurl + '/AYLIKGELIRDETAYCEK?yil=' + gelen, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    itemsdetay: json
                })
            })
    } */
    render() {
        var { items, itemsdetay } = this.state
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">



                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Aylık Gelir </h4>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default collapsed">

                            <div className="panel-body table-responsive ">
                                <div style={{ width: '100%', height: '100%' }}>
                                    <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            paginationAutoPageSize={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            masterDetail={true}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            onGridReady={this.onGridReady}
                                            rowData={this.state.rowData}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
        )
    }
}

export default Aylikgelir
