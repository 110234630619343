import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class Satis extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            columnDefs: [
                { field: 'CARIADI', minWidth: 120, headerName: 'Cari Adı', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'VADEBILGI', headerName: 'Vade Bilgili', sortable: true, floatingFilter: true, filter: true, },
                { field: 'TONAJ', headerName: 'Tonaj', sortable: true, floatingFilter: true, filter: true, type: 'rightAligned', },
                { field: 'OFIYAT', headerName: 'O Fiyat', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
            ],
            defaultColDef: { flex: 1, },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
        }
        // this.vericek = this.vericek.bind(this);
    }
    onGridReady = (params) => {
        document.getElementById("GRDdeneme").style.display = "";
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };
        var tarih = document.getElementById("TARIHH").value;
        fetch(window.$apiurl + '/SATICEK?tarih=' + tarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    componentDidMount() {
        document.getElementById("GRDdeneme").style.display = "none";
        //document.getElementById("BASTARIH").value =yyyy+"-"+mm+"-"+dd;
        this.tarihcek();
    }
    tarihcek() {
        fetch(window.$apiurl + '/TARIHCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                //document.getElementById("BASTARIH").value = json[0]["TARIHG"];
                document.getElementById("TARIHH").value = json[0]["TARIHB"];
            })
    }
    /* vericek() {
        if (Cookies.get("durum") != "DEMİR") {
            return;
        }
        var flag = Cookies.get("Flag");
        if (flag != "1") {
            window.location.href = "/Login"
        }
        var tarih = document.getElementById("TARIHH").value;
        fetch(window.$apiurl + '/SATICEK?tarih=' + tarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    items: json
                })
            })
    } */
    render() {
        var { items } = this.state
        return (
            <div>
                <div>
                    <div>
                        <div>
                            <Header />
                            <Rightsidebar />
                            <Leftsidebar />
                            <section className="main-content container">



                                {/*page header start*/}
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>Satış Bilgileri</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default collapsed">

                                    <div className="panel-body table-responsive ">
                                        <input id="TARIHH" type="date" style={{ marginRight: "15px", border: "1px solid", marginBottom: "10px" }} /><input className="renkkodu" onClick={this.onGridReady} style={{ marginLeft: "10px", marginRight: "10px", height: "30px", width: "60px", borderRadius: "5px", color: "white" }} type="submit" value="Ara" />
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 500, width: "100%" }}>
                                                <AgGridReact
                                                    localeText={this.state.localeText}
                                                    animateRows={true}
                                                    enableRangeSelection={true}
                                                    columnDefs={this.state.columnDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Satis
