import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'

import './renk.css'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class Stok extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemdetay: [],
            columnDefs: [
                { field: 'TEDADI', headerName: 'Tedarikci Adı', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            defaultColDef: { flex: 1, },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        { field: 'URUNKODU', headerName: 'Ürün Kodu' },
                        { field: 'STOKMIK', headerName: 'Stok.Mik', type: 'rightAligned' },
                        { field: 'YUKLENEN', headerName: 'Yüklenen', type: 'rightAligned' },
                        { field: 'REZERVEMIK', headerName: 'Rezerve Mik', type: 'rightAligned' },
                        { field: 'KALANSTOK', headerName: 'Kalan Stok', type: 'rightAligned' },
                    ],
                    defaultColDef: { flex: 1 },
                },
                getDetailRowData: function (params) {
                    //alert(params.data.BAGNO);
                    setTimeout(function () {
                        fetch(window.$apiurl + '/STOKDETAYCEK?tedadi=' + params.data.TEDADI, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                            .then(res => res.json())
                            .then(dataT => params.successCallback(dataT));

                    }, 1000);
                },
            },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }
        //this.stokdetay = this.stokdetay.bind(this);
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/STOKCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    componentDidMount() {

        //this.vericek();
    }
    /*  vericek() {
         if (Cookies.get("durum") != "DEMİR") {
             return;
         }
         fetch(window.$apiurl + '/STOKCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
             .then(res => res.json())
             .then(json => {
                 this.setState({
                     items: json
                 })
             })
     } */
    /* stokdetay(gelen) {

        fetch(window.$apiurl + '/STOKDETAYCEK?tedadi=' + gelen, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    itemdetay: json
                })
            })
    } */
    render() {
        var { items, itemdetay } = this.state
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">



                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Stok </h4>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default collapsed">

                            <div className="panel-body table-responsive ">
                                <div style={{ width: '100%', height: '100%' }}>
                                    <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            paginationAutoPageSize={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            masterDetail={true}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            onGridReady={this.onGridReady}
                                            rowData={this.state.rowData}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                        <h4 className="modal-title" id="myModalLabel" style={{ fontSize: "15px" }}>Detay </h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="panel-body table-responsive ">
                                            <table className="table table-bordered" style={{ border: "1px solid #ddd" }}>
                                                <thead>
                                                    <tr className="renkkodu" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                                                        <th>Ürün Kodu</th>
                                                        <th>Stok.Mik</th>
                                                        <th>Yüklenen</th>
                                                        <th>Rezerve Mik</th>
                                                        <th>Kalan Stok</th>
                                                        <th>Açıklama</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {itemdetay.map(itemm => (
                                                        <tr style={{ fontSize: "12px" }}>
                                                            <td>{itemm.URUNKODU}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.STOKMIK}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.YUKLENEN}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.REZERVEMIK}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.KALANSTOK}</td>
                                                            <td>{itemm.ACIKLAMA}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" style={{ border: "0px" }} className="btn btn-primary renkkodu" data-dismiss="modal">Kapat</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>

            </div>
        )
    }
}

export default Stok
