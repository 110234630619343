import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import CariLeftsidebar from '../GerekliSayfalar/CariLeftsidebar'
import Cookies from 'js-cookie'
export class Calisbaglantilari extends Component {
    constructor(props){
        super(props);
        this.state = {
            items :[],
            isloaded:false
        }
    }
    componentDidMount(){
        this.vericek();
    }
    vericek(){
        var cariid = Cookies.get("CariID");
        var flag = Cookies.get("Flag");
        fetch(window.$apiurl+'/ALISBAGCEK?flag='+flag+'&cariID='+cariid)
        .then(res => res.json())
        .then(json => {
          //console.log(json);
          this.setState({
            items: json,
            isloaded: true
          })
        });
    }
    render() {
        var {items,isloaded} = this.state;
        return (
            <div>
            <div>
                <Header />
                <CariLeftsidebar />
                <section class="main-content container">



                    {/*page header start*/}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Cari Alış Bağlantıları</h4>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default collapsed">
                            
                            <div className="panel-body table-responsive ">
                                <table className="table table-bordered" style={{border:"1px solid #ddd"}}>
                                    <thead>
                                        <tr style={{backgroundColor:"#cfcdcd",fontSize:"12px",fontFamily:"sans-serif"}}>
                                            <th>Bağlantı No</th>
                                            <th>B tarihi</th>
                                            <th style={{width:"230px"}}>Firma Adı</th>
                                            <th>Bag.Tutarı</th>
                                            <th>N08mm_KG</th>
                                            <th>N10mm_KG</th>
                                            <th>N12-14-16mm..</th>
                                            <th>Bir.Fiyat</th>
                                            <th>Kalan Tutar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => (
                                            <tr style={{fontSize:"12px"}}>
                                            <td style={{textAlign:"center",width:"90px"}}>{item.BAGNO}</td>
                                            <td>{item.BTARIHI}</td>
                                            <td>{item.FIRMAADI}</td>
                                            <td style={{backgroundColor:"#398d13",color:"white",textAlign:"right"}}>{item.BAGTUT}</td>
                                            <td style={{textAlign:"right"}}>{item.N08MM}</td>
                                            <td style={{textAlign:"right"}}>{item.N10MM}</td>
                                            <td style={{textAlign:"right"}}>{item.N10mmm}</td>
                                            <td style={{textAlign:"right"}}>{item.BIRIM}</td>
                                            <td style={{backgroundColor:"#c20306",color:"white",textAlign:"right"}}>{item.KALANTUT}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </section>
            </div>
       
       
        </div>
        )
    }
}

export default Calisbaglantilari
