import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import '../Sayfalar/renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class cBekleyenbaglantilar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemsdetay: [],
            isloaded: false,
            columnDefs: [
                { field: 'FISREF', minWidth: 120, onCellClicked: this.onCellClickfisref, headerName: 'Bagno', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                Cookies.get('Flag') == "1" ? { field: 'FIRMAADI', headerName: 'Firma Adi', sortable: true, floatingFilter: true, filter: true, } : '',
                { field: 'FISTIPI', maxWidth: 80, headerName: 'FişTipi', sortable: true, floatingFilter: true, filter: true, },
                { field: 'FTURU', maxWidth: 80, headerName: 'FişTürü', sortable: true, floatingFilter: true, filter: true, },
                { field: 'TARIH', headerName: 'Bag.Tarihi', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
                { field: 'BOLGE', headerName: 'Bölge', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
                //{ field: 'TONAJ', sortable: true,cellStyle:{background:'rgb(57, 141, 19)',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'TONAJ', headerName: 'Bag.Tonaj', cellStyle: { background: '#97beff' }, sortable: true, type: 'rightAligned', },
                { field: 'TUTAR', headerName: 'Bag.Tutar', cellStyle: { background: '#97beff' }, sortable: true, type: 'rightAligned', },
                { field: 'ORTFIYAT', headerName: 'Bag.Fiyatı', sortable: true, type: 'rightAligned', },
                { field: 'YUKTONAJ', headerName: 'YükTonaj', cellStyle: { background: '#7ce670' }, sortable: true, type: 'rightAligned', },
                { field: 'YUKTUTAR', headerName: 'YükTutar', cellStyle: { background: '#7ce670' }, sortable: true, type: 'rightAligned' },
                { field: 'BAKIYETONAJ', onCellClicked: this.onCellClick, headerName: 'BakiyeTonaj', cellStyle: { background: '#f47353' }, sortable: true, type: 'rightAligned', },
                { field: 'BAKIYETUTAR', onCellClicked: this.onCellClick, headerName: 'BakiyeTUtar', cellStyle: { background: '#f47353' }, sortable: true, type: 'rightAligned', },
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            columnDefsdetay: [

                { field: 'ORDREF', maxWidth: 100, headerName: 'OrdinoNo', sortable: true, floatingFilter: true, filter: true, },
                { field: 'YUKTARIH', minWidth: 110, headerName: 'Yük Tarihi', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'PLAKA', cellStyle: { fontWeight: 'bold', color: 'red' }, maxWidth: 80, headerName: 'Plaka', sortable: true, floatingFilter: true, filter: true, },
                { field: 'URUNADI', headerName: 'Ürün Adi', sortable: true, floatingFilter: true, filter: true, },
                { field: 'NKG', maxWidth: 100, headerName: 'YükTonaj', type: 'rightAligned', filter: 'Yük Tonaj', sortable: true },
                //{ field: 'TONAJ', sortable: true,cellStyle:{background:'rgb(57, 141, 19)',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'MFIYAT', maxWidth: 100, headerName: 'Birim Fiyat', sortable: true, type: 'rightAligned', },
                { field: 'SATISTUTAR', maxWidth: 100, headerName: 'Satış Tutar', sortable: true, type: 'rightAligned', },
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            defaultColDef: { flex: 1, },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        { field: 'URUNADI', headerName: 'Ürün Adı', minWidth: 150 },
                        { field: 'FIYATI', headerName: 'Fiyatı', type: 'rightAligned' },
                        { field: 'MIKTARI', headerName: 'Bağlantı Tonajı', type: 'rightAligned' },
                        { field: 'HMIKTAR', headerName: 'Yüklenen Tonaj', type: 'rightAligned' },
                        /*{ field: 'SUMMIKTAR',type:'rightAligned'},
                        { field: 'SUMHMIKTAR',type:'rightAligned'},
                        { field: 'SUMHTUTAR',type:'rightAligned'},*/


                    ],
                    defaultColDef: { flex: 1 },
                },
                getDetailRowData: function (params) {
                    //alert(params.data.BAGNO);
                    setTimeout(function () {
                        fetch(window.$apiurl + '/cBAGDETAYCEK?bref=' + params.data.FISREF + '&fistipi=' + params.data.FISTIPI, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                            .then(res => res.json())
                            .then(dataT => params.successCallback(dataT));

                    }, 1000);
                },
            },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }

    }
    onGridReady = (params) => {
        var cariid = Cookies.get("CariID");
        var flag = Cookies.get("Flag");
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/CBEKLEYENBAGCEK?cariID=' + cariid, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };

    onCellClickfisref = (params) => {
        //if(params.data.FTURU == 'B')
            window.open('https://mehmetalidc.online/BaglantiFormWeb/'+params.data.FISREF, '_blank');
        //window.open('https://mehmetalidc.online/SiparisForm/'+params.data.FISREF, '_blank');
        
    };

    onCellClick = (params) => {
        document.getElementById("DVgrid").style.display = "none";
        document.getElementById("myModalLabel").innerHTML = params.data.FISREF + " Numaralı Yükleme"
        document.getElementById("BTNmdl").click();
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData2: data });
        };
        fetch(window.$apiurl + '/CYUKDETAYCEK?fisref=' + params.data.FISREF, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
        document.getElementById("DVgrid").style.display = "";
        //const colId = params.column.getId();
        //alert(params.data.FISREF);
        //onst selectedCountry = params.data.country;
        //const selectedCity = params.data.city;
        //const allowedCities = countyToCityMap(selectedCountry);
        //const cityMismatch = allowedCities.indexOf(selectedCity) < 0;

    };
    render() {
        var { items, isloaded, itemsdetay } = this.state;


        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">



                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Bekleyen Bağlantılar</h4>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default collapsed">
                            <input id="BTNmdl" style={{ display: 'none' }} type="button" value="d" data-toggle="modal" data-target=".bs-example-modal-lg" />
                            <div className="panel-body table-responsive ">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div style={{ color: 'black' }} className="alert alert-danger" role="alert">
                                            - Fiş numarasının yanındaki Ok'a basarak bağlantı detaylarını görebilirsiniz.<br />
                                            - BakiyeTonaj ve BakiyeTutar bilgilerine tıklıyarak yükleme detaylarını görebilirisiniz.<br />
                                            - Başlıkların Altındaki gri alana basarak arama yapabilirsiniz.<br />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: '100%', height: '100%' }}>
                                    <div className="ag-theme-balham" style={{ height: 1200, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            paginationAutoPageSize={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            masterDetail={true}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            onGridReady={this.onGridReady}
                                            rowData={this.state.rowData}
                                        //onCellDoubleClicked={this.onCellClick}    
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                        <h4 className="modal-title" id="myModalLabel" style={{ fontSize: "15px" }}>Numaralı Yükleme </h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="panel-body table-responsive ">
                                            <div id="DVgrid" style={{ width: '100%', height: '100%' }}>
                                                <div className="ag-theme-balham" style={{ height: 200, width: "100%" }}>
                                                    <AgGridReact
                                                        localeText={this.state.localeText}
                                                        animateRows={true}
                                                        enableRangeSelection={true}
                                                        paginationAutoPageSize={true}
                                                        columnDefs={this.state.columnDefsdetay}
                                                        defaultColDef={this.state.defaultColDef}
                                                        //onGridReady={this.onCellClick}
                                                        rowData={this.state.rowData2}
                                                    //onCellDoubleClicked={this.onCellClick}    
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" style={{ border: "0px" }} className="btn btn-primary renkkodu" data-dismiss="modal">Kapat</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>


            </div>
        )

    }
}

export default cBekleyenbaglantilar
