import React, { Component } from 'react'

export class CariLeftsidebar extends Component {
    render() {
        return (
            <aside className="float-navigation light-navigation">
  <div className="nano">
    <div className="nano-content">
      <ul className="metisMenu nav" id="menu" >
        <li className="nav-heading"><span>Mental-Erp</span></li>
        <li>
          <a  href="javascript: void(0);" style={{fontFamily:"sans-serif"}} aria-expanded="true"><i  className="icon-plane" /> Bağlantılar <span className="fa arrow" /></a>
          <ul className="nav-second-level nav" aria-expanded="true">
            <li><a style={{fontFamily:"sans-serif"}} href="/Calisbaglantilari"><i className="icon-plane" style={{marginRight:"5px"}}/>Alış Bağlantıları</a></li>
            <li><a style={{fontFamily:"sans-serif"}} href="/Satisbaglantilari"><i className="icon-plane" style={{marginRight:"5px"}} />Satış Bağlantıları</a></li>
          </ul>
        </li>
        <li>
          <a style={{fontFamily:"sans-serif"}} href="javascript: void(0);" aria-expanded="true"><i className="icon-social-dropbox" /> Siparişler <span className="fa arrow" /></a>
          <ul className="nav-second-level nav" aria-expanded="true">
            <li><a style={{fontFamily:"sans-serif"}} href="/Bekleyensiparisler"><i className="icon-social-dropbox" style={{marginRight:"5px"}}/>Bekleyen</a></li>
            <li><a style={{fontFamily:"sans-serif"}} href="/TamamlananSiparisler"><i className="icon-social-dropbox" style={{marginRight:"5px"}} />Tamamlanan</a></li>
          </ul>
        </li>
        <li>
          <a style={{fontFamily:"sans-serif"}} href="/Sevkiyatlar"><i className="icon-docs" /> Sevkiyatlar </a>
        </li>


        <li>
          <a style={{fontFamily:"sans-serif"}} href="/Uyelog"><i className="icon-loop" /> Cari Hareketlerim </a>
        </li>
        <li>
          <a style={{fontFamily:"sans-serif"}} href="/Hesap"><i className="icon-settings"/> Hesap </a>
        </li>
        <li>
          <a style={{fontFamily:"sans-serif"}} href="#" onClick={this.cikis}><i className="icon-logout" /> Çıkış</a>
        </li>
        {/*<li><a href="landing/index.html" target="_blank" class="bg-primary"><i class="icon-star"></i>Landing page</a></li>*/}
      </ul>
    </div>{/*nano content*/}
  </div>{/*nano scroll end*/}
  
</aside>
        )
    }
}

export default CariLeftsidebar
