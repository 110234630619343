import React, { Component } from 'react'
import Footer from '../GerekliSayfalar/Footer'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import "./Sayfa.css"
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class CariBilgileri extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isloaded: false,
            columnDefs: [
                { field: 'CARIADI', minWidth: 120, headerName: 'Cari Adı', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'CARIKODU', headerName: 'Cari Kodu', sortable: true, floatingFilter: true, filter: true },
                //{ field: 'YETKILI', headerName: 'Yetkili', sortable: true, floatingFilter: true, filter: true },
                { field: 'SEHIR', headerName: 'Şehir', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true },
                { field: 'SONALIM', headerName: 'SonYükleme', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true },
                //{ field: 'ORTFIYAT', headerName: 'OrtBrFiyat', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
                { field: 'BAKIYE', headerName: 'Bakiye', cellStyle: { background: '#f47353' }, filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
            ],
            defaultColDef: { flex: 1, },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
        }
        //this.vericek = this.vericek.bind(this);
        //this.caridetay = this.caridetay.bind(this);
    }
    onGridReady = (params) => {
        document.getElementById("GRDdeneme").style.display = "";
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        
        const updateData = (data) => {
            this.setState({ rowData: data });
        };
        //var arama = document.getElementById("TXTarama").value;
        fetch(window.$apiurl + '/CARICEK?cariadi=&durum='+sessionStorage.getItem("durum"), { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    componentDidMount() {
        var flag = Cookies.get("Flag");
        if (flag != "1") {
            window.location.href = "/Login"
        }
        this.vericek();
        sessionStorage.setItem("durum","Bakiyeli");
    }
    /*caridetay(cariref) {
        document.getElementById("myModalLabel").innerHTML = cariref + " Numaralı Fatura Detayı";
        // alert(sipref);
        fetch(window.$apiurl + '/CARIDETAYCEK?cariref=' + cariref)
            .then(res => res.json())
            .then(json => {
                //console.log(json);
                this.setState({
                    caridetayitems: json,
                    isloadeddetay:true
                })
            });
    }*/
    vericek() {
        //var arama = document.getElementById("TXTarama").value;
        /*  fetch(window.$apiurl + '/CARICEK?cariadi=' + arama, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
             .then(res => res.json())
             .then(json => {
                 //console.log(json);
                 this.setState({
                     items: json,
                     isloaded: true
                 })
             }); */
    }

    gecis(cariref, cariadi, carikodu) {
        sessionStorage.setItem("cariref", cariref);
        sessionStorage.setItem("cariadi", cariadi);
        sessionStorage.setItem("carikodu", carikodu);
        window.location.href = "/CariEkstre"

        //alert(cariref);
    }
    onCellClick = (params) => {
        sessionStorage.setItem("cariref", params.data.CARIREF);
        sessionStorage.setItem("cariadi", params.data.CARIADI);
        sessionStorage.setItem("carikodu", params.data.CARIKODU);
        window.location.href = "/CariEkstre"
    };
    grd(gelen){
        sessionStorage.setItem("durum", gelen);
        document.getElementById("BTNgridcagir").click();
    }
    render() {
        var { items, isloaded } = this.state;
        /* if (!isloaded) {
            return (
                <div>
                    <div>
                        <Header />
                        <Rightsidebar />
                        <Leftsidebar />
                        <section class="main-content container">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h4>Cari Bilgileri</h4>
                                    </div>
                                </div>
                            </div>
                            <input type="text" id="TXTarama"></input>
                            <div className="loading"></div>
                        </section>
                    </div>

                </div>
            )
        } */


        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">
                    <input id="BTNgridcagir" type="button" onClick={this.onGridReady}/>


                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-2">
                                    <h4>Cari Bilgileri</h4>
                                </div>
                                <div className="col-sm-3">
                                <input className="renkkodu" onClick={() => this.grd('Bakiyeli')} style={{ marginLeft: "10px", marginRight: "10px", height: "30px", width: "120px", borderRadius: "5px", color: "white" }} type="submit" value="Bakiyesi Olanlar" />
                                <input className="renkkodu" onClick={() => this.grd('hepsi')} style={{ marginLeft: "10px", marginRight: "10px", height: "30px", width: "60px", borderRadius: "5px", color: "white" }} type="submit" value="Hepsi" />
                                </div>
                            </div>
                        </div>

                        <div className="panel panel-default collapsed">
                            <div className="row">
                                <div className="col-lg-3">
                                    {/* <input onChange={this.onGridReady} type="text" placeholder="Cari Adını yazınız" id="TXTarama" className="inptext" style={{ marginTop: "10px", marginLeft: "15px", border: "1px solid #9b8f90" }}></input> */}

                                </div>
                            </div>
                            <div className="panel-body table-responsive ">
                                <div style={{ width: '100%', height: '100%' }}>
                                    <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 500, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            onGridReady={this.onGridReady}
                                            onRowClicked={this.onCellClick}
                                        />
                                    </div>
                                </div>
                                {/* <table className="table table-bordered" style={{ border: "1px solid #ddd" }}>
                                    <thead>
                                        <tr className="renkkodu" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <th>#</th>
                                            <th style={{ width: "230px" }}>Cari Adı</th>
                                            <th>Cari Kodu</th>
                                            <th style={{ width: "160px" }}>Yetkili</th>
                                            <th>Şehir</th>
                                            <th>Son Alım</th>
                                            <th style={{ textAlign: "center" }}>OrtBrFiyat</th>
                                            <th>Bakiye</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => (
                                            <tr style={{ fontSize: "12px" }}>
                                                <td style={{ textAlign: "center", width: "33px", cursor: "pointer" }}><span class="label label-info renkkodu" onClick={() => this.gecis(item.CARIREF, item.CARIADI, item.CARIKODU)}>Detay</span></td>
                                                <td>{item.CARIADI}</td>
                                                <td>{item.CARIKODU}</td>
                                                <td>{item.YETKILI}</td>
                                                <td>{item.SEHIR}</td>
                                                <td style={{ textAlign: "right" }}>{item.SONALIM}</td>
                                                <td style={{ textAlign: "center" }}>{item.ORTFIYAT}</td>
                                                <td style={{ textAlign: "right" }}>{item.BAKIYE}</td>

                                            </tr>
                                        ))}


                                    </tbody>
                                </table> */}
                            </div>
                        </div>

                    </section>
                </div>

            </div>
        )
    }
}

export default CariBilgileri
