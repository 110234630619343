import React, { Component } from 'react'
import './Form2.css'
import logo from './image003.jpg'
import logo2 from './NewG.jpg'
import QRcode from 'react-qr-code'
import htmlcanvas from 'html2canvas'
import axios from 'axios'
import Cookies from 'js-cookie'
export class SDetay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            sipno: sessionStorage.getItem("sipno"),
            link: "https://mehmetalidc.online/bagform/" + sessionStorage.getItem("sipno")
        }
        this.yazdir = this.yazdir.bind(this);
        this.onayla = this.onayla.bind(this);
        this.SMSsifre = this.SMSsifre.bind(this);
        this.formonay = this.formonay.bind(this);
        this.ssal = this.ssal.bind(this);
        this.smsgonder = this.smsgonder.bind(this);
    }
    componentDidMount() {
        if (sessionStorage.getItem("sipno") == null) {
            window.location.href = "/login";
        }
        this.vericek();
        document.getElementById("TXTsifre").style.display = "none";
        document.getElementById("BTNsifreonay").style.display = "none";
    }
    dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
    ssal() {
        var mailadres = document.getElementById("TXTmailadresi").value;
        if (mailadres == "") {
            alert("Lütfen mail adresi giriniz !!");
            return;
        }
        htmlcanvas(document.querySelector("#scdeneme")).then(canvas => {

            var base64URL = canvas.toDataURL('image/jpeg');
            var dataurl = canvas.toDataURL('image/jpeg', 0.8);
            var blob = this.dataURLtoBlob(dataurl);
            var fd = new FormData();
            fd.append("image", blob, "thumb.jpg");
            let url = 'https://mentalsoft.net:32/api/oguzlarresim/ALISRESIM?gkime=' + mailadres;
            axios.post(url, fd, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + Cookies.get('Token')
                },
            })
                .then(res => {
                    console.log(res.data);
                    this.vericek();
                    alert("Mail Gönderilmiştir");
                })
                .catch(err => console.log(err))

            //let conf = { "BASE64": base64URL };
            // console.log(base64URL.replace("data:image/jpeg;base64,", ""));

            /*fetch('https://mentalsoft.net:92/api/yedek/POSTIM', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  "BASE64": base64URL.replace("data:image/jpeg;base64,", ""),
            })
          })
          .then((response) => response.json())
          .then ((res) => { });*/
        });

    }
    SMSsifre() {
        /*var telno = document.getElementById("TXTtelno").value;
        if (telno.length > 11 || telno.length == 0) {
            alert("Lütfen Doğru telefon numarası giriniz");
        } else {
            fetch(window.$apiurltokensiz + '/SMSBGONDER?kime=' + telno)
                .then(res => res.json())
                .then(json => {
                    alert(json[0]["SONUC"]);
                });
        }*/
    }
    vericek() {
        var sipno = sessionStorage.getItem("sipno");
        fetch(window.$apiurltokensiz + '/SIPFORMCEK?sipref=' + sipno)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    items: json
                })
                if (json[0]["WONAYDURUM"] == "1") {
                    document.getElementById("BTNonayla").style.display = "none";
                }
                if (json[0]["TURU"] != "1") {
                    document.getElementById("LBLalt").innerHTML = "SATICI FİRMA";
                    document.getElementById("LBLalt2").innerHTML = "ALICI FİRMA (Kaşe &amp; İmza)";
                }
                document.getElementById("LBLbagtarih").innerHTML = json[0]["BTARIH"];
                document.getElementById("LBLcariadi").innerHTML = json[0]["CARIADI"];
                document.getElementById("LBLcariadres").innerHTML = json[0]["ADRES"];
                document.getElementById("LBLvno").innerHTML = json[0]["VDAIRE"] + " : " + json[0]["VNO"];
                document.getElementById("LBLtelefon").innerHTML = json[0]["TELEFON"];
                // document.getElementById("LBLdeneme").innerHTML=json[1]["URUNADI"];
                document.getElementById("LBLbagno").innerHTML = json[0]["BNO"];
                document.getElementById("TXTtonaji").innerHTML = json[0]["TONAJ"] + " KG";
                document.getElementById("TXTtutari").innerHTML = json[0]["TUTARI"] + " TL";
                document.getElementById("TXTformadi").innerHTML = json[0]["TURU"];
                console.log(json);
            });
    }
    yazdir() {
        document.getElementById("BTNyazdir").style.display = "none";
        document.getElementById("TXTsifre").style.display = "none";
        document.getElementById("BTNsifreonay").style.display = "none";
        document.getElementById("BTNonayla").style.display = "none";
        document.getElementById("BTNmailgonder").style.display = "none";
        document.getElementById("BTNsms").style.display = "none";
        window.print();
        document.getElementById("BTNyazdir").style.display = "";
        document.getElementById("BTNmailgonder").style.display = "";
        document.getElementById("BTNsms").style.display = "";
        //document.getElementById("TXTsifre").style.display = "";
        //document.getElementById("BTNsifreonay").style.display ="";
        document.getElementById("BTNonayla").style.display = "";
    }
    smsgonder() {
        var telno = document.getElementById("TXTtelno").value;
        var link = this.state.link;
        if (telno.length > 11 || telno.length == 0) {
            alert("Lütfen Doğru telefon numarası giriniz");
        } else {
            fetch(window.$apiurltokensiz + '/GSMS?mesajj=' + link + '&kime=' + telno)
                .then(res => res.json())
                .then(json => {
                    //alert(json[0]["SONUC"]);
                });
        }
    }
    onayla() {
        document.getElementById("TXTsifre").style.display = "";
        document.getElementById("BTNsifreonay").style.display = "";
        //document.getElementById("BTNsifreal").style.display ="";
    }
    formonay() {
        var sipno = document.getElementById("LBLbagno").innerHTML;
        var sifre = document.getElementById("TXTsifre").value;
        fetch(window.$apiurltokensiz + '/SIPONAYLA?sifre=' + sifre + '&bagno=' + sipno)
            .then(res => res.json())
            .then(json => {
                if (json[0]["SONUC"] != "") {
                    alert("ONAYLANDI");
                    window.location.href = "/SDetay";
                } else {
                    alert("ŞİFRE HATALI");
                }
            })
    }
    render() {
        var { items } = this.state;
        return (
            <div style={{ backgroundColor: "gray" }}>
                <div style={{ marginLeft: "5%", marginBottom: "5px", marginTop: "5px" }}>
                    <input className="renkkodu" type="button" style={{ marginLeft: "10px", height: "30px", width: "100px", borderRadius: "5px", color: "white" }} onClick={this.yazdir} id="BTNyazdir" value="Yazdır"></input>
                    <input className="renkkodu" type="button" style={{ marginLeft: "10px", height: "30px", width: "100px", borderRadius: "5px", color: "white" }} onClick={this.onayla} id="BTNonayla" value="Onayla"></input>
                    <input type="password" style={{ marginLeft: "15px" }} id="TXTsifre"></input>
                    <input className="renkkodu" type="button" id="BTNsifreonay" onClick={this.formonay} style={{ marginLeft: "10px", height: "30px", width: "100px", borderRadius: "5px", color: "white" }} value="Şifre Onayla" ></input>
                    <input className="renkkodu" type="button" style={{ marginLeft: "10px", height: "30px", width: "100px", borderRadius: "5px", color: "white" }} data-toggle="modal" data-target="#signupModal" id="BTNmailgonder" value="Mail Gönder"></input>
                    <input className="renkkodu" type="button" style={{ marginLeft: "10px", height: "30px", width: "100px", borderRadius: "5px", color: "white" }} data-toggle="modal" data-target="#signupModalsms" id="BTNsms" value="SMS Gönder"></input>
                </div>
                <div id="scdeneme">
                    <div id="anacizgi">
                        <div id="anacizgi2">
                            <div id="kutu" style={{ paddingLeft: "20px" }}>
                                <img src={logo2} style={{ float: "left", marginLeft: "10px", marginTop: "10px" }}></img>


                                <p style={{ marginTop: "10px", marginLeft: "0px", float: "right", marginRight: "10px" }}><QRcode value={this.state.link} id="qrcode" size="80" /></p>
                            </div>
                            <div style={{ textAlign: 'center', width: "100%" }}>
                                <p className="baglanti" id="LBLformadi" style={{ marginLeft: "10px", float: "left", marginTop: "5px", marginRight: "10px", fontFamily: "sans-serif" }}>ALIŞ ONAY FORMU</p>
                            </div>

                            <br />
                            <div id="orta1">
                                <p className="yazi1">
                                    <br />
                                    <br />
                                </p><p style={{ color: "#3c3c3c", fontSize: "15px", fontFamily: "sans-serif" }} id="LBLcariadi">C</p><br />
                                <span id="LBLcariadres" style={{ paddingLeft: "8px" }}>C</span><br />
                                <span id="LBLvno" style={{ paddingLeft: "8px" }}>C</span>
                                <p />
                            </div>
                            <table className="ortayazi">
                                <tbody><tr className="cltr">
                                    <td style={{ paddingLeft: "5px", width: "120px" }} className="cltr">
                                        Bağlantı No
                                    </td>
                                    <td id="LBLbagno" style={{ paddingLeft: "5px", width: "150px" }} className="cltr">
                                        0
                                    </td>
                                </tr>
                                    <tr className="cltr">
                                        <td style={{ paddingLeft: "5px" }} className="cltr">
                                            Bağ.Tarihi
                                        </td>
                                        <td id="LBLbagtarih" style={{ paddingLeft: "5px" }} className="cltr">
                                            0
                                        </td>
                                    </tr>
                                    <tr className="cltr">
                                        <td style={{ paddingLeft: "5px" }} className="cltr">
                                            Telefon
                                        </td>
                                        <td style={{ paddingLeft: "5px" }} id="LBLtelefon" className="cltr">
                                            02324415050
                                        </td>
                                    </tr>
                                    <tr className="cltr">
                                        <td style={{ paddingLeft: "5px" }} className="cltr">
                                            Fax
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody></table>
                            <br />
                            <br /><table className="tablo2" border={1}>
                                <tbody><tr className="cltr">
                                    <td className="cltr" style={{ paddingLeft: "5px" }}>Ürün tanımı</td>
                                    <td className="cltr" style={{ textAlign: "center" }}>Miktar</td>
                                    <td className="cltr" style={{ textAlign: "center" }}>B.Fiyatı
                                    </td>
                                    <td className="cltr" style={{ textAlign: "center" }}>Tutarı</td>
                                    <td className="cltr" style={{ paddingLeft: "5px" }}>Sipariş Detay Bilgisi</td>
                                </tr>
                                    {items.map(item => (
                                        <tr className="cltr">
                                            <td className="cltr" style={{ paddingLeft: "5px" }}>{item.URUNADI}</td>
                                            <td className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}>{item.MIKTARI} KG</td>
                                            <td className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}>{item.FIYATI}</td>
                                            <td className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}>{item.TOPTUAR} {item.KUR}</td>
                                            <td className="cltr" id="LBLdeneme" />
                                        </tr>
                                    ))}

                                    <tr className="cltr">

                                    </tr>

                                    <tr className="cltr">
                                        <td className="cltr" style={{ paddingLeft: "5px" }}>Fiyatlarımıza KDV Dahil Edilmiştir.</td>
                                        <td id="TXTtonaji" className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}> </td>
                                        <td className="cltr" />
                                        <td id="TXTtutari" className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}> </td>
                                        <td className="cltr" />
                                    </tr>
                                </tbody></table>
                            <table style={{ marginTop: "10px" }} border="0">
                                <tr>
                                    <td style={{ width: "100px", textAlign: 'center', border: "1px solid" }}>Teslim Yeri</td>
                                    <td style={{ width: "400px", border: "1px solid" }}></td>
                                    <td style={{ width: "50px" }}></td>
                                    <td style={{ border: "1px solid", width: "100px" }}>Ödeme Tarihi</td>
                                    <td style={{ border: "1px solid", width: "100px" }}>Ödeme Tutarı</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ border: "1px solid", width: "100px" }}> </td>
                                    <td style={{ border: "1px solid", width: "100px" }}></td>
                                </tr>
                            </table>
                            <p> Diğer Hususlar; <br />- Malzemeler TSE 708'e uygun olup, ANALİZ garantilidir.
                                <br />- Malzemelerin araçlardan indirilmesi tarafınıza aittir.
                                <br />- Ödemenin gününde yapılmaması durumunda günlük % 0,2 gecikme bedeli faizi uygulanacaktır.
                                <br />- Fabrika kantarı ile şantiyeniz kantarı arasında meydana gelebilecek  +,-% 0,3 'e kadar olan farkları normal tolerans olarak kabul edilecektir.
                                <br />- Müşteri, iş bu sipariş teklif ve kabul formunun konusunu ve yukarıda yazılı ürünleri onayladığı taktirde her türlü itiraz ve talep haklarından<br /> peşinen vazgeçmiştir.
                                <br />- İhtilaf halinde İzmir mahkemelerinin selahiyeti taraflar kabul etmektedir.
                                <br />- İş bu teklifimizin kabulü halinde firmanız kaşe ve yetkili imzası ile onaylayarak tarafımıza gönderilmesini rica ederiz.
                                <br />- İş bu sözleşme tek taraflı feshedilemez.
                                <br />- 6698 sayılı kişisel verilerin korunması kanunu çerçevesinde;kişisel verilerinizin toplanma şekilleri,işlenme amaçları,işlemenin hukuki<br /> nedenleri ve haklarınız konularında sizleri en şeffaf şekilde ürün/hizmet teklifi,pazarlama ve satış,evrak işlemleri faaliyetlerinde kullanılabilmesi,bu<br /> çerçevede firmamız tarafından kişisel verilerinizin işleneceğini bildiririz. </p>

                            <br />
                            <br />
                            <div id="alt1">
                                <p className="satici"><u id="LBLalt" style={{ marginLeft: "5px" }}>ALICI FİRMA</u></p>
                                <p className="satici2"><u id="LBLalt2">SATICI FİRMA (Kaşe &amp; İmza)</u></p>
                                <p className="satici"><u><img src={logo} style={{ height: "35px" }}></img></u></p>
                                <p className="satici2"><u></u></p>
                            </div>
                            <div id="alt2">

                                {/*<b style={{ paddingLeft: "10px" }}>Merkez</b> &nbsp; Anadolu Cad.No:41/1 MYPLAZA Kat:12 D:12001-12002 Bayraklı / İZMİR<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  Telefon:0(232) 486 65 35 pbx<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Faks    :0(232) 462 64 94*/}
                                <table style={{ paddingLeft: "10px", marginLeft: "20px", marginTop: "7px" }}>
                                    <tr style={{border: "1px solid black" }} >
                                        <td style={{ width: "700px" }}> Merkez :  Çetin Emeç Bul.1322 Cad. No: 75/15 Öveçler Çankaya/Ankara </td>
                                    </tr>
                                    <tr style={{ border: "1px solid black" }} >
                                        <td style={{ width: "700px" }}> Şube :  Cumhuriyet Mah. No : 136/1 Sk. No:2 Koyundere Menemen/İZMİR </td>
                                    </tr>
                                </table>
                                <table style={{ paddingLeft: "10px", marginLeft: "20px", marginTop: "7px" }}>
                                    <tr>
                                        <td style={{ border: "1px solid black", width: "80px", textAlign: "center" }}>Telefon</td>
                                        <td style={{ width: "10px" }}>&nbsp;</td>
                                        <td style={{ border: "1px solid black", width: "110px", }}>+90 232 364 34 44</td>
                                        <td style={{ width: "310px" }}>&nbsp;</td>
                                        <td style={{ border: "1px solid black", width: "80px", textAlign: "center" }}>Fax</td>
                                        <td style={{ border: "1px solid black", width: "110px", }}>+90 312 473 67 99</td>
                                    </tr>
                                </table>
                                <table style={{ marginLeft: "20px" }}>
                                    <tr>
                                        <td>E-mail: info@mehmetalidc.com | Web: www.mehmetalidc.com | B2B Sys : www.mehmetalidc.com</td>
                                    </tr>
                                </table>
                            </div>
                            {/*<p id="enalt"> E-mail:  zms@zmsdemir.com   -  Web: www.zmsdemir.com</p>*/}
                        </div>
                    </div>
                    <div className="modal fade" id="signupModal" tabIndex={-1} role="dialog" aria-labelledby="signupModal">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                    <h3 className="modal-title" id="myModalLabel">Mail Adresi</h3>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-form">
                                        <form role="form">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <input type="text" data-toggle="tooltip" id="TXTmailadresi" data-placement="top" title="Cari Adı" className="form-control" placeholder="Mail adresini giriniz" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <button type="button" style={{ backgroundColor: "#F21D27", border: "0px" }} data-dismiss="modal" aria-label="Close" onClick={this.ssal} className="btn btn-primary">Mail Gönder</button>
                                                </div>
                                            </div>
                                        </form>
                                        <hr />
                                        <div className="text-center">
                                            <p>Mentalsoft</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="signupModalsms" tabIndex={-1} role="dialog" aria-labelledby="signupModalsms">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                    <h3 className="modal-title" id="myModalLabel">SMS Gönderme Alanı</h3>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-form">
                                        <form role="form">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <input type="text" data-toggle="tooltip" id="TXTtelno" data-placement="top" title="Cari Adı" className="form-control" placeholder="Telefon numaranızı giriniz" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <button type="button" style={{ backgroundColor: "#F21D27", border: "0px" }} data-dismiss="modal" aria-label="Close" onClick={this.smsgonder} className="btn btn-primary">SMS Gönder</button>
                                                </div>
                                            </div>
                                        </form>
                                        <hr />
                                        <div className="text-center">
                                            <p>Mentalsoft</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SDetay
