import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import './renk.css'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class istakip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemsdetay: [],
            columnDefs: [
                { field: 'YIL', onCellClicked: this.onCellClick, headerName: 'Yıl', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            columnDefsdetay: [
                /*  <td>{itemm.YUKTARIH}</td>
                 <td style={{ textAlign: "right" }}>{itemm.NETKG}</td>
                 <td style={{ textAlign: "right" }}>{itemm.ORTBFIYAT}</td>
                 <td style={{ textAlign: "right" }}>{itemm.SATIS}</td>
                 <td style={{ textAlign: "right" }}>{itemm.ALIS}</td>
                 <td style={{ textAlign: "right" }}>{itemm.NAKLIYE}</td>
                 <td style={{ textAlign: "right" }}>{itemm.BRUTKAR}</td>
                 <td style={{ textAlign: "right" }}>{itemm.NETKAR}</td> */
                { field: 'YUKTARIH', maxWidth: 100, headerName: 'OrdinoNo', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'NETKG', minWidth: 110, headerName: 'Yük Tarihi', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'ORTBFIYAT', cellStyle: { fontWeight: 'bold', color: 'red' }, maxWidth: 80, headerName: 'Plaka', sortable: true, floatingFilter: true, filter: true, },
                { field: 'SATIS', headerName: 'Ürün Adi', sortable: true, floatingFilter: true, filter: true, },
                { field: 'ALIS', maxWidth: 100, headerName: 'YükTonaj', type: 'rightAligned', filter: 'Yük Tonaj', sortable: true },
                //{ field: 'TONAJ', sortable: true,cellStyle:{background:'rgb(57, 141, 19)',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'NAKLIYE', maxWidth: 100, headerName: 'Birim Fiyat', sortable: true, type: 'rightAligned', },
                { field: 'BRUTKAR', maxWidth: 100, headerName: 'Satış Tutar', sortable: true, type: 'rightAligned', },
                { field: 'NETKAR', maxWidth: 100, headerName: 'Satış Tutar', sortable: true, type: 'rightAligned', },
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            defaultColDef: { flex: 1, },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
        }
        // this.vericek = this.vericek.bind(this);
        //this.isdetay = this.isdetay.bind(this);
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/AYLIKGELIRCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    onCellClick = (params) => {
        document.getElementById("myModalLabel").innerHTML = params.data.YIL + " Detay"
        document.getElementById("BTNmdl").click();
        //var yil = params.data.YIL;
        var seciliay = document.getElementById("seciliay").value;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData2: data });
        };
        fetch(window.$apiurl + '/ISTAKIPCEK?yil=' + params.data.YIL + '&ay=' + seciliay, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
        //const colId = params.column.getId();
        //alert(params.data.FISREF);
        //onst selectedCountry = params.data.country;
        //const selectedCity = params.data.city;
        //const allowedCities = countyToCityMap(selectedCountry);
        //const cityMismatch = allowedCities.indexOf(selectedCity) < 0;

    };
    componentDidMount() {
        // this.vericek();
    }

    vericek() {
        if (Cookies.get("durum") != "DEMİR") {
            return;
        }
        fetch(window.$apiurl + '/AYLIKGELIRCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    items: json
                })
            })
    }
    verideneme(yil) {
        document.getElementById("LBLyil").innerHTML = yil;
        this.isdetay();
    }
    isdetay() {
        var yil = document.getElementById("LBLyil").innerHTML;
        var seciliay = document.getElementById("seciliay").value;
        fetch(window.$apiurl + '/ISTAKIPCEK?yil=' + yil + '&ay=' + seciliay, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    itemsdetay: json
                })
            })
        //alert(0);
    }

    render() {
        var { items, itemsdetay } = this.state
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">
                        <div id="LBLyil" style={{ display: "none" }}></div>
                        <input id="BTNmdl" style={{ display: 'none' }} type="button" value="d" data-toggle="modal" data-target=".bs-example-modal-lg" />
                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>İş Takip</h4>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default collapsed">

                            <div className="panel-body table-responsive ">
                                <div style={{ width: '100%', height: '100%' }}>
                                    <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            onGridReady={this.onGridReady}
                                            rowData={this.state.rowData}

                                        />
                                    </div>
                                </div>
                                {/* <table className="table table-bordered" style={{ border: "1px solid #ddd" }}>
                                    <thead>
                                        <tr className="renkkodu" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <th>#</th>
                                            <th>Yıllar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => (
                                            <tr style={{ fontSize: "12px" }}>
                                                <td style={{ textAlign: "center", width: "33px" }}><span class="label label-info renkkodu" data-toggle="modal" data-target=".bs-example-modal-lg" onClick={() => this.verideneme(item.YIL)}>Detay</span></td>
                                                <td>{item.YIL}</td>
                                            </tr>
                                        ))}


                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                        <h4 className="modal-title" id="myModalLabel" style={{ fontSize: "15px" }}>Detay </h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="panel-body table-responsive ">
                                            AY SEÇİNİZ = <select id="seciliay">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                                <option>6</option>
                                                <option>7</option>
                                                <option>8</option>
                                                <option>9</option>
                                                <option>10</option>
                                                <option>11</option>
                                                <option>12</option>
                                            </select>
                                            <input className="renkkodu" onClick={this.onCellClick} style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px", height: "30px", width: "60px", borderRadius: "5px", color: "white" }} type="submit" value="Ara" />

                                            <div style={{ width: '100%', height: '100%' }}>
                                                <div className="ag-theme-balham" style={{ height: 200, width: "100%" }}>
                                                    <AgGridReact
                                                        localeText={this.state.localeText}
                                                        animateRows={true}
                                                        enableRangeSelection={true}
                                                        paginationAutoPageSize={true}
                                                        columnDefs={this.state.columnDefsdetay}
                                                        defaultColDef={this.state.defaultColDef}
                                                        //onGridReady={this.onCellClick}
                                                        rowData={this.state.rowData2}
                                                    //onCellDoubleClicked={this.onCellClick}    
                                                    />
                                                </div>
                                            </div>
                                            {/* <table className="table table-bordered" style={{ border: "1px solid #ddd" }}>
                                                <thead>
                                                    <tr className="renkkodu" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                                                        <th>Yük Tarih</th>
                                                        <th>NetKG</th>
                                                        <th>Ort.B.Fiyat</th>
                                                        <th>Satış</th>
                                                        <th>Alış</th>
                                                        <th>Nakliye</th>
                                                        <th>Brüt Kar</th>
                                                        <th>Net Kar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {itemsdetay.map(itemm => (
                                                        <tr style={{ fontSize: "12px" }}>
                                                            <td>{itemm.YUKTARIH}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.NETKG}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.ORTBFIYAT}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.SATIS}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.ALIS}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.NAKLIYE}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.BRUTKAR}</td>
                                                            <td style={{ textAlign: "right" }}>{itemm.NETKAR}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table> */}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" style={{ border: "0px" }} className="btn btn-primary renkkodu" data-dismiss="modal">Kapat</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>

            </div>
        )
    }
}

export default istakip
