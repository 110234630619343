import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import ReactToExcel from 'react-html-table-to-excel'
import Cookies from 'js-cookie'
import '../Sayfalar/renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class CSevkiyatlar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            flag: Cookies.get("Flag"),
            columnDefs: [
                { field: 'YUKREF', onCellClicked: this.onCellClick, rowStyle: { background: 'black' }, headerName: 'OrdinoNo', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'YUKTARIH', headerName: 'Tarih', sortable: true, floatingFilter: true, filter: true, },
                { field: 'PLAKA', cellStyle: { fontWeight: 'bold' }, headerName: 'Plaka', sortable: true, floatingFilter: true, filter: true, },
                { field: 'SOFOR', minWidth: 120, headerName: 'Şöför', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
                { field: 'TCNO', headerName: 'TC', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
                //{ field: 'TONAJ', sortable: true,cellStyle:{background:'rgb(57, 141, 19)',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'SEVKYERI', maxWidth: '80', headerName: 'SevkYeri', sortable: true, },
                { field: 'TUTAR', headerName: 'Tutar', sortable: true, type: 'rightAligned', },
                { field: 'LOGOFISNO', headerName: 'İrsaliyeNo', sortable: true },
                { field: 'LOGOFATNO', headerName: 'FaturaNo', sortable: true },
                { field: 'DURUM', maxWidth: '75', headerName: 'Durum', sortable: true }
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            rowClassRules: {
                'sick-days-breach': function (params) {
                    var numSickDays = params.data.DURUM;
                    return numSickDays == 'B';
                },
                // 'sick-days-breach': 'data.DURUM != Bekleyen',
            },

            defaultColDef: { flex: 1, },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        { field: 'ADI', headerName: 'Ürün Adı', minWidth: 150 },
                        { field: 'NKG', headerName: 'NetKg', type: 'rightAligned' },
                        { field: 'MFIYAT', headerName: 'BirimFiyat', type: 'rightAligned' },
                        { field: 'YBAGMIK', headerName: 'BagMiktari', type: 'rightAligned' },
                        { field: 'YBAGNO', headerName: 'Bağlantı No', type: 'rightAligned' },
                        /*{ field: 'SUMMIKTAR',type:'rightAligned'},
                        { field: 'SUMHMIKTAR',type:'rightAligned'},
                        { field: 'SUMHTUTAR',type:'rightAligned'},*/


                    ],
                    defaultColDef: { flex: 1 },
                },
                getDetailRowData: function (params) {
                    //alert(params.data.BAGNO);
                    setTimeout(function () {
                        fetch(window.$apiurl + '/CSEVKIYATDETAYCEK?yukref=' + params.data.YUKREF, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                            .then(res => res.json())
                            .then(dataT => params.successCallback(dataT));

                    }, 1000);
                },
            },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }
        //this.vericek = this.vericek.bind(this);
    }
    onGridReady = (params) => {
        document.getElementById("GRDdeneme").style.display = "";
        var flag = Cookies.get("Flag");
        var cariid = Cookies.get("CariID");
        var bastarih = document.getElementById("BASTARIH").value;
        var bittarih = document.getElementById("BITTARIH").value;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/CSEVKIYATCEK?durum=' + sessionStorage.getItem("zdurum") + '&cariID=' + cariid + '&bastarih=' + bastarih + '&bittarih=' + bittarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };

    onCellClick = (params) => {
            //window.open('https://mehmetalidc.online/YuklemeOrdino/'+params.data.YUKREF, '_blank');        
    };

    componentDidMount() {
        // alert(sessionStorage.getItem("zdurum"));
        this.tarihcek();
        document.getElementById("GRDdeneme").style.display = "none";
        // this.vericek();
    }
    tarihcek() {
        fetch(window.$apiurl + '/TARIHCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                document.getElementById("BASTARIH").value = json[0]["TARIHG"];
                document.getElementById("BITTARIH").value = json[0]["TARIHB"];
            })
    }

    render() {
        var { items } = this.state;
        return (
            <div>
                <div>
                    <div>
                        <div>
                            <Header />
                            <Rightsidebar />
                            <Leftsidebar />
                            <section class="main-content container">
                                {/*page header start*/}
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h4>Sevkiyatlar</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default collapsed">

                                    <div className="panel-body table-responsive ">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div style={{ color: 'black' }} className="alert alert-danger" role="alert">
                                                    - Ordino numarasının yanındaki Ok'a basarak bağlantı detaylarını görebilirsiniz.<br />
                                                    - Başlıkların Altındaki gri alana basarak arama yapabilirsiniz.<br />
                                                </div>
                                            </div>
                                        </div>
                                        <input id="BASTARIH" type="date" style={{ marginRight: "15px", marginBottom: "10px", border: "1px solid" }} /><input id="BITTARIH" style={{ border: "1px solid" }} type="date" /><input className="renkkodu" onClick={this.onGridReady} style={{ marginLeft: "10px", marginRight: "10px", height: "30px", width: "60px", borderRadius: "5px", color: "white" }} type="submit" value="Ara" />
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 1200, width: "100%" }}>
                                                <AgGridReact
                                                    localeText={this.state.localeText}
                                                    animateRows={true}
                                                    enableRangeSelection={true}
                                                    paginationAutoPageSize={true}
                                                    columnDefs={this.state.columnDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    masterDetail={true}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    //onGridReady={this.onGridReady}
                                                    rowClassRules={this.state.rowClassRules}
                                                    rowData={this.state.rowData}

                                                />
                                            </div>
                                        </div>
                                        {/* <ReactToExcel table="EXtable" filename="SevkiyatExcel" sheet="sheet" buttonText="Yazdır" className="btnclas" /><br />

                                            <table className="table table-bordered" id="EXtable" style={{ border: "1px solid #ddd" }}>
                                                <thead>
                                                    <tr className="renkkodu" style={{   fontSize: "12px", fontFamily: "sans-serif" }}>
                                                        <th style={{ width: "90px" }}>Yük Tarih</th>
                                                        <th style={{ width: "80px" }}>Fiş NO</th>
                                                        <th style={{ width: "90px" }}>Plaka</th>
                                                        <th style={{ width: "40px" }}>YT</th>
                                                        <th style={{ width: "80px" }}>Birim Fiyat</th>
                                                        <th style={{ width: "180px" }}>Ürün Adı</th>
                                                        <th style={{ width: "80px" }}>Tonaj(KG)</th>
                                                        <th style={{ width: "80px" }}>Tutar(TL)</th>

                                                        <th style={{ width: "60px" }}>Durum</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items.map(item => (
                                                        <tr style={{ fontSize: "12px", backgroundColor: item.BGCOLOR }}>
                                                            <td>{item.YUKTARIH}</td>
                                                            <td>{item.FISNO}</td>
                                                            <td>{item.PLAKA}</td>
                                                            <td>{item.YTURU}</td>
                                                            <td>{item.BIRIMFIYAT}</td>
                                                            <td>{item.URUNADI}</td>
                                                            <td style={{ textAlign: "right" }}>{item.TONAJ}</td>
                                                            <td style={{ textAlign: "right" }}>{item.TUTAR}</td>

                                                            <td>{item.YDURUMU}</td>
                                                        </tr>
                                                    ))}


                                                </tbody>
                                            </table> */}
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                </div>
            </div>
        )

    }
}

export default CSevkiyatlar
