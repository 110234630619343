import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'

export class SatisYuklemeleri extends Component {
    constructor(props) {
    super(props);
    this.state = {
        columnDefs: [
            { field: 'YUKREF', onCellClicked : this.onCellClick, rowStyle: { background: 'black' }, headerName: 'OrdinoNo', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
            { field: 'YUKTARIH', headerName: 'Tarih', sortable: true, floatingFilter: true, filter: true, },
            { field: 'PLAKA', cellStyle: { fontWeight: 'bold' }, headerName: 'Plaka', sortable: true, floatingFilter: true, filter: true, },
            { field: 'SOFOR', minWidth: 120, headerName: 'Şöför', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
            { field: 'TCNO', headerName: 'TC', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
            { field: 'FIRMA', headerName: 'Firma Adı', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, },
            { field: 'TUTAR', headerName: 'Tutar', sortable: true, type: 'rightAligned', },
            { field: 'DURUM', maxWidth: '55', headerName: 'AK', sortable: true },
            { field: 'YUKDURUM', maxWidth: '85', headerName: 'FDurum', sortable: true },
        ],
        rowClassRules: {
            'sick-days-warning': function (params) {
                var numSickDays = params.data.YUKDURUM;
                return numSickDays == 'IPTAL';
            },
            'sick-days-breach': function (params) {
                var numSickDays = params.data.DURUM;
                return numSickDays == 'B';
            },
        },
        detailCellRendererParams: {
            detailGridOptions: {
                columnDefs: [
                    { field: 'ADI', headerName: 'Ürün Adı', minWidth: 150 },
                    { field: 'FKG', headerName: 'NazariKg', type: 'rightAligned' },
                    { field: 'NKG', headerName: 'NetKg', type: 'rightAligned' },
                    { field: 'MFIYAT', headerName: 'BirimFiyat', type: 'rightAligned' },
                    { field: 'YBAGMIK', headerName: 'BagMiktari', type: 'rightAligned' },
                ],
                defaultColDef: { flex: 1 },
            },
            getDetailRowData: function (params) {
                setTimeout(function () {
                    fetch(window.$apiurl + '/CSEVKIYATDETAYCEK?yukref=' + params.data.YUKREF, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                        .then(res => res.json())
                        .then(dataT => params.successCallback(dataT));

                }, 1000);
            },
        },
        defaultColDef: { flex: 1, },
        localeText: {
            searchOoo: 'Arama Yapabilirsiniz...',
            noMatches: 'Kayıt Bulunamadı!.',
        },
        rowData: null,
        rowdetay: null
    }
    }

    onCellClick = (params) => {
        window.open('https://mehmetalidc.online/YuklemeOrdino/'+params.data.YUKREF, '_blank');        
    };

    onGridReady = (params) => {     
        var bastarih = document.getElementById("BASTARIH").value;
        var bittarih = document.getElementById("BITTARIH").value;
        document.getElementById("GRDdeneme").style.display = "";
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/YUKMUSTERICEK?bastarih='+bastarih + '&bittarih='+bittarih, {headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    componentDidMount() {
        var flag = Cookies.get("Flag");
        if(flag != "1"){
            window.location.href = "/Login"
        }
        this.tarihcek();
        document.getElementById("GRDdeneme").style.display = "none";
    }
    tarihcek(){
        fetch(window.$apiurl+'/TARIHCEK',{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
        .then(res => res.json())
        .then(json => {
            document.getElementById("BASTARIH").value = json[0]["TARIHG"];
            document.getElementById("BITTARIH").value = json[0]["TARIHB"];
        })
    }
    render() {
        return (
            <div>
                <Header />
                <Rightsidebar />
                <Leftsidebar />
                <section class="main-content container">
                    {/*page header start*/}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Satış Yüklemeleri</h4>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default collapsed">
                        <div className="panel-body table-responsive ">
                            <input id="BASTARIH" type="date" style={{ marginRight: "15px", marginBottom: "10px", border: "1px solid" }} /><input id="BITTARIH" style={{ border: "1px solid" }} type="date" /><input className="renkkodu" onClick={this.onGridReady} style={{ marginLeft: "10px", marginRight: "10px", height: "30px", width: "60px", borderRadius: "5px", color: "white" }} type="submit" value="Ara" />
                            <div style={{ width: '100%', height: '100%' }}>
                                    <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            paginationAutoPageSize={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            masterDetail={true}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            //onGridReady={this.onGridReady}
                                            rowData={this.state.rowData} 
                                            rowClassRules={this.state.rowClassRules}
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default SatisYuklemeleri