import React, { Component } from 'react'

export class Hata extends Component {
    render() {
        return (
            <div>
               <h1 style={{textAlign:"center"}}> Aradığınız Sayfa bulunamadı</h1><br/>
              <div style={{textAlign:"center",color:"green"}}> <a href="/Login"><h2>Geri Dön</h2></a></div> 
            </div>
        )
    }
}

export default Hata
