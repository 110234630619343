import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Anasayfa from './Sayfalar/Anasayfa';
import Alisbaglantilari from './Sayfalar/Alisbaglantilari';
import Satisbaglantilari from './Sayfalar/Satisbaglantilari';
import Bekleyensiparisler from './Sayfalar/Bekleyensiparisler';
import TamamlananSiparisler from './Sayfalar/TamamlananSiparisler';
import CariBilgileri from './Sayfalar/CariBilgileri';
import CariEkstre from './Sayfalar/CariEkstre';
import deneme from './Sayfalar/deneme';
import PlasiyerBilgileri from './Sayfalar/PlasiyerBilgileri';
import Sevkiyatlar from './Sayfalar/Sevkiyatlar';
import Yukleme from './Sayfalar/Yukleme';
import Satis from './Sayfalar/Satis';
import Depo from './Sayfalar/Depo';
import Uyelog from './Sayfalar/Uyelog';
import Login from './Sayfalar/Login';
import Aylikgelir from './Sayfalar/Aylikgelir';
import istakip from './Sayfalar/istakip';
import Hesap from './Sayfalar/Hesap';
import Uyeislemleri from './Sayfalar/Uyeislemleri';
import Hata from './Sayfalar/Hata';
import Stok from './Sayfalar/Stok';
import CariAnasayfa from './Sayfalar/CariAnasayfa';
import Calisbaglantilari from './CariSayfalar/Calisbaglantilari';
import CariHaraketleri from './Sayfalar/CariHaraketleri';
import asd from './Sayfalar/asd';
import FDetay from './Sayfalar/FDetay';
import bagform from './Sayfalar/bagform';
import sipform from './Sayfalar/sipform';
import SDetay from './Sayfalar/SDetay';
import TeklifForm from './Sayfalar/TeklifForm';
import TeklifDetay from './Sayfalar/TeklifDetay';
import bahittin from './Sayfalar/bahittin';
import ordino from './Sayfalar/ordino';
import ODetay from './Sayfalar/ODetay';
import cBekleyenbaglantilar from './CariSayfalar/cBekleyenbaglantilar';
import cKapananbaglantilar from './CariSayfalar/cKapananbaglantilar';
import CSevkiyatlar from './CariSayfalar/CSevkiyatlar';
import cOrdino from './CariSayfalar/cOrdino';
import cBankaBilgileri from './CariSayfalar/cBankaBilgileri';
import AlisYuklemeleri from './Sayfalar/AlisYuklemeleri';
import SatisYuklemeleri from './Sayfalar/SatisYuklemeleri';
import BaglantiForm from './Formlar/BaglantiForm'
import Bform from './Formlar/Bform'
import BaglantiFormWeb from './Formlar/BaglantiFormWeb'
import Bformw from './Formlar/Bformw'
import SiparisForm from './Formlar/SiparisForm';
import Sform from './Formlar/Sform';
import YuklemeOrdino from './Formlar/YuklemeOrdino';
import YOrdino from './Formlar/YOrdino';
import Tform from './Formlar/Tform';
import TeklifY from './Formlar/TeklifY';
import FabrikaStok from './Sayfalar/FabrikaStok';
import Borc from './Sayfalar/Borc';
import gAStok from './Sayfalar/gAStok';
import SatTonaj from './Sayfalar/SatTonaj';
import BAlis from './Sayfalar/BAlis';
import TAlis from './Sayfalar/TAlis';
import BSatis from './Sayfalar/BSatis';
import TSatis from './Sayfalar/TSatis';
import gATonaji from './Sayfalar/gATonaji';
import gSTonaji from './Sayfalar/gSTonaji';
import gYTonaji from './Sayfalar/gYTonaji';
import YuklemeOrdinoMail from './Formlar/YuklemeOrdinoMail';
import YOrdinoMail from './Formlar/YOrdinoMail'
import SevkiyatRapor from './Formlar/SevkiyatRapor';
import SevkiyatForm from './Formlar/SevkiyatForm';
import YuklemeForm from './Formlar/YuklemeForm';
import Yuklemerapor from './Formlar/YuklemeRapor';
const rootElement = document.getElementById("root");
//window.$apiurl="https://mentalsoft.net:51/home";


window.$apiurl="https://mentalsoft.net:98/api/zmsrct";
window.$apiurltokensiz = "https://mentalsoft.net:98/api/tokensizrct";

/* window.$apiurl="http://localhost:5001/api/zmsrct";
window.$apiurltokensiz = "http://localhost:5001/api/tokensizrct"; */

window.$renkkodu = "#F21D27";
//window.$apiurl ="http://mentalsoft.net:52/home"
//window.$apiurl = "http://localhost:50850/home";
ReactDOM.render(
<BrowserRouter>
   
  <Route exact path="/" component={App} />
  <Route path="/Anasayfa" component={Anasayfa}/>
  <Route path="/Alisbaglantilari" component={Alisbaglantilari}/>
  <Route path="/Satisbaglantilari" component={Satisbaglantilari}/>
  <Route path="/Bekleyensiparisler" component={Bekleyensiparisler}/>
  <Route path="/TamamlananSiparisler" component={TamamlananSiparisler}/>
  <Route path="/CariBilgileri" component={CariBilgileri}/>
  <Route path="/CariEkstre" component={CariEkstre}/>
  <Route path="/deneme" component={deneme}/>
  <Route path="/PlasiyerBilgileri" component={PlasiyerBilgileri}/>
  <Route path="/Sevkiyatlar" component={Sevkiyatlar}/>
  <Route path="/Yukleme" component={Yukleme}/>
  <Route path="/Satis" component={Satis}/>
  <Route path="/Depo" component={Depo}/>
  <Route path="/Uyelog" component={Uyelog}/>
  <Route path="/Login" component={Login}/>
  <Route path="/Aylikgelir" component={Aylikgelir}/>
  <Route path="/istakip" component={istakip}/>
  <Route path="/Hesap" component={Hesap}/>
  <Route path="/Uyeislemleri" component={Uyeislemleri}/>
  <Route path="/Stok" component={Stok}/>
  <Route path="/CariAnasayfa" component={CariAnasayfa}/>
  <Route path="/Calisbaglantilari" component={Calisbaglantilari}/>
  <Route path="/CariHareketleri" component={CariHaraketleri}/>
  <Route path="/asd" component={asd}/>
  <Route path="/FDetay" component={FDetay}/>
  <Route path="/bagform/:id" component={bagform}/>
  <Route path="/sipform/:id" component={sipform}/>
  <Route path="/SDetay" component={SDetay}/>
  <Route path="/TeklifForm/:id" component={TeklifForm}/>
  <Route path="/TeklifDetay" component={TeklifDetay}/>
  <Route path="/bahittin" component={bahittin}/>
  <Route path="/ordino/:id" component={ordino}/>
  <Route path="/ODetay" component={ODetay}/>
  <Route path="/cBekleyenbaglantilar" component={cBekleyenbaglantilar}/>
  <Route path="/cKapananbaglantilar" component={cKapananbaglantilar}/>
  <Route path="/CSevkiyatlar" component={CSevkiyatlar}/>
  <Route path="/cOrdino" component={cOrdino}/>
  <Route path="/cBankaBilgileri" component={cBankaBilgileri}/>
  <Route path="/AlisYuklemeleri" component={AlisYuklemeleri}/>
  <Route path="/SatisYuklemeleri" component={SatisYuklemeleri}/>
  <Route path="/BaglantiForm/:id" component={BaglantiForm}/>
  <Route path="/Bform" component={Bform}/>
  <Route path="/BaglantiFormWeb/:id" component={BaglantiFormWeb}/>
  <Route path="/Bformw" component={Bformw}/>
  <Route path="/SiparisForm/:id" component={SiparisForm}/>
  <Route path="/Sform" component={Sform}/>
  <Route path="/YuklemeOrdino/:yukref" component={YuklemeOrdino}/>
  <Route path="/YOrdino" component={YOrdino}/>
  <Route path="/Tform" component={Tform}/>
  <Route path="/TeklifY/:id" component={TeklifY}/>
  <Route path="/FabrikaStok" component={FabrikaStok}/>
  <Route path="/Borc" component={Borc}/>
  <Route path="/gAStok" component={gAStok}/>
  <Route path="/SatTonaj" component={SatTonaj}/>
  <Route path="/BAlis" component={BAlis}/>
  <Route path="/TAlis" component={TAlis}/>
  <Route path="/BSatis" component={BSatis}/>
  <Route path="/TSatis" component={TSatis}/>
  <Route path="/gATonaji" component={gATonaji} />
  <Route path="/gSTonaji" component={gSTonaji} />
  <Route path="/gYTonaji" component={gYTonaji} />
  <Route path="/YuklemeOrdinoMail/:plaka/:yukref" component={YuklemeOrdinoMail}/>
  <Route path="/YOrdinoMail" component={YOrdinoMail}/>
  <Route path="/Yuklemerapor/:plaka/:bastarih/:bittarih/:cariID/:kapali" component={Yuklemerapor}/>
  <Route path="/Sevkiyatrapor/:plaka/:bastarih/:bittarih/:cariID/:kapali" component={SevkiyatRapor}/>
  <Route path="/YuklemeForm" component={YuklemeForm}/>
  <Route path="/SevkiyatForm" component={SevkiyatForm}/>
</BrowserRouter>,
rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
