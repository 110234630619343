import React, { Component } from 'react'

export class YuklemeOrdino extends Component {
    componentDidMount(){
        var yukref = this.props.match.params.yukref;
       // var tedid = this.props.match.params.tedid;
        sessionStorage.setItem("yukref",yukref);
       // sessionStorage.setItem("tedid",tedid);
        window.location.href = "/YOrdino";
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default YuklemeOrdino
