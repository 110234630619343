import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import './renk.css'
export class Uyeislemleri extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isloaded: false,
            uyedetayitems: []
        }
        this.uyedetay = this.uyedetay.bind(this);
        this.vericek = this.vericek.bind(this);
        this.guncelle = this.guncelle.bind(this);
    }
    
    componentDidMount() {
        var flag = Cookies.get("Flag");
        if(flag != "1"){
            window.location.href = "/Login"
        }
        this.vericek();
        document.getElementById("load").style.display = "";
    }
    uyedetay(gelen) {
    
        fetch(window.$apiurl + '/UYELERCEK?refno=' + gelen,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
            .then(res => res.json())
            .then(json => {
                document.getElementById("TXTcariadi").value = json[0]["CARIADI"];
                document.getElementById("TXTmesaj").value = json[0]["MESAJ"];
                document.getElementById("TXTusername").value = json[0]["USERNAME"];
                document.getElementById("TXTuserpass").value = json[0]["USERPASS"];
                document.getElementById("refno").innerHTML = json[0]["REFNO"];
            })
    }
    vericek() {
        fetch(window.$apiurl + '/UYELERCEK?refno=',{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
            .then(res => res.json())
            .then(json => {
                //console.log(json);
                this.setState({
                    items: json,
                    isloaded: true
                })
                document.getElementById("load").style.display = "none";
            });
           
    }
    guncelle() {
        var cariadi = document.getElementById("TXTcariadi").value;
        var mesaj = document.getElementById("TXTmesaj").value;
        var username = document.getElementById("TXTusername").value;
        var userpass = document.getElementById("TXTuserpass").value;
        var refno = document.getElementById("refno").innerHTML;
        fetch(window.$apiurl + '/UYEGUNCELLE?refno=' + refno+'&cariadi='+cariadi+'&username='+username+'&userpass='+userpass+'&mesaj='+mesaj,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
        .then(res => res.json())
            .then(json => {
                //console.log(json);
            });
            alert("güncelleme başarılı");
            this.vericek();
    }
    render() {
        var { items,isloaded } = this.state
        
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">
                        <div id="load" style={{display:"none"}} className="loading"></div>
                        <div id="refno" style={{display:"none"}}></div>
                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Üye İşlemleri</h4>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default collapsed">

                            <div className="panel-body table-responsive ">
                                <table className="table table-bordered" style={{ border: "1px solid #ddd" }}>
                                    <thead>
                                        <tr className="renkkodu" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <th>#</th>
                                            <th style={{ width: "80px" }}>Referans No</th>
                                            <th style={{ width: "230px" }}>Cari Adı</th>
                                            <th style={{ width: "100px" }}>User Name</th>
                                            <th style={{ width: "100px" }}>User Pass</th>
                                            <th style={{ width: "180px" }}>Mesaj</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => (
                                            <tr style={{ fontSize: "12px" }}>
                                                <td style={{ textAlign: "center", fontSize: "14px", width: "33px" }}><span class="label label-info renkkodu"  data-toggle="modal" data-target="#signupModal" onClick={() => this.uyedetay(item.REFNO)}>Güncelle</span></td>
                                                <td>{item.REFNO}</td>
                                                <td>{item.CARIADI}</td>
                                                <td>{item.USERNAME}</td>
                                                <td>{item.USERPASS}</td>
                                                <td>{item.MESAJ}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal fade" id="signupModal" tabIndex={-1} role="dialog" aria-labelledby="signupModal">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                        <h3 className="modal-title" id="myModalLabel">Üye Güncelleme</h3>
                                    </div>
                                    <div className="modal-body">
                                        <div className="modal-form">
                                            <form role="form">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <input type="text" data-toggle="tooltip" id="TXTcariadi" data-placement="top" title="Cari Adı" className="form-control" placeholder="Cari Adı" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <input type="text" data-toggle="tooltip" id="TXTmesaj" data-placement="top" title="Mesaj" className="form-control" placeholder="Mesaj" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <input type="text" data-toggle="tooltip" id="TXTusername" data-placement="top" title="User Name" className="form-control" placeholder="User Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <input type="text" data-toggle="tooltip" id="TXTuserpass" data-placement="top" title="User Pass" className="form-control" placeholder="User Pass" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix">
                                                    <button type="button" style={{   border: "0px" }} className="btn btn-primary renkkodu">Sil</button> <button type="button" onClick={this.guncelle} style={{   border: "0px" }} className="btn btn-primary pull-right renkkodu">Güncelle</button>
                                                </div>
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <p>Mentalsoft</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </section>
                </div>

            </div>
        )
    }
}

export default Uyeislemleri
