import React, { Component } from 'react'
import './login.css'
import Cookies from 'js-cookie'
import Axios from 'axios'
export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipadresi:''
    }
    this.vericek = this.vericek.bind();
  }

  vericek() {
    var Vadi = "";
    var adi = document.getElementById("TXTkadi").value;
    var sifre = document.getElementById("TXTsifre").value;
    var data = { "username": document.getElementById("TXTkadi").value, "password": document.getElementById("TXTsifre").value };
    Axios.post("https://mentalsoft.net:98/api/auth/login", data).then(res => {
      Cookies.set('giris', 1, { expires: 1 });
      Cookies.set('Adi', res.data.ADI, { expires: 1 });
      Cookies.set('CariID', res.data.CARIID, { expires: 1 });
      Cookies.set('Flag', res.data.FLAG, { expires: 1 });
      Cookies.set('durum', 'DEMİR', { expires: 1 });
      Cookies.set('Token', res.data.Token, { expires: 0.02 });
      Cookies.set('CariAdi', res.data.CARIADI, { expires: 1 });
      Cookies.set('CBAGREF', res.data.CBAGREF, { expires: 1 });

      
      //alert(sessionStorage.getItem('IPY'));
      fetch(window.$apiurl + "/LOGKAYDET?username=" + document.getElementById("TXTkadi").value + "&cariid=" + res.data.CARIID + "&ipadres=" + sessionStorage.getItem('IPY'), { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
      .then(res => res.json())
      .then(json => {
        //alert("sa")
      })//.catch(er => alert(er)) 

      //console.log(res.data.CARIADI);
      if (res.data.CARIID == "0" && res.data.FLAG == "1") {
        window.location.href = "/Anasayfa";
      }
      else {
        window.location.href = "/CariAnasayfa";
      }
      // console.log(res.data.ADI);
      // console.log(res.data[0]["ISIM"]);
      //document.getElementById("Token").innerHTML = res.data.Token;
      /*const authAxios = Axios.create({
          baseURL:'https://mentalsoft.net:92/api/yedek',
          headers:{
              Authorization:'Bearer '+res.data.Token
          }
      })
      authAxios.get('https://mentalsoft.net:92/api/yedek').then(res2 => {
          console.log(res2.data);
      });*/
    }).catch(er => {
      alert("Kullanıcı Adı veya Şifre Hatalı !" + er);
    })
    /*fetch(window.$apiurl + '/login?ad=' + adi + '&sifre=' + sifre)
      .then(res => res.json())
      .then(json => {
        if (json[0]["SONUC"] != "") {
          alert(json[0]["SONUC"]);
        }
        else {
          Cookies.set('giris',1,{expires:1});
          Cookies.set('Adi',json[0]["ADI"],{expires:1});
          Cookies.set('CariID',json[0]["CARIID"],{expires:1});
          Cookies.set('Flag',json[0]["FLAG"],{expires:1});
          Cookies.set('durum','DEMİR',{expires:1});
          if(json[0]["CARIID"] == "0" && json[0]["FLAG"] == "1"){
            window.location.href = "/Anasayfa";
          }
         else{
            window.location.href = "/CariAnasayfa";
          }
        }
      })*/

  }

  async logkaydet(){
    var ipadres = "";
    var sehir = "";
   await Axios.get('https://geolocation-db.com/json/').then(res => {
     sessionStorage.setItem('IPY',res.data.IPv4);
      //alert(this.state.ipadresi)
     // alert(ipadres);
      //alert(sehir);
    });
  }
  componentDidMount(){
    this.logkaydet();
  }
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.vericek();
    }
  }

  render() {
    return (
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <div className="login100-pic js-tilt" data-tilt>
                <img src="images/image003.jpg" alt="IMG" />
              </div>
              <span style={{ color: 'white' }}>
                s
              </span>
              <div className="wrap-input100 validate-input" data-validate="Lütfen e-mail adresinizi giriniz.">
                <input id="TXTkadi" onKeyPress={this.handleKeyPress} className="input100" type="text" name="email" placeholder="Kullanıcı Adını Giriniz" />
                <span className="focus-input100" />
                <span className="symbol-input100">
                  <i className="fa fa-envelope" style={{ color: '#e22727' }} aria-hidden="true" />
                </span>
              </div>
              <div className="wrap-input100 validate-input" data-validate="Lütfen şifrenizi giriniz.">
                <input id="TXTsifre" onKeyPress={this.handleKeyPress} className="input100" type="password" name="pass" placeholder="Şifreniz" />
                <span className="focus-input100" />
                <span className="symbol-input100">
                  <i className="fa fa-lock" style={{ color: '#e22727' }} aria-hidden="true" />
                </span>
              </div>
              <div className="container-login100-form-btn">
                <input type="button" onClick={this.vericek} value="GİRİŞ YAP" className="login100-form-btn">

                </input>
              </div>
            </form>
          </div>
        </div>
      </div>


    )
  }
}

export default Login
