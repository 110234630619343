import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import './Sayfa.css'
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class Depo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemsfir: [],
            itemshar: [],
            columnDefs: [
                { field: 'STARIH', headerName: 'S.Tarihi', sortable: true, floatingFilter: true, filter: true, },
                { field: 'FISTURU', headerName: 'FişTürü', sortable: true, floatingFilter: true, filter: true, },
                { field: 'FIRMAADI', headerName: 'FirmaAdi', sortable: true, floatingFilter: true, filter: true, type: 'rightAligned', },
                { field: 'FISNO', headerName: 'FisNo', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
                { field: 'PLAKA', headerName: 'Plaka', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
                { field: 'URUNADI', headerName: 'UrunAdi', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
                { field: 'NKG', headerName: 'NKG', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
                { field: 'TFIYAT', headerName: 'TFiyati', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
                { field: 'MASRAF', headerName: 'Masraf', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
                { field: 'BAGMIK', headerName: 'BagMik', filter: 'agSetColumnFilter', sortable: true, floatingFilter: true, type: 'rightAligned', },
            ],
            defaultColDef: { flex: 1, },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,

        }
        this.vericek = this.vericek.bind(this);
    }
    onGridReady = (params) => {
        var tarih = document.getElementById("TARIH").value;
        document.getElementById("GRDdeneme").style.display = "";
        document.getElementById("gizle2").style.display = "";
        document.getElementById("gizle").style.display = "none";
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/DEPOHAREKETCEK?tarih=' + tarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    componentDidMount() {
        //document.getElementById("TARIH").value =yyyy+"-"+mm+"-"+dd;
        this.tarihcek();
        document.getElementById("gizle").style.display = "none";
        document.getElementById("gizle2").style.display = "none";
        document.getElementById("load").style.display = "none";

    }
    tarihcek() {
        fetch(window.$apiurl + '/TARIHCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                //document.getElementById("BASTARIH").value = json[0]["TARIHG"];
                document.getElementById("TARIH").value = json[0]["TARIHB"];
            })
    }
    vericek(gelen) {
        if (Cookies.get("durum") != "DEMİR") {
            return;
        }
        document.getElementById("load").style.display = "";
        if (gelen == 0) {
            fetch(window.$apiurl + '/DEPOCEK?gelen=C.BGR=0', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                .then(res => res.json())
                .then(json => {
                    this.setState({
                        items: json
                    })
                })
            fetch(window.$apiurl + '/DEPOCEK?gelen=C.BGR>0', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                .then(res => res.json())
                .then(json => {
                    this.setState({
                        itemsfir: json,

                    })
                    document.getElementById("load").style.display = "none"
                })
            document.getElementById("gizle").style.display = "";
            document.getElementById("gizle2").style.display = "none";
        }
        else {
            var tarih = document.getElementById("TARIH").value;
            document.getElementById("gizle").style.display = "none";
            document.getElementById("gizle2").style.display = "";
            document.getElementById("load").style.display = "none"
            fetch(window.$apiurl + '/DEPOHAREKETCEK?tarih=' + tarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                .then(res => res.json())
                .then(json => {
                    this.setState({
                        itemshar: json
                    })
                })
        }
    }
    render() {
        var { items, itemsfir, itemshar } = this.state



        return (
            <div>
                <Header />
                <Rightsidebar />
                <Leftsidebar />
                <section class="main-content container">
                    <div id="load" ><div className="loading"></div></div>
                    {/*page header start*/}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Depo</h4>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default collapsed">
                        <div className="panel-body table-responsive ">
                            <div style={{ textAlign: "center", marginBottom: "10px" }}><span className="btn btn-primary renkkodu" onClick={() => this.vericek(0)} style={{ border: "0px", marginRight: "10px" }}>Stok Bilgisi</span><span className="btn btn-primary renkkodu" onClick={this.onGridReady} style={{ border: "0px" }}>Hareketler</span><br /></div>
                            <div id="gizle">
                                <table className="table table-bordered" id="EXtable" style={{ border: "1px solid #ddd", marginBottom: "0px", paddingBottom: "0px" }}>
                                    <thead>
                                        <tr><td colspan="7" className="renkkodu" style={{ textAlign: "center", color: "white", fontSize: "15px", fontFamily: "sans-serif" }}>Stok Bilgisi</td></tr>
                                        <tr className="renkkodu" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <th>Türü</th>
                                            <th>Depo</th>
                                            <th>Ürün Adı</th>
                                            <th>Stok KG</th>
                                            <th>Tbag Mik</th>
                                            <th>Ort Fiyatı</th>
                                            <th>Stok Değeri</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => (
                                            <tr style={{ fontSize: "12px" }}>
                                                <td>{item.TURU}</td>
                                                <td>{item.DEPOO}</td>
                                                <td>{item.URUNADI}</td>
                                                <td>{item.STOKKG}</td>
                                                <td>{item.TBAG}</td>
                                                <td>{item.ORTFIYAT}</td>
                                                <td>{item.STOKDEGERI}</td>
                                            </tr>
                                        ))}
                                        <tr className="renkkodu" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>00,00 KG</td>
                                            <td>00,00 BAG</td>
                                            <td>0,0000</td>
                                            <td>00,00 TL</td>
                                        </tr>
                                        <tr>
                                            <td className="renkkodu" colspan="7" style={{ textAlign: "center", color: "white", fontSize: "15px", fontFamily: "sans-serif" }}>Firkete</td>
                                        </tr>
                                        {itemsfir.map(itemm => (
                                            <tr style={{ fontSize: "12px" }}>
                                                <td>{itemm.TURU}</td>
                                                <td>{itemm.DEPOO}</td>
                                                <td>{itemm.URUNADI}</td>
                                                <td>{itemm.STOKKG}</td>
                                                <td>{itemm.TBAG}</td>
                                                <td>{itemm.ORTFIYAT}</td>
                                                <td>{itemm.STOKDEGERI}</td>
                                            </tr>
                                        ))}

                                        <tr className="renkkodu" style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>00,00 KG</td>
                                            <td>00,00 BAG</td>
                                            <td>0,0000</td>
                                            <td>00,00 TL</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div id="gizle2">
                                <input id="TARIH" style={{ marginBottom: "10px", marginRight: "10px", border: "1px solid" }} type="date" /><input className="renkkodu" onClick={this.onGridReady} style={{ marginLeft: "10px", marginRight: "10px", height: "30px", width: "60px", borderRadius: "5px", color: "white" }} type="submit" value="Ara" />
                                <div style={{ width: '100%', height: '100%' }}>
                                    <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 500, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Depo
