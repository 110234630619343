import React, { Component } from 'react'
import './Form2.css'
import logo from './image003.jpg'
import QRcode from 'react-qr-code'
import Cookies from 'js-cookie'
export class TeklifDetay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            link: "https://mehmetalidc.online/teklifform/" + sessionStorage.getItem("teklifno")
        }
        this.yazdir = this.yazdir.bind(this);
    }
    componentDidMount() {
        if (sessionStorage.getItem("teklifno") == null) {
            window.location.href = "/login";
            return;
        }
        this.vericek();
    }
    yazdir(){
        document.getElementById("BTNyazdir").style.display = "none";
        window.print();
        document.getElementById("BTNyazdir").style.display = "";
    }
    vericek() {
        var tref = sessionStorage.getItem("teklifno");
        fetch(window.$apiurltokensiz + "/TEKLIFCEK?tref=" + tref)
            .then(res => res.json())
            .then(json => {
                this.setState({ items: json });
                document.getElementById("LBLbagtarih").innerHTML = json[0]["STARIHI"];
                document.getElementById("LBLcariadi").innerHTML = "Sayın :" + json[0]["FIRMAADI"];
                document.getElementById("LBLvno").innerHTML = json[0]["TESADRES"];
                document.getElementById("LBLbagno").innerHTML = json[0]["BNO"];
                document.getElementById("LBLnakliye").innerHTML = json[0]["TNAKLIYESTR"];
                document.getElementById("LBLtelefon").innerHTML = json[0]["CEPTEL"];
                document.getElementById("LBLteklifnot").innerHTML = "Teklin Not Bilgisi :" + json[0]["BDETAY"];
                document.getElementById("LBLteslimyeri").innerHTML = "Teslim Yeri : " +json[0]["TESLIMYERI"];
            });
    }
    render() {
        return (
            <div id="scdeneme" style={{ backgroundColor: "gray" }}>
                <div style={{ marginLeft: "5%", marginBottom: "5px", marginTop: "5px" }}>
                    <input type="button" className="renkkodu" style={{ marginLeft: "10px", height: "30px", width: "100px",  borderRadius: "5px", color: "white" }} onClick={this.yazdir} id="BTNyazdir" value="Yazdır"></input>
                </div>
                <div id="anacizgi">
                    <div id="anacizgi2">
                        <div id="kutu">
                            <img src={logo} style={{ width: "150", height: "45px", float: "left", marginLeft: "10px", marginTop: "20px" }}></img>
                            <p className="baglanti" style={{ marginLeft: "50px", float: "left", marginTop: "10px", marginRight: "20px", fontFamily: "sans-serif" }}>TEKLİF ONAY FORMU</p>

                            <p style={{ marginTop: "10px", marginLeft: "20px", float: "right", marginRight: "10px" }}><QRcode value={this.state.link} id="qrcode" size="80" /></p>
                        </div>

                        <br />
                        <div id="orta1">
                            <p className="yazi1">
                                <br />
                                <br />
                            </p><p style={{ color: "#3c3c3c", fontSize: "13px", fontFamily: "sans-serif" }} id="LBLcariadi">C</p><br />
                            <span id="LBLcariadres" style={{ paddingLeft: "8px" }}></span><br />
                            <span id="LBLvno" style={{ paddingLeft: "8px" }}></span>
                            <p />
                        </div>
                        <table style={{ marginTop: "5px" }} className="ortayazi">
                            <tbody><tr className="cltr">
                                <td style={{ paddingLeft: "5px", width: "120px" }} className="cltr">
                                    Bağlantı No
          </td>
                                <td id="LBLbagno" style={{fontWeight:"bold", paddingLeft: "5px", width: "150px" }} className="cltr">
                                    
          </td>
                            </tr>
                                <tr className="cltr">
                                    <td style={{paddingLeft: "5px" }} className="cltr">
                                        Bağ.Tarihi
          </td>
                                    <td id="LBLbagtarih" style={{ fontWeight:"bold", paddingLeft: "5px" }} className="cltr">
                                        0
          </td>
                                </tr>
                                <tr className="cltr">
                                    <td style={{ paddingLeft: "5px" }} className="cltr">
                                        Nakliye
          </td>
                                    <td id="LBLnakliye" style={{ paddingLeft: "5px" }} className="cltr">

                                    </td>
                                </tr>
                                <tr className="cltr">
                                    <td style={{ paddingLeft: "5px" }} className="cltr">
                                        Telefon
          </td>
                                    <td style={{ paddingLeft: "5px" }} id="LBLtelefon" className="cltr">

                                    </td>
                                </tr>
                                <tr className="cltr">
                                    <td style={{ paddingLeft: "5px" }} className="cltr">
                                        Fax
          </td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody></table>
                        <br />
                        <br /><table className="tablo2" border={1}>
                            <tbody><tr className="cltr">
                                <td className="cltr" style={{fontWeight:"bold",  paddingLeft: "5px", width: "150px" }}>Ürün tanımı</td>
                                <td className="cltr" style={{ fontWeight:"bold", textAlign: "center", width: "60px" }}>Miktar KG</td>
                                <td className="cltr" style={{ fontWeight:"bold", textAlign: "center", width: "60px" }}>B.Fiyatı
          </td>
                                <td className="cltr" style={{fontWeight:"bold",  textAlign: "center", width: "120px" }}>Tutarı</td>
                                <td className="cltr" style={{fontWeight:"bold",  paddingLeft: "5px", width: "120px", textAlign: "center" }}>Ürün Detay Bilgisi</td>
                            </tr>
                                {this.state.items.map(item => (
                                    <tr className="cltr">
                                        <td className="cltr" style={{ paddingLeft: "5px" }}>{item.URUNADI}</td>
                                        <td className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}>{item.MIKTARI} KG</td>
                                        <td className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}>{item.FIYATI} {item.KURC}</td>
                                        <td className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}>{item.TOPTTUAR} {item.KURC} {item.KUR}</td>
                                        <td className="cltr" style={{ textAlign: 'center' }} id="LBLdeneme">{item.URUNDETAY}</td>
                                    </tr>
                                ))}

                                <tr className="cltr">

                                </tr>

                                <tr className="cltr">
                                    <td className="cltr" style={{ paddingLeft: "5px" }}>Fiyatlarımıza KDV Dahil Edilmiştir.</td>
                                    <td className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}></td>
                                    <td className="cltr" />
                                    <td className="cltr" style={{ textAlign: "end", paddingRight: "5px" }}></td>
                                    <td className="cltr" />
                                </tr>
                            </tbody></table>
                        {/*<p id="LBLteklifnot" style={{height:"100px",border:"1px solid black", width:"400px",marginTop:"10px"}}>sa</p>,
                        <p id="LBLteslimyeri" style={{height:"100px",border:"1px solid black",width:"300px",marginLeft:"50px",flex:"top",marginTop:"0px",paddingTop:"0px"}}>sa2</p><br/>*/}
                        <div id="orta2">
                            <p id="LBLteklifnot"  className="yazi1">
                               
                            </p><br/>
                            <p id="LBLteslimyeri" ></p>
                        </div>
                        
                        <p> Diğer Hususlar; <br />- Malzemeler TSE 708'e uygun olup, ANALİZ garantilidir.
                        <br />- Malzemelerin araçlardan indirilmesi tarafınıza aittir.
                        <br />- Ödemenin gününde yapılmaması durumunda günlük % 0,2 gecikme bedeli faizi uygulanacaktır.
                        <br />- Fabrika kantarı ile şantiyeniz kantarı arasında meydana gelebilecek  +,-% 0,3 'e kadar olan farkları normal tolerans olarak kabul edilecektir.
                        <br />- Müşteri, iş bu sipariş teklif ve kabul formunun konusunu ve yukarıda yazılı ürünleri onayladığı taktirde her türlü itiraz ve talep haklarından<br /> peşinen vazgeçmiştir.
                        <br />- İhtilaf halinde İzmir mahkemelerinin selahiyeti taraflar kabul etmektedir.
                        <br />- İş bu teklifimizin kabulü halinde firmanız kaşe ve yetkili imzası ile onaylayarak tarafımıza gönderilmesini rica ederiz.
                        <br />- İş bu sözleşme tek taraflı feshedilemez.
                        <br />- 6698 sayılı kişisel verilerin korunması kanunu çerçevesinde;kişisel verilerinizin toplanma şekilleri,işlenme amaçları,işlemenin hukuki<br /> nedenleri ve haklarınız konularında sizleri en şeffaf şekilde ürün/hizmet teklifi,pazarlama ve satış,evrak işlemleri faaliyetlerinde kullanılabilmesi,bu<br /> çerçevede firmamız tarafından kişisel verilerinizin işleneceğini bildiririz. </p>

                        <br />
                        <br />
                        <div id="alt1">
                            <p className="satici"><u style={{ marginLeft: "5px" }}>Teklif Veren Firma</u></p>
                            <p className="satici2"><u>TEKLİF ONAYLAYAN FİRMA (Kaşe&amp;İmza)</u></p>
                            <p className="satici"><u><img src={logo} style={{ height: "45px" }}></img></u></p>
                            <p className="satici2"><u></u></p>
                        </div>
                        <div id="alt2">

                            <b style={{ paddingLeft: "10px" }}>Merkez</b> &nbsp;  Cumhuriyet Mah. No:136/1 Sk. No:2 Koyundere Menemen/İZMİR<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Telefon:+90 232 364 34 44<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Faks    :+90 312 473 67 99
    </div>
                        <p id="enalt"> E-mail:info@mehmetalidc.com -  Web: www.mehmetalidc.com</p>
                    </div>
                </div>
                <div className="modal fade" id="signupModal" tabIndex={-1} role="dialog" aria-labelledby="signupModal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                <h3 className="modal-title" id="myModalLabel">Şifre Alma</h3>
                            </div>
                            <div className="modal-body">
                                <div className="modal-form">
                                    <form role="form">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input type="text" data-toggle="tooltip" id="TXTtelno" data-placement="top" title="Cari Adı" className="form-control" placeholder="Telefon No" />
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <button type="button" style={{ backgroundColor: "#9b8f90", border: "0px" }} onClick={this.SMSsifre} className="btn btn-primary">Sms Gönder</button>
                                            </div>
                                        </div>
                                    </form>
                                    <hr />
                                    <div className="text-center">
                                        <p>Mentalsoft</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeklifDetay
