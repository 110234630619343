import React, { Component } from 'react'
import Footer from '../GerekliSayfalar/Footer'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import './renk.css'
export class TamamlananSiparisler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isloaded: false,
            sipdetayitems:[]
        }
        this.sipdetay = this.sipdetay.bind(this);
        this.mailgonder = this.mailgonder.bind(this);
        this.smsgonder = this.smsgonder.bind(this);
    }

    componentDidMount() {
        this.vericek();
    }
    sipdetay(sipref) {
        document.getElementById('myModalLabel').innerHTML = sipref + ' Numaralı Fatura Detayı';
        document.getElementById('sipref').innerHTML = sipref;
        // alert(sipref);
        fetch(window.$apiurl + '/TAMSIPDETAYCEK?sipref='+sipref,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
        .then(res => res.json())
        .then(json => {
            //console.log(json);
            this.setState({
                sipdetayitems: json,
               
            })
            document.getElementById('TXTsumMik').innerHTML = json[0]['SUMMIKTAR'];
            document.getElementById('TXTsumTut').innerHTML = json[0]['SUMTUTAR'];
            document.getElementById('TXTsumYukmik').innerHTML = json[0]['SUMYUKMIK'];
        });
    }
    vericek() {
        if (Cookies.get("durum") != "DEMİR") {
            return;
        }
        var cariid = Cookies.get("CariID");
        fetch(window.$apiurl + '/TAMAMSIPCEK?cariID='+cariid,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
            .then(res => res.json())
            .then(json => {
                //console.log(json);
                this.setState({
                    items: json,
                    isloaded: true
                })
            });
    }
    mailgonder(){
        var kime = document.getElementById('TXTmail').value;
        var sipreff = document.getElementById("sipref").innerHTML;
        //alert(icerik.replace("td","sa"));
        if(kime == ""){alert("lütfen mail adresi giriniz");return;}
        fetch(window.$apiurl + "/MAILGONDER?Gkime="+kime+"&sipref="+sipreff,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
        .then(res => res.json())
        .then(json => {
            //console.log(json);
        });
        alert(kime  + " ADRESİNE MAİL GÖNDERİLMİŞTİR")
    }
    smsgonder(){
        var telno = document.getElementById('TXTtelno').value;
        var sipreff = document.getElementById("sipref").innerHTML;
        if(telno == ""){alert("lütfen telefon numarası giriniz");return;}
        fetch(window.$apiurl+'/SMSGONDER?kime='+telno+'&sipno='+sipreff,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
        .then(res => res.json())
        .then(json => {
            if(json[0]["SONUC"] != ""){
                alert(json[0]["SONUC"]);
                return;
            }else{
                alert("SMS GÖNDERİLMİŞTİR")
            }
        })
        
    }

    render() {
        var { items,sipdetayitems } = this.state
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class='main-content container'>
                    <div style={{display:"none"}} id="sipref"></div>


                        {/*page header start*/}
                        <div className='page-header'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <h4 id="deneme">Tamamlanan Siparişler</h4>
                                </div>
                            </div>
                        </div>
                        <div className='panel panel-default collapsed'>

                            <div className='panel-body table-responsive '>
                               
                                <table className='table table-bordered' style={{ border: '1px solid #ddd' }}>
                                    <thead>
                                        <tr className="renkkodu" style={{  fontSize: '12px', fontFamily: 'sans-serif' }}>
                                            <th>#</th>
                                            <th>SipNo</th>
                                            <th style={{ width: '230px' }}>Firma Adı</th>
                                            <th>S.Y</th>
                                            <th>Plasiyer</th>
                                            <th>Sipariş KG</th>
                                            <th>Sip Tarihi</th>
                                            <th style={{ textAlign: 'center' }}>Tutarı</th>
                                            <th>Kalem</th>
                                            <th>Yuk Tonaj</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => (
                                            <tr style={{ fontSize: '12px' }}>
                                                <td style={{ textAlign: 'center' ,width:'33px',cursor:"pointer"}}><span class='label label-info renkkodu'  data-toggle='modal' data-target='.bs-example-modal-lg' onClick={() => this.sipdetay(item.SIPREF)}>Detay</span></td>
                                                <td>{item.SIPREF}</td>
                                                <td>{item.FIRMAADI}</td>
                                                <td>{item.SY}</td>
                                                <td>{item.PLASIYER}</td>
                                                <td style={{ textAlign: 'right' }}>{item.SIPKG}</td>
                                                <td style={{ textAlign: 'center' }}>{item.SIPTARIH}</td>
                                                <td style={{ textAlign: 'right' }}>{item.TUTARI}</td>
                                                <td style={{ textAlign: 'center' }}>{item.KALEM}</td>
                                                <td style={{ textAlign: 'right' }}>{item.YUKTONAJ}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class='modal fade bs-example-modal-lg' tabindex='-1' role='dialog' aria-labelledby='myLargeModalLabel'>
                            <div className='modal-dialog modal-lg' role='document'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <button type='button' className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true' className='fa fa-times-circle' /></button>
                                        <h4 className='modal-title' id='myModalLabel' style={{fontSize:'15px'}}>Numaralı Fatura Detay </h4>
                                    </div>
                                    <div className='modal-body'>
                                    <div className='panel-body table-responsive '>
                                        <div className="row">
                                            <div className="col-lg-6" >
                                            <input  type='text' id='TXTmail' placeholder='Mail Adresini Giriniz'  style={{border:'1px solid',marginBottom:'10px',paddingLeft:"5px",borderRadius:'5px'}}/><input onClick={this.mailgonder} className="renkkodu" style={{marginLeft:'10px',marginRight:'10px',height:'29px',width:'90px',borderRadius:'5px',color:'white'}} type='submit' value=' Mail Gönder'/>
                                            </div>
                                            <div className="col-lg-6" style={{textAlign:"right"}}>
                                            <input  type='text' id='TXTtelno' placeholder='0 Olmadan Telno giriniz'  style={{border:'1px solid',marginBottom:'10px',paddingLeft:"5px",borderRadius:'5px'}}/><input onClick={this.smsgonder} className="renkkodu" style={{marginLeft:'10px',marginRight:'10px',height:'29px',width:'90px',borderRadius:'5px',color:'white'}} type='submit' value=' SMS Gönder'/>
                                            </div>
                                        </div>
                                  
                                    
                                <table id='TBLdeneme'  className='table table-bordered' style={{ border: '1px solid #ddd' }}>
                                    <thead>
                                        <tr className="renkkodu" style={{   fontSize: '12px', fontFamily: 'sans-serif' }}>
                                            <th>Ürün Adı</th>
                                            <th>Fiyatı</th>
                                            <th>Kur</th>
                                            <th style={{textAlign:'center'}}>Sipariş Miktarı</th>
                                            <th style={{textAlign:'center'}}>Sipariş Tutarı</th>
                                            <th style={{textAlign:'center'}}>Yüklenen Miktar</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                      {sipdetayitems.map(sipitems =>(

                                      
                                            <tr style={{ fontSize: '12px' }}>
                                                <td>{sipitems.URUNADI}</td>
                                                <td style={{textAlign:'right'}}>{sipitems.FIYAT}</td>
                                                <td>{sipitems.KUR}</td>
                                                <td style={{textAlign:'right'}}>{sipitems.MIKTAR}</td>
                                                <td style={{textAlign:'right'}}>{sipitems.TUTAR}</td>
                                                <td style={{textAlign:'right'}}>{sipitems.YUKMIK}</td>
                                            </tr>
                                      ))}
                                      <tr id="TBLbody" className="renkkodu"  style={{   fontSize: '12px', fontFamily: 'sans-serif' }}>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td style={{textAlign:'right'}} id='TXTsumMik'>0</td>
                                          <td style={{textAlign:'right'}} id='TXTsumTut'>0</td>
                                          <td style={{textAlign:'right'}} id='TXTsumYukmik'>0</td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                                    </div>
                                    <div className='modal-footer'>
                                        <button type='button' style={{backgroundColor:'#9b8f90',border:'0px'}} className='btn btn-primary' data-dismiss='modal'>Kapat</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default TamamlananSiparisler
