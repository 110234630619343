import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
export class Hesap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
        this.vericek = this.vericek.bind(this);
    }
    componentDidMount() {
        document.getElementById("TXTkadi").value = Cookies.get("Adi");

    }
    vericek() {
        var adi = document.getElementById("TXTkadi").value;
        var eskisifre = document.getElementById("TXTeskisifre").value;
        var yenisifre = document.getElementById("TXTyenisifre").value;

        fetch(window.$apiurl + '/HESAPGUNCELLE?adi=' + adi + '&eskisifre=' + eskisifre + '&yenisifre=' + yenisifre, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    items: json
                })

                if (json[0]["SONUC"] != "") {
                    alert(json[0]["SONUC"]);
                } else {
                    alert("Şifreniz Güncellemiştir Lütfen Giriş Yapınız");
                    window.location.href = "/login"
                    Cookies.remove("giris");
                    Cookies.remove("Adi");
                }
            })

    }

    render() {
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">
                        <div id="LBLyil" style={{ display: "none" }}></div>


                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Hesap</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-sm-12">
                                <div className="panel panel-default">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div style={{ color: 'black' }} className="alert alert-danger" role="alert">
                                                    - Şifre değişmek için güncel şifrenizi ve yeni şifrenizi yazıp güncelle butonuna basmanız gerekmektedir.<br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-horizontal">

                                            <fieldset>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Kullanıcı Adı</label>
                                                    <div className="col-sm-4">
                                                        <input type="text" id="TXTkadi" readOnly className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Eski Şifre</label>
                                                    <div className="col-sm-4">
                                                        <input type="text" id="TXTeskisifre" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label">Yeni Şifre</label>
                                                    <div className="col-sm-4">
                                                        <input type="text" id="TXTyenisifre" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-sm-2 control-label"> </label>
                                                    <div className="col-sm-8">
                                                        <input type="submit" onClick={this.vericek} value="Güncelle" className="btn btn-primary" />
                                                    </div>
                                                </div>
                                            </fieldset>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>

            </div>
        )
    }
}

export default Hesap
