import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer style={{position:"fixed"}} className="footer">
                <span style={{textAlign:"right"}}>Copyright © 2017. Float</span>
            </footer>

        )
    }
}

export default Footer
