import React, { Component } from 'react'

export class ordino extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
        console.log(props.match.params.id); 
    }
    componentDidMount(){
        var id = this.props.match.params.id;
        sessionStorage.setItem("ordinoID",id);
        window.location.href = "/ODetay";
    }
    render() {
        return (
            <div>
              
            </div>
        )
    }
}

export default ordino
