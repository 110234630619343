import React, { Component } from 'react'
import Footer from '../GerekliSayfalar/Footer'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import "./Sayfa.css"
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'

export class SatTonaj extends Component {
    constructor(props) {
        super(props);
        this.state = {

            columnDefs: [
                { field: 'TIP', headerName: 'Tip', maxWidth: 60, sortable: true, },
                { field: 'BOLGE', headerName: 'Bölge', maxWidth: 80, sortable: true, },
                { field: 'TARIH', headerName: 'Tarih', sortable: true, floatingFilter: true, filter: true, },
                { field: 'FIRMA', minWidth: 200, headerName: 'Firma', sortable: true, floatingFilter: true, filter: true, },
                { field: 'BAGNO', onCellClicked: this.onCellClick, headerName: 'Bağlantı No', sortable: true, },
                { field: 'OFIYAT', headerName: 'Ort Fiyat', sortable: true, type: 'rightAligned', },
                { field: 'TONAJ', headerName: 'Tonajı',  sortable: true, type: 'rightAligned', },
                { field: 'YUKTONAJ', headerName: 'YükTonaj',  sortable: true, type: 'rightAligned', },
                { field: 'KALANTONAJ', headerName: 'Kalan Tonaj',  sortable: true, type: 'rightAligned', },
                { field: 'BAKIYE', headerName: 'Bakiye TL', sortable: true ,type:'rightAligned' },
            ],
            defaultColDef: { flex: 1, }, 
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }
    }

    onGridReady = (params) => {
        document.getElementById("GRDdeneme").style.display = "";
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/STONAJCEK', {headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
        .then((resp) => resp.json())
        .then((data) => updateData(data))
    };

    onCellClick = (params) => {
        window.open('https://mehmetalidc.online/BaglantiFormWeb/'+params.data.BAGNO, '_blank');
        
    };

    componentDidMount() {
        var flag = Cookies.get("Flag");
        if(flag != "1"){
            window.location.href = "/Login"
        }
    }

    render() {
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">
                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Satılabilir Tonaj</h4>
                                </div>
                            </div>
                        </div>

                        <div className="panel panel-default collapsed">
                            <div className="panel-body table-responsive ">
                            <div style={{ width: '100%', height: '100%' }}>
                                <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
                                    <AgGridReact
                                        localeText={this.state.localeText}
                                        animateRows={true}
                                        enableRangeSelection={true}
                                        paginationAutoPageSize={true}
                                        columnDefs={this.state.columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        onGridReady={this.onGridReady}
                                        rowData={this.state.rowData}  
                                    />
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default SatTonaj
