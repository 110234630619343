import React, { Component } from 'react'
import Cookies from 'js-cookie'
export class App extends Component {
  componentDidMount(){
    window.location.href = "/Login"
  }
  render() {
    return (
      <div>
        
      </div>
    )
  }
}

export default App
