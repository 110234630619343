import React, { Component } from 'react'

export class YuklemeOrdinoMail extends Component {
    componentDidMount(){
        var yukref = this.props.match.params.yukref;
        var plaka = this.props.match.params.plaka;
       // var tedid = this.props.match.params.tedid;
        sessionStorage.setItem("yukref",yukref);
        sessionStorage.setItem("plaka",plaka);
       // sessionStorage.setItem("tedid",tedid);
        window.location.href = "/YOrdinoMail";
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default YuklemeOrdinoMail
