import React, { Component } from 'react'
import Footer from '../GerekliSayfalar/Footer'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import "./Sayfa.css"
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'

export class gYTonaji extends Component {
    constructor(props) {
        super(props);
        this.state = {

            columnDefs: [
                { field: 'YUKREF', onCellClicked:this.onCellClickbag, headerName: 'YükREF', sortable: true, },
                { field: 'FIRMA', minWidth: 200, headerName: 'Firma', sortable: true, floatingFilter: true, filter: true, },
                { field: 'PLAKA', headerName: 'Plaka', sortable: true, },
                { field: 'NKG', headerName: 'NKG', sortable: true, type: 'rightAligned', },
                { field: 'MFIYAT', headerName: 'MFiyatı',  sortable: true, type: 'rightAligned', },
                { field: 'STUTARI', headerName: 'SatışTutarı', sortable: true, type: 'rightAligned', },
                { field: 'TEDARIKCI', minWidth: 200, headerName: 'Tedarikçi', sortable: true, floatingFilter: true, filter: true, },
                { field: 'TFIYAT', headerName: 'TFiyatı', sortable: true ,type:'rightAligned' },
            ],
            columnDefsdetay: [
                { field: 'ORDREF', maxWidth: 100, headerName: 'OrdinoNo', maxWidth: 90, sortable: true },
                { field: 'YUKTARIH', minWidth: 110, headerName: 'Yük Tarihi', sortable: true, },
                { field: 'PLAKA',cellStyle: { fontWeight: 'bold',color:'red' }, headerName: 'Plaka', sortable: true, },
                { field: 'URUNADI', headerName: 'Ürün Adi', sortable: true },
                { field: 'NKG',maxWidth: 100, headerName: 'YükTonaj',type: 'rightAligned', filter: 'Yük Tonaj', sortable: true },
                { field: 'MFIYAT',maxWidth: 100, headerName: 'Birim Fiyat',  sortable: true, type: 'rightAligned', },
                { field: 'SATISTUTAR',maxWidth: 100, headerName: 'Satış Tutar', sortable: true, type: 'rightAligned', },
            ],
            defaultColDef: { flex: 1, }, 
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }
    }

    onGridReady = (params) => {
        document.getElementById("GRDdeneme").style.display = "";
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/GYTONAJCEK', {headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
        .then((resp) => resp.json())
        .then((data) => updateData(data))
    };
    onCellClick = (params) => {
        document.getElementById("myModalLabel").innerHTML = params.data.BAGNO +" Numaralı Yükleme"
        document.getElementById("BTNmdl").click();
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData2: data });
        };
        fetch(window.$apiurl + '/FSYUKCEK?fisref=' + params.data.BAGNO, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
        .then((resp) => resp.json())
        .then((data) => updateData(data));
    };

    onCellClickbag = (params) => {
        window.open('https://mehmetalidc.online/BaglantiFormWeb/'+params.data.YUKREF, '_blank');
        
    };

    componentDidMount() {
        var flag = Cookies.get("Flag");
        if(flag != "1"){
            window.location.href = "/Login"
        }
    }

    render() {
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">
                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Günlük Yüklemeler</h4>
                                </div>
                            </div>
                        </div>

                        <div className="panel panel-default collapsed">
                            <input id="BTNmdl" style={{ display: 'none' }} type="button" value="d" data-toggle="modal" data-target=".bs-example-modal-lg" />
                            <div className="panel-body table-responsive ">
                            <div style={{ width: '100%', height: '100%' }}>
                                <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
                                    <AgGridReact
                                        localeText={this.state.localeText}
                                        animateRows={true}
                                        enableRangeSelection={true}
                                        paginationAutoPageSize={true}
                                        columnDefs={this.state.columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        onGridReady={this.onGridReady}
                                        rowData={this.state.rowData}  
                                    />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                        <h4 className="modal-title" id="myModalLabel" style={{ fontSize: "15px" }}>Numaralı Yükleme </h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="panel-body table-responsive ">
                                            <div style={{ width: '100%', height: '100%' }}>
                                                <div className="ag-theme-balham" style={{ height: 200, width: "100%" }}>
                                                    <AgGridReact
                                                        localeText={this.state.localeText}
                                                        animateRows={true}
                                                        enableRangeSelection={true}
                                                        paginationAutoPageSize={true}
                                                        columnDefs={this.state.columnDefsdetay}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.rowData2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" style={{ border: "0px", width:"200px" }} className="btn btn-primary renkkodu" data-dismiss="modal">Kapat</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default gYTonaji
