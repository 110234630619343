import React, { Component } from 'react'

export class Sform extends Component {
    componentDidMount() {
        document.getElementById("DVframe").innerHTML = "<iframe  style='width:100%;height:1400px' src='https://mentalsoft.net:65/mehmetalidcsipform.aspx?sipref=" + sessionStorage.getItem("ysipno") + "'/>";
    }
    render() {
        return (
            <div>
                <div style={{ width: "100%", height: '1500px' }} id="DVframe">
                </div>
            </div>
        )
    }
}

export default Sform
