import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import '../App.css'
import './Sayfa.css'
import Cookies from 'js-cookie'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
export class CariAnasayfa extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.sevkiyatgit = this.sevkiyatgit.bind(this);
        this.git = this.git.bind(this);
        this.git2 = this.git2.bind(this);
    }
    componentDidMount() {
        this.vericek();
    }
    sevkiyatgit(durum) {
        sessionStorage.setItem("zdurum", durum);
        window.location.href = "/CSevkiyatlar";
    }
    ordgit(durum) {
        sessionStorage.setItem("zdurum", durum);
        window.location.href = "/cOrdino";
    }
    vericek() {
        var cariid = Cookies.get("CariID");
        fetch(window.$apiurl + "/CANACEK?cariID=" + cariid, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                document.getElementById("LBLbakiye").innerHTML = json[0]["BAKIYE"] + " TL";
                document.getElementById("LBLbtonaj").innerHTML = json[0]["BTONAJ"] + " KG";
                document.getElementById("LBLbtl").innerHTML = json[0]["BTL"] + " TL";
                document.getElementById("LBLytonaj").innerHTML = json[0]["YTONAJ"] + " KG";
                document.getElementById("LBLytl").innerHTML = json[0]["YTL"] + " TL";
                document.getElementById("LBLbekordino").innerHTML = json[0]["BEKORDINO"];
                document.getElementById("LBLkapordino").innerHTML = json[0]["KAPORDINO"];
                //document.getElementById("LBLgordino").innerHTML = json[0]["TOPORD"];
            });
    }
    git() {
        window.location.href = "/cBekleyenbaglantilar";
    }
    git2() {
        window.location.href = "/CariHareketleri";
    }
    render() {
        return (
            <div>
                <div>
                    <Header />
                    <Leftsidebar />
                    <section class="main-content container">



                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Anasayfa</h4>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <div className="row">
                                <div>
                                    <div data-toggle="tooltip" data-placement="top" title="" data-original-title="Ekstre Sayfasına Gider" style={{ cursor: 'pointer' }} onClick={this.git2} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget bg-teal padding-0">
                                            <div className="row row-table">
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <em className="icon-wallet fa-3x" />
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLbakiye" className="mv-0">0 TL</h2>
                                                    <div className="text-uppercase">Bakİye</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}

                                    <div style={{ cursor: 'pointer' }} onClick={this.git} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget bg-success padding-0">
                                            <div className="row row-table">
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <em className="icon-calculator fa-3x" />
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLbtonaj" className="mv-0">0</h2>
                                                    <div className="text-uppercase">Bekleyen Tonaj</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}


                                    <div style={{ cursor: 'pointer' }} onClick={() => this.sevkiyatgit(3)} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget bg-info padding-0">
                                            <div className="row row-table" style={{backgroundColor:'chocolate'}}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <em className="icon-cloud-upload fa-3x" />
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLytonaj" className="mv-0">0</h2>
                                                    <div className="text-uppercase">Yüklenen Tonaj</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}



                                    <div style={{ cursor: 'pointer' }} onClick={() => this.ordgit(0)} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget bg-danger padding-0">
                                            <div className="row row-table">
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <em className="icon-docs fa-3x" />
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '14px' }} id="LBLbekordino" className="mv-0">0</h2>
                                                    <div style={{ fontSize: '13px' }} className="text-uppercase">Bekleyen Ordino</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}
                                    {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="widget bg-indigo padding-0">
                                        <div className="row row-table">
                                            <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                <em className="icon-cloud-download fa-3x" />
                                            </div>
                                            <div className="col-xs-8 pv-15 text-center">
                                                <h2 style={{ fontSize: '15px' }} id="LBLgordino" className="mv-0">0</h2>
                                                <div className="text-uppercase">Günlük Ordino</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget bg-teal padding-0">
                                            <div className="row row-table">
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <em className="icon-notebook fa-3x" />
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLotarihleri" className="mv-0">0</h2>
                                                    <div className="text-uppercase">Ödeme Tarihleri</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}
                                    <div style={{ cursor: 'pointer' }} onClick={this.git} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget bg-success padding-0">
                                            <div className="row row-table">
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <em className="icon-bell fa-3x" />
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLbtl" className="mv-0">0</h2>
                                                    <div className="text-uppercase">Bekleyen TL</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}
                                    <div style={{ cursor: 'pointer' }} onClick={() => this.sevkiyatgit(3)} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget bg-info padding-0">
                                            <div className="row row-table" style={{backgroundColor:'chocolate'}}>
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <em className="icon-tag fa-3x" />
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLytl" className="mv-0">0</h2>
                                                    <div className="text-uppercase">Yüklenen TL</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}
                                    <div style={{ cursor: 'pointer' }} onClick={() => this.ordgit(1)} className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="widget bg-danger padding-0">
                                            <div className="row row-table">
                                                <div className="col-xs-4 text-center pv-15 bg-light-dark">
                                                    <em className="icon-book-open fa-3x" />
                                                </div>
                                                <div className="col-xs-8 pv-15 text-center">
                                                    <h2 style={{ fontSize: '15px' }} id="LBLkapordino" className="mv-0">0</h2>
                                                    <div className="text-uppercase">Kapanan Ordino</div>
                                                </div>
                                            </div>
                                        </div>{/*end widget*/}
                                    </div>{/*end col*/}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div style={{color:'black'}} className="alert alert-danger" role="alert">
                                        - Sisteme ilk defa giriş yapıyorsanız <a href="/Hesap">Hesap</a> sayfasından şifreniz değiştirmeniz önerilir.<br/>
                                        - Bakiye yazan kart'a tıklıyarak cari eksterinizin olduğu sayfaya gidebilirsiniz.<br/>
                                        - Bekleyen Tonaj yazan kart'a tıklıyarak bekleyen bağlantılarınız olduğu sayfaya gidebilirsiniz.<br/>
                                        - Bekleyen TL yazan kart'a tıklıyarak bekleyen bağlantılarınız olduğu sayfaya gidebilirsiniz.<br/>
                                        - Yüklenen Tonaj yazan kart'a tıklıyarak yüklenen bağlantılarınız olduğu sayfaya gidebilirsiniz.<br/>
                                        - Yüklenen TL yazan kart'a tıklıyarak yüklenen bağlantılarınız olduğu sayfaya gidebilirsiniz.<br/>
                                        - Bekleyen Ordino yazan kart'a tıklıyarak bekleyen ordinolarınızın olduğu sayfaya gidebilirsiniz.<br/>
                                        - Kapanan Ordino yazan kart'a tıklıyarak kapanan ordinolarınızın olduğu sayfaya gidebilirsiniz.<br/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>


            </div>
        )
    }
}

export default CariAnasayfa
