import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import "./Sayfa.css"
import Cookies from 'js-cookie'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class Uyelog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            columnDefs: [
                { field: 'ISIM', headerName: 'Kullanıcı Adı', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'IP', headerName: 'IP Adresi', sortable: true, floatingFilter: true, filter: true, },
                { field: 'TARIH', headerName: 'Tarih', sortable: true, floatingFilter: true, filter: true, },
            ],
            defaultColDef: { flex: 1, },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }
        // this.vericek = this.vericek.bind(this);
    }
    onGridReady = (params) => {
        var cariid = Cookies.get("CariID");
        var flag = Cookies.get("Flag");
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/UYELOGCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    /*  vericek() {
        // var arama = document.getElementById("TXTarama").value;
         fetch(window.$apiurl + '/UYELOGCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
             .then(res => res.json())
             .then(json => {
                 this.setState({
                     items: json
                 })
             })
     } */
    componentDidMount() {
        var flag = Cookies.get("Flag");
        if (flag != "1") {
            window.location.href = "/Login"
        }
        // this.ricek();
    }
    render() {
        var { items } = this.state
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">



                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Üye Log</h4>
                                </div>
                            </div>
                        </div>

                        <div className="panel panel-default collapsed">
                            {/* <div className="row">
                                <div className="col-lg-3">
                                    <input onChange={this.vericek} type="text" placeholder="İsim Yazınız" id="TXTarama" className="inptext" style={{ marginTop: "10px", marginLeft: "15px", border: "1px solid #9b8f90" }}></input>

                                </div>
                            </div> */}
                            <div className="panel-body table-responsive ">
                                <div style={{ width: '100%', height: '100%' }}>
                                    <div className="ag-theme-balham" style={{ height: 600, width: "100%" }}>
                                        <AgGridReact
                                            localeText={this.state.localeText}
                                            animateRows={true}
                                            enableRangeSelection={true}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            onGridReady={this.onGridReady}
                                            rowData={this.state.rowData}
                                        //onCellDoubleClicked={this.onCellClick}    
                                        />
                                    </div>
                                </div>
                                {/* <table className="table table-bordered" style={{ border: "1px solid #ddd" }}>
                                    <thead>
                                        <tr style={{ backgroundColor: "#cfcdcd", fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <th>İsim</th>
                                            <th>IP</th>
                                            <th>Tarih</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => (
                                            <tr  style={{ fontSize: "12px" }}>
                                                <td>{item.ISIM}</td>
                                                <td>{item.IP}</td>
                                                <td>{item.TARIH}</td>
                                            </tr>
                                        ))}


                                    </tbody>
                                </table> */}
                            </div>
                        </div>

                    </section>
                </div>

            </div>
        )
    }
}

export default Uyelog
