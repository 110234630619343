import React, { Component } from 'react'
import Footer from '../GerekliSayfalar/Footer'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import './renk.css'
export class Bekleyensiparisler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isloaded: false,
            sipdetayitems:[]
        }
        this.sipdetay = this.sipdetay.bind(this);
    }
    componentDidMount() {
        this.vericek();
    }
    sipdetay(sipref) {
        document.getElementById("myModalLabel").innerHTML = sipref + " Numaralı Fatura Detayı";
        // alert(sipref);
        fetch(window.$apiurl + '/BEKSIPDETAYCEK?sipref='+sipref,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
        .then(res => res.json())
        .then(json => {
            //console.log(json);
            this.setState({
                sipdetayitems: json,
                isloaded: true
            })
            document.getElementById("TXTsumMik").innerHTML = json[0]["SUMMIKTAR"];
            document.getElementById("TXTsumTut").innerHTML = json[0]["SUMTUTAR"];
            document.getElementById("TXTsumYukmik").innerHTML = json[0]["SUMYUKMIK"];
        });
    }
    vericek() {
        if (Cookies.get("durum") != "DEMİR") {
            return;
        }
        var cariid = Cookies.get("CariID");
        fetch(window.$apiurl + '/BEKLEYENSIPCEK?cariID='+cariid,{headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
            .then(res => res.json())
            .then(json => {
                //console.log(json);
                this.setState({
                    items: json,
                    isloaded: true
                })
            });
    }


    render() {
        var { items,sipdetayitems } = this.state
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">



                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Bekleyen Siparişler</h4>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default collapsed">

                            <div className="panel-body table-responsive ">
                                <table className="table table-bordered" style={{ border: "1px solid #ddd" }}>
                                    <thead>
                                        <tr className="renkkodu" style={{  fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <th>#</th>
                                            <th>SipNo</th>
                                            <th style={{ width: "230px" }}>Firma Adı</th>
                                            <th>S.Y</th>
                                            <th>Plasiyer</th>
                                            <th>Sipariş KG</th>
                                            <th>Sip Tarihi</th>
                                            <th style={{textAlign:"center"}}>Tutarı</th>
                                            <th>Kalem</th>
                                            <th>Yuk Tonaj</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map(item => (
                                            <tr style={{ fontSize: "12px" }}>
                                                <td style={{ textAlign: "center" ,width:"33px",cursor:"pointer"}}><span class="label label-info renkkodu"  data-toggle="modal" data-target=".bs-example-modal-lg" onClick={() => this.sipdetay(item.SIPREF)}>Detay</span></td>
                                                <td>{item.SIPREF}</td>
                                                <td>{item.FIRMAADI}</td>
                                                <td>{item.SY}</td>
                                                <td>{item.PLASIYER}</td>
                                                <td style={{ textAlign: "right" }}>{item.SIPKG}</td>
                                                <td style={{ textAlign: "center" }}>{item.SIPTARIH}</td>
                                                <td style={{ textAlign: "right" }}>{item.TUTARI}</td>
                                                <td style={{ textAlign: "center" }}>{item.KALEM}</td>
                                                <td style={{ textAlign: "right" }}>{item.YUKTONAJ}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="fa fa-times-circle" /></button>
                                        <h4 className="modal-title" id="myModalLabel" style={{fontSize:"15px"}}>Numaralı Fatura Detay </h4>
                                    </div>
                                    <div className="modal-body">
                                    <div className="panel-body table-responsive ">
                                <table className="table table-bordered" style={{ border: "1px solid #ddd" }}>
                                    <thead>
                                        <tr className="renkkodu" style={{  fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <th>Ürün Adı</th>
                                            <th>Fiyatı</th>
                                            <th>Kur</th>
                                            <th style={{textAlign:"center"}}>Sipariş Miktarı</th>
                                            <th style={{textAlign:"center"}}>Sipariş Tutarı</th>
                                            <th style={{textAlign:"center"}}>Yüklenen Miktar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                      {sipdetayitems.map(sipitems =>(

                                      
                                            <tr style={{ fontSize: "12px" }}>
                                                <td>{sipitems.URUNADI}</td>
                                                <td style={{textAlign:"right"}}>{sipitems.FIYAT}</td>
                                                <td>{sipitems.KUR}</td>
                                                <td style={{textAlign:"right"}}>{sipitems.MIKTAR}</td>
                                                <td style={{textAlign:"right"}}>{sipitems.TUTAR}</td>
                                                <td style={{textAlign:"right"}}>{sipitems.YUKMIK}</td>
                                            </tr>
                                      ))}
                                      <tr  className="renkkodu" style={{  fontSize: "12px", fontFamily: "sans-serif" }}>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td style={{textAlign:"right"}} id="TXTsumMik">0</td>
                                          <td style={{textAlign:"right"}} id="TXTsumTut">0</td>
                                          <td style={{textAlign:"right"}} id="TXTsumYukmik">0</td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" style={{border:"0px"}} className="btn btn-primary renkkodu" data-dismiss="modal">Kapat</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>

            </div>
        )
    }
}

export default Bekleyensiparisler
