import React, { Component } from 'react'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
export class Yukleme extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            items2: [],
            veri: 2,
            columnDefs: [
                { field: 'FIRMAADI', minWidth: 120, headerName: 'Firma Adı', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'IRSALIYENO', headerName: 'İrsaliyeNo', type: 'rightAligned', sortable: true, floatingFilter: true, filter: true, },
                { field: 'YUKKG', headerName: 'Yuk KG', sortable: true, type: 'rightAligned', floatingFilter: true, filter: true, },
                { field: 'YUKTL', headerName: 'Yuk TL', filter: 'agSetColumnFilter', type: 'rightAligned', sortable: true, floatingFilter: true, },
                //{ field: 'TONAJ', sortable: true,cellStyle:{background:'rgb(57, 141, 19)',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
                { field: 'NAKTUT', headerName: 'Nak Tutarı', type: 'rightAligned', sortable: true, },
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            columnDefs2: [
                { field: 'TEDADI', minWidth: 120, headerName: 'Tedarikci Adı', cellRenderer: 'agGroupCellRenderer', sortable: true, floatingFilter: true, filter: true, },
                { field: 'IRSALIYENO', headerName: 'İrsaliyeNo', sortable: true, type: 'rightAligned', floatingFilter: true, filter: true, },
                { field: 'YUKKG', headerName: 'Yuk KG', sortable: true, floatingFilter: true, type: 'rightAligned', filter: true, },
                { field: 'YUKTL', headerName: 'Yuk TL', filter: 'agSetColumnFilter', type: 'rightAligned', sortable: true, floatingFilter: true, },
                //{ field: 'TONAJ', sortable: true,cellStyle:{background:'rgb(57, 141, 19)',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
                //{ field: 'NAKTUT', headerName: 'Nak Tutarı', sortable: true, },
                //{ field: 'KALANTUT', sortable: true,cellStyle:{background:'#ce3524',color:'white'},type:'rightAligned', filter: true,floatingFilter: true, },
            ],
            /* <td>{itemm.TEDADI}</td>
            <td>{itemm.IRSALIYENO}</td>
            <td>{itemm.YUKKG}</td>
            <td>{itemm.YUKTL}</td> */
            defaultColDef: { flex: 1, },
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowData2: null,
        }

        //this.vericek = this.vericek.bind(this);
    }
    onGridReady = (params) => {
        document.getElementById("GRDdeneme").style.display = "";
        document.getElementById("GRDdeneme2").style.display = "none";
        var tarih = document.getElementById("BASTARIH").value;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/YUKMUSTERICEK?tarih=' + tarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    onGridReady2 = (params) => {
        document.getElementById("GRDdeneme2").style.display = "";
        document.getElementById("GRDdeneme").style.display = "none";
        var tarih = document.getElementById("BASTARIH").value;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData2: data });
        };

        fetch(window.$apiurl + '/YUKTEDCEK?tarih=' + tarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then((resp) => resp.json())
            .then((data) => updateData(data));
    };
    componentDidMount() {
        //document.getElementById("BASTARIH").value =yyyy+"-"+mm+"-"+dd;
        this.tarihcek();
        document.getElementById("GRDdeneme").style.display = "none"
        document.getElementById("GRDdeneme2").style.display = "none"
        //document.getElementById("EXtable2").style.display = "none"
    }

    tarihcek() {
        fetch(window.$apiurl + '/TARIHCEK', { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
            .then(res => res.json())
            .then(json => {
                //document.getElementById("BASTARIH").value = json[0]["TARIHG"];
                document.getElementById("BASTARIH").value = json[0]["TARIHB"];
            })
    }
    /* vericek(gelen) {

        if (gelen == 0) {
            var tarih = document.getElementById("BASTARIH").value;
            fetch(window.$apiurl + '/YUKMUSTERICEK?tarih=' + tarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                .then(res => res.json())
                .then(json => {
                    this.setState({
                        items: json
                    })
                })
            document.getElementById("GRDdeneme").style.display = ""
            document.getElementById("EXtable2").style.display = "none"
        } else {
            var tarih = document.getElementById("BASTARIH").value;
            fetch(window.$apiurl + '/YUKTEDCEK?tarih=' + tarih, { headers: { 'Authorization': 'Bearer ' + Cookies.get('Token') } })
                .then(res => res.json())
                .then(json => {
                    this.setState({
                        items2: json
                    })
                    console.log(json)
                })
            document.getElementById("EXtable").style.display = "none"
            document.getElementById("EXtable2").style.display = ""
        }
    } */

    render() {
        var { items, items2 } = this.state
        return (
            <div>
                <Header />
                <Rightsidebar />
                <Leftsidebar />
                <section class="main-content container">
                    {/*page header start*/}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Yükleme Bilgileri</h4>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default collapsed">
                        <div className="panel-body table-responsive ">
                            <div style={{ textAlign: "center", marginBottom: "10px" }}><span className="btn btn-primary renkkodu" onClick={this.onGridReady} style={{ border: "0px", marginRight: "10px" }}>Müşteri</span><span className="btn btn-primary renkkodu" onClick={this.onGridReady2} style={{ border: "0px" }}>Fabrika</span><br /></div>
                            <input id="BASTARIH" type="date" style={{ marginRight: "10px", marginBottom: "10px", border: "1px solid" }} /><input className="renkkodu" onClick={this.onGridReady} style={{ marginLeft: "10px", marginRight: "10px", height: "30px", width: "60px", borderRadius: "5px", color: "white" }} type="submit" value="Ara" />

                            <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 500, width: "100%" }}>
                                <AgGridReact
                                    localeText={this.state.localeText}
                                    animateRows={true}
                                    enableRangeSelection={true}
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    //onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                />
                            </div>
                            <div id="GRDdeneme2" className="ag-theme-balham" style={{ height: 500, width: "100%" }}>
                                <AgGridReact
                                    localeText={this.state.localeText}
                                    animateRows={true}
                                    enableRangeSelection={true}
                                    columnDefs={this.state.columnDefs2}
                                    defaultColDef={this.state.defaultColDef}
                                    //onGridReady={this.onGridReady}
                                    rowData={this.state.rowData2}
                                />
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Yukleme
