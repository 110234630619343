import React, { Component } from 'react'

export class Rightsidebar extends Component {
    render() {
        return (
           <nav id="right-sidebar-toggle" className="right_sidebar">
  <div className="nano">
    <div className="nano-content">
      <div>
        {/* Nav tabs */}
        <ul className="list-inline nav-tab-panel clearfix" role="tablist">
          <li role="presentation" className="active"><a href="#projects" aria-controls="projects" role="tab" data-toggle="tab">Projects</a></li>
          <li role="presentation"><a href="#contacts" aria-controls="contacts" role="tab" data-toggle="tab">Contacts</a></li>
        </ul>
        {/* Tab panes */}
        <div className="tab-content">
          <div role="tabpanel" className="tab-pane active" id="projects">
            <ul className="sidebar-list">
              <li className="sidebar-title">
                <h3 className="font-100"> Current projects</h3>
                <small>There are <strong>7</strong> Current project</small>
              </li>
              <li>
                <a href="#">
                  <span className="label label-primary pull-right">New</span>
                  <h4>YII with material design</h4>
                  <p>
                    It is a long established fact that a reader will be distracted.
                  </p>
                  <div className="small">Completion with: 42%</div>
                  <div className="progress progress-mini">
                    <div style={{width: '42%'}} className="progress-bar progress-bar-warning" />
                  </div>
                  <div className="small text-danger">Deadline - September 15, 2016</div>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="label label-primary pull-right">New</span>
                  <h4>HTML to AngularJs</h4>
                  <p>
                    It is a long established fact that a reader will be distracted.
                  </p>
                  <div className="small">Completion with: 12%</div>
                  <div className="progress progress-mini">
                    <div style={{width: '12%'}} className="progress-bar progress-bar-danger" />
                  </div>
                  <div className="small text-danger">Deadline - September 15, 2016</div>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="label label-success pull-right">Company</span>
                  <h4>Website Re-design</h4>
                  <p>
                    It is a long established fact that a reader will be distracted.
                  </p>
                  <div className="small">Completion with: 84%</div>
                  <div className="progress progress-mini">
                    <div style={{width: '84%'}} className="progress-bar progress-bar-success" />
                  </div>
                  <div className="small text-danger">Deadline - September 15, 2016</div>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="label label-success pull-right">Metting</span>
                  <h4>Business Consultancy</h4>
                  <p>
                    It is a long established fact that a reader will be distracted.
                  </p>
                  <div className="small">Completion with: 54%</div>
                  <div className="progress progress-mini">
                    <div style={{width: '54%'}} className="progress-bar progress-bar-info" />
                  </div>
                  <div className="small text-danger">Deadline - September 15, 2016</div>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="label label-primary pull-right">New</span>
                  <h4>YII with material design</h4>
                  <p>
                    It is a long established fact that a reader will be distracted.
                  </p>
                  <div className="small">Completion with: 42%</div>
                  <div className="progress progress-mini">
                    <div style={{width: '42%'}} className="progress-bar progress-bar-warning" />
                  </div>
                  <div className="small text-danger">Deadline - September 15, 2016</div>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="label label-primary pull-right">New</span>
                  <h4>HTML to AngularJs</h4>
                  <p>
                    It is a long established fact that a reader will be distracted.
                  </p>
                  <div className="small">Completion with: 12%</div>
                  <div className="progress progress-mini">
                    <div style={{width: '12%'}} className="progress-bar progress-bar-danger" />
                  </div>
                  <div className="small text-danger">Deadline - September 15, 2016</div>
                </a>
              </li>
            </ul>
          </div>
          <div role="tabpanel" className="tab-pane" id="contacts">
            <ul className="list-unstyled sidebar-contact-list">
              <li className="clearfix">
                <a href="#" className="media-box">
                  <span className="pull-right">
                    <span className="circle circle-success circle-lg" />
                  </span>
                  <span className="pull-left">
                    {/* Contact avatar*/}
                    <img src="images/avtar-1.jpg" alt="user" className="media-box-object img-circle" width={50} />
                  </span>
                  {/* Contact info*/}
                  <span className="media-box-body">
                    <span className="media-box-heading">
                      <strong>Angelina</strong>
                      <br />
                      <small className="text-muted">Designer</small>
                    </span>
                  </span>
                </a>
              </li>{/*li end*/}
              <li className="clearfix">
                <a href="#" className="media-box">
                  <span className="pull-right">
                    <span className="circle circle-success circle-lg" />
                  </span>
                  <span className="pull-left">
                    {/* Contact avatar*/}
                    <img src="images/avtar-2.jpg" alt="user" className="media-box-object img-circle" width={50} />
                  </span>
                  {/* Contact info*/}
                  <span className="media-box-body">
                    <span className="media-box-heading">
                      <strong>John doe</strong>
                      <br />
                      <small className="text-muted">Designer</small>
                    </span>
                  </span>
                </a>
              </li>{/*li end*/}
              <li className="clearfix">
                <a href="#" className="media-box">
                  <span className="pull-right">
                    <span className="circle circle-danger circle-lg" />
                  </span>
                  <span className="pull-left">
                    {/* Contact avatar*/}
                    <img src="images/avtar-3.jpg" alt="user" className="media-box-object img-circle" width={50} />
                  </span>
                  {/* Contact info*/}
                  <span className="media-box-body">
                    <span className="media-box-heading">
                      <strong>Eluga Smith</strong>
                      <br />
                      <small className="text-muted">Designer</small>
                    </span>
                  </span>
                </a>
              </li>{/*li end*/}
              <li className="clearfix">
                <a href="#" className="media-box">
                  <span className="pull-right">
                    <span className="circle circle-lg" />
                  </span>
                  <span className="pull-left">
                    {/* Contact avatar*/}
                    <img src="images/avtar-4.jpg" alt="user" className="media-box-object img-circle" width={50} />
                  </span>
                  {/* Contact info*/}
                  <span className="media-box-body">
                    <span className="media-box-heading">
                      <strong>Jonathan Doe</strong>
                      <br />
                      <small className="text-muted">Designer</small>
                    </span>
                  </span>
                </a>
              </li>{/*li end*/}
              <li className="clearfix">
                <a href="#" className="media-box">
                  <span className="pull-right">
                    <span className="circle circle-success circle-lg" />
                  </span>
                  <span className="pull-left">
                    {/* Contact avatar*/}
                    <img src="images/avtar-5.jpg" alt="user" className="media-box-object img-circle" width={50} />
                  </span>
                  {/* Contact info*/}
                  <span className="media-box-body">
                    <span className="media-box-heading">
                      <strong>Angelina</strong>
                      <br />
                      <small className="text-muted">Designer</small>
                    </span>
                  </span>
                </a>
              </li>{/*li end*/}
              <li>
                <div className=" text-center">
                  {/* Optional link to list more users*/}
                  <a href="javascript:void(0);" title="See more contacts" className="btn btn-teal">
                    Load more..
                  </a>
                </div>
              </li>
            </ul>{/*end sidebar contacts-list*/}
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

        )
    }
}

export default Rightsidebar
