import React, { Component } from 'react'
import Datepicker from 'react-datepicker'
import ReactToExcel from 'react-html-table-to-excel'
export class deneme extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    
    }
    componentDidMount(){
       
    }
  
    render() {
        return (
            <div>
                 <ReactToExcel table="EXtable" style={{display:"none"}} filename="deneme" sheet="sheet 1" buttonText="EXPORT" className="btn btn-primary"/><br/>
                <table className="table table-bordered" id="EXtable" style={{ border: "1px solid #ddd" }}>
                                    <thead>
                                        <tr  style={{ backgroundColor: "#cfcdcd", fontSize: "12px", fontFamily: "sans-serif" }}>
                                            <th>Plasiyer</th>
                                            <th>Tonaj</th>
                                            <th style={{ width: "200px" }}>Tutar</th>
                                            <th>OrtBrFiyat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            <tr style={{ fontSize: "12px" }}>
                                                <td>asdasd</td>
                                                <td>aasdasdd</td>
                                                <td>asdasdd</td>
                                                <td>asdasd</td>
                                            </tr>   
                                            <tr style={{ fontSize: "12px" }}>
                                                <td>asdasd</td>
                                                <td>aasdasdd</td>
                                                <td>asdasdd</td>
                                                <td>asdasd</td>
                                            </tr>                                          
                                    </tbody>
                                </table>
            </div>
        )
    }
}

export default deneme
