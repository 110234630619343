import React, { Component } from 'react'
import Footer from '../GerekliSayfalar/Footer'
import Header from '../GerekliSayfalar/Header'
import Leftsidebar from '../GerekliSayfalar/Leftsidebar'
import Rightsidebar from '../GerekliSayfalar/Rightsidebar'
import "./Sayfa.css"
import Cookies from 'js-cookie'
import './renk.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'

export class gAStok extends Component {
    constructor(props) {
        super(props);
        this.state = {

            columnDefs: [
                { field: 'LREF', headerName: 'LogicalREF', minWidth: 100, sortable: true, },
                { field: 'TEDARIKCI', minWidth: 200, headerName: 'Tedarikçi', sortable: true, floatingFilter: true, filter: true, },
                { field: 'STOKID', headerName: 'Stok ID', sortable: true, },
                { field: 'URUNKODU', headerName: 'Ürün Kodu', sortable: true, },
                { field: 'STOKMIK', headerName: 'Stok Miktarı',  sortable: true, type: 'rightAligned', },
                { field: 'YUKLENEN', headerName: 'Yüklenen',  sortable: true, type: 'rightAligned', },
                { field: 'REZMIK', headerName: 'RezerveMik',  sortable: true, type: 'rightAligned', },
                { field: 'KALANSTOK', headerName: 'Kalan Stok', sortable: true ,type:'rightAligned' },
                { field: 'KALANTONAJ', headerName: 'Kalan Tonaj', sortable: true ,type:'rightAligned' },
                { field: 'ACIKLAMA', headerName: 'Açıklama', sortable: true, },
            ],
            defaultColDef: { flex: 1, }, 
            localeText: {
                searchOoo: 'Arama Yapabilirsiniz...',
                noMatches: 'Kayıt Bulunamadı!.',
            },
            rowData: null,
            rowdetay: null
        }
    }

    onGridReady = (params) => {
        document.getElementById("GRDdeneme").style.display = "";
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = (data) => {
            this.setState({ rowData: data });
        };

        fetch(window.$apiurl + '/GASTOKCEK', {headers:{'Authorization':'Bearer '+Cookies.get('Token')}})
        .then((resp) => resp.json())
        .then((data) => updateData(data))
    };

    componentDidMount() {
        var flag = Cookies.get("Flag");
        if(flag != "1"){
            window.location.href = "/Login"
        }
    }

    render() {
        return (
            <div>
                <div>
                    <Header />
                    <Rightsidebar />
                    <Leftsidebar />
                    <section class="main-content container">
                        {/*page header start*/}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Günlük Açılan Stok</h4>
                                </div>
                            </div>
                        </div>

                        <div className="panel panel-default collapsed">
                            <div className="panel-body table-responsive ">
                            <div style={{ width: '100%', height: '100%' }}>
                                <div id="GRDdeneme" className="ag-theme-balham" style={{ height: 400, width: "100%" }}>
                                    <AgGridReact
                                        localeText={this.state.localeText}
                                        animateRows={true}
                                        enableRangeSelection={true}
                                        paginationAutoPageSize={true}
                                        columnDefs={this.state.columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        onGridReady={this.onGridReady}
                                        rowData={this.state.rowData}  
                                    />
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default gAStok
