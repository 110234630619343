import React, { Component } from 'react'
import Cookies from 'js-cookie'
export class Leftsidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
    this.cikis = this.cikis.bind();
  }
  componentDidMount() {
    if (Cookies.get("durum") != "" || Cookies.get("durum") != null) {
      document.getElementById("secilidurum").value = Cookies.get("durum");
    } else {
      document.getElementById("secilidurum").value = "DEMİR";
    }
    var cariid = Cookies.get("CariID");
    var flag = Cookies.get("Flag");
    /* if(cariid != "0" && flag != "1"){
       window.location.href = "/Login"
     }*/
    if (cariid != "0" && flag != "1") {
      document.getElementById("LBLgenelrapor").style.display = "none";
      document.getElementById("LBLcaribilgileri").style.display = "none";
      document.getElementById("LBLplasiyerbilgileri").style.display = "none";
      document.getElementById("LBLyukleme").style.display = "none";
      document.getElementById("LBLsatis").style.display = "none";
      document.getElementById("LBLdepo").style.display = "none";
      document.getElementById("LBLuyelog").style.display = "none";
      document.getElementById("LBLgecmisgelir").style.display = "none";
      document.getElementById("LBLstok").style.display = "none";
      document.getElementById("LBLuyeislemleri").style.display = "none";
      document.getElementById("LBLbaglantilar").style.display = "none";
      //document.getElementById("LBLsevkiyatlar").style.display = "none";
      document.getElementById("LBLAYuklemeleri").style.display = "none";
      document.getElementById("LBLSYuklemeleri").style.display = "none";
      //document.getElementById("LBLSevkiyatlar").style.display = "none";
      document.getElementById("LBLalis").style.display = "none";
      document.getElementById("LBLsatis").style.display = "none";
      document.getElementById("LBLfinansman").style.display = "none";



    }
    else {
      document.getElementById("LBLcanasayfa").style.display = "none";
      document.getElementById("LBLcarihareketleri").style.display = "none";
      document.getElementById("LBLcsevkiyatlar").style.display = "none";
      //document.getElementById("LBLcaribaglantilar").style.display = "none";
    }
  }
  sevkiyatgit(durum) {
    sessionStorage.setItem("zdurum", durum);
    window.location.href = "/CSevkiyatlar";
  }
  kontrol() {
    var dd = document.getElementById("secilidurum").value;
    console.log(dd);
    Cookies.set("durum", dd, { expires: 1 });
    window.location.reload();
  }
  cikis() {
    Cookies.remove('giris');
    Cookies.remove('Adi');
    window.location.href = "/Login"
  }
  render() {
    return (
      <aside className="float-navigation light-navigation">
        <div className="nano">
          <div className="nano-content">
            <ul className="metisMenu nav" id="menu" >
              <li className="nav-heading"></li>
              <li style={{ display: 'none' }}>
                <a><i className="icon-home"></i>
                  <select style={{ width: "85%" }} onChange={this.kontrol} id="secilidurum">
                    <option>DEMİR</option>
                    <option>PROFİL</option>
                    <option>KANGAL</option>
                  </select>
                </a>
              </li>
              <li id="LBLgenelrapor" >
                <a style={{ fontFamily: "sans-serif" }} href="/Anasayfa"><i className="fas fa-home" /> Genel Rapor</a>
              </li>
              <li id="LBLcanasayfa" >
                <a style={{ fontFamily: "sans-serif" }} href="/CariAnasayfa"><i className="fas fa-home" /> Anasayfa</a>
              </li>
              <li id="LBLcaribaglantilar" >
                <a href="javascript: void(0);" style={{ fontFamily: "sans-serif" }} aria-expanded="true"><i className="fas fa-plane" /> Bağlantılar <span className="fa arrow" /></a>
                <ul className="nav-second-level nav" aria-expanded="true">
                  <li><a style={{ fontFamily: "sans-serif" }} href="/cBekleyenbaglantilar"><i className="fas fa-plane-departure" style={{ marginRight: "5px" }} />Bekleyen Bağlantılar</a></li>
                  <li><a style={{ fontFamily: "sans-serif" }} href="/cKapananbaglantilar"><i className="fas fa-plane-arrival" style={{ marginRight: "5px" }} />Kapanan Bağlantılar</a></li>
                </ul>
              </li>
              <li style={{ display: 'none' }} id="LBLbaglantilar">
                <a href="javascript: void(0);" style={{ fontFamily: "sans-serif" }} aria-expanded="true"><i className="icon-plane" /> Bağlantılar <span className="fa arrow" /></a>
                <ul className="nav-second-level nav" aria-expanded="true">
                  <li><a style={{ fontFamily: "sans-serif" }} href="/Alisbaglantilari"><i className="icon-plane" style={{ marginRight: "5px" }} />Alış Bağlantıları</a></li>
                  <li><a style={{ fontFamily: "sans-serif" }} href="/Satisbaglantilari"><i className="icon-plane" style={{ marginRight: "5px" }} />Satış Bağlantıları</a></li>
                </ul>
              </li>
              <li style={{ display: 'none' }}>
                <a style={{ fontFamily: "sans-serif" }} href="javascript: void(0);" aria-expanded="true"><i className="icon-social-dropbox" /> Siparişler <span className="fa arrow" /></a>
                <ul className="nav-second-level nav" aria-expanded="true">
                  <li><a style={{ fontFamily: "sans-serif" }} href="/Bekleyensiparisler"><i className="icon-social-dropbox" style={{ marginRight: "5px" }} />Bekleyen</a></li>
                  <li><a style={{ fontFamily: "sans-serif" }} href="/TamamlananSiparisler"><i className="icon-social-dropbox" style={{ marginRight: "5px" }} />Tamamlanan</a></li>
                </ul>
              </li>
              <li id="LBLcaribilgileri">
                <a style={{ fontFamily: "sans-serif" }} href="/CariBilgileri" ><i className="fas fa-users" /> Cari Bilgileri</a>
              </li>
              <li id="LBLAYuklemeleri">
                <a style={{ fontFamily: "sans-serif" }} href="/AlisYuklemeleri"><i className="fas fa-dolly" /> Alış Yüklemeleri </a>
              </li>
              <li id="LBLSYuklemeleri">
                <a style={{ fontFamily: "sans-serif" }} href="/SatisYuklemeleri"><i className="fas fa-shuttle-van" /> Satış Yüklemeleri </a>
              </li>
              <li style={{display:'none'}} id="LBLplasiyerbilgileri"><a style={{ fontFamily: "sans-serif" }} href="/PlasiyerBilgileri"><i className="icon-user-follow" /> Plasiyer Bilgileri</a></li>
              {/* <li id="LBLsevkiyatlar">
                <a style={{ fontFamily: "sans-serif" }} href="/Sevkiyatlar"><i className="fas fa-shuttle-van" /> Sevkiyatlar </a>
              </li> */}
              <li id="LBLcsevkiyatlar">
                <a style={{ fontFamily: "sans-serif" }} onClick={() => this.sevkiyatgit(3)}><i className="fas fa-shuttle-van" /> Sevkiyatlar </a>
              </li>
              <li id="LBLcarihareketleri">
                <a style={{ fontFamily: "sans-serif" }} href="/CariHareketleri"><i className="fas fa-calendar-check" /> Cari Hareketlerim </a>
              </li>
              <li id="LBLalis">
                <a style={{ fontFamily: "sans-serif" }} href="javascript: void(0);" aria-expanded="true"><i className="fas fa-tag" /> Alış<span className="fa arrow" /></a>
                <ul className="nav-second-level nav" aria-expanded="true">
                  <li><a style={{ fontFamily: "sans-serif" }} href="/BAlis"><i className="fas fa-tag" style={{ marginRight: "5px" }} />Bekleyen Alışlar</a></li>
                  <li><a style={{ fontFamily: "sans-serif" }} href="/TAlis"><i className="fas fa-tag" style={{ marginRight: "5px" }} /> Tamamlanan Alışlar</a></li>
                </ul>
              </li>
              <li id="LBLsatis">
                <a style={{ fontFamily: "sans-serif" }} href="javascript: void(0);" aria-expanded="true"><i className="fas fa-tags" /> Satış<span className="fa arrow" /></a>
                <ul className="nav-second-level nav" aria-expanded="true">
                  <li><a style={{ fontFamily: "sans-serif" }} href="/BSatis"><i className="fas fa-tags" style={{ marginRight: "5px" }} />Bekleyen Satışlar</a></li>
                  <li><a style={{ fontFamily: "sans-serif" }} href="/TSatis"><i className="fas fa-tags" style={{ marginRight: "5px" }} /> Tamamlanan Satışlar</a></li>
                </ul>
              </li>
              <li id="LBLfinansman">
                <a style={{ fontFamily: "sans-serif" }} href="/cBankaBilgileri"><i className="fas fa-university" /> Finansman </a>
              </li>
              <li id="LBLbankabilgileri">
                <a style={{ fontFamily: "sans-serif" }} href="/cBankaBilgileri"><i className="fas fa-university" /> Banka Bilgileri </a>
              </li>
              <li style={{display:'none'}} id="LBLyukleme">
                <a style={{ fontFamily: "sans-serif" }} href="/Yukleme"><i className="icon-handbag" /> Yükleme </a>
              </li>
              <li style={{display:'none'}} id="LBLsatis">
                <a style={{ fontFamily: "sans-serif" }} href="/Satis"><i className="icon-tag" /> Satış </a>
              </li>
              <li id="LBLstok">
                <a style={{ fontFamily: "sans-serif" }} href="/Stok"><i className="fas fa-boxes" />Günlük Stok </a>
              </li>
              <li id="LBLdepo">
                <a style={{ fontFamily: "sans-serif" }} href="/Depo"><i className="fas fa-warehouse" /> Depo </a>
              </li>
              <li id="LBLuyelog">
                <a style={{ fontFamily: "sans-serif" }} href="/Uyelog"><i className="fas fa-user-check" /> Üyelog </a>
              </li>
              <li  id="LBLgecmisgelir">
                <a style={{ fontFamily: "sans-serif" }} href="javascript: void(0);" aria-expanded="true"><i className="fas fa-piggy-bank" /> Geçmiş Gelir<span className="fa arrow" /></a>
                <ul className="nav-second-level nav" aria-expanded="true">
                  <li><a style={{ fontFamily: "sans-serif" }} href="/Aylikgelir"><i className="fas fa-credit-card" style={{ marginRight: "5px" }} />Aylık Gelir</a></li>
                  <li><a style={{ fontFamily: "sans-serif" }} href="/istakip"><i className="fas fa-credit-card" style={{ marginRight: "5px" }} /> İş Takip</a></li>
                </ul>
              </li>
              
              <li id="LBLhesap">
                <a style={{ fontFamily: "sans-serif" }} href="/Hesap"><i className="fas fa-user-circle" /> Hesap </a>
              </li>
              <li id="LBLuyeislemleri">
                <a style={{ fontFamily: "sans-serif" }} href="/Uyeislemleri"><i className="fas fa-users-cog" /> Üye İşlemleri </a>
              </li>
              <li id="LBLcikis">
                <a style={{ fontFamily: "sans-serif" }} href="#" onClick={this.cikis}><i className="fas fa-sign-out-alt" /> Çıkış</a>
              </li>
              {/*<li><a href="landing/index.html" target="_blank" class="bg-primary"><i class="icon-star"></i>Landing page</a></li>*/}
            </ul>
          </div>{/*nano content*/}
        </div>{/*nano scroll end*/}

      </aside>

    )
  }
}

export default Leftsidebar
