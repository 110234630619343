import React, { Component } from 'react'

export class sipform extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
        console.log(props.match.params.id); 
    }
    componentDidMount(){
        var id = this.props.match.params.id;
        sessionStorage.setItem("sipno",id);
        window.location.href = "/SDetay";
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

export default sipform
